import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { Addresses } from "../../types";
import { ADDRESS_FIELDS } from "../fragments";

export const CUSTOMER_ADDRESSES = gql`
  ${ADDRESS_FIELDS}
  query Addresses {
    customer {
      addresses {
        ...AddressFields
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Addresses>, "data" | "loading" | "error"> {
  getCustomerAddresses: () => void;
  called: boolean;
}

export const useCustomerAddressesQuery = (): ExtendedQueryResult => {
  const [getCustomerAddresses, { data, error, loading, called }] = useLazyQuery<Addresses>(CUSTOMER_ADDRESSES, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getCustomerAddresses,
    data,
    error,
    loading,
    called,
  };
};
