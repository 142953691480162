import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";

import {
  Appointment,
  APPOINTMENT_SOURCE_EXTENDED,
  APPOINTMENT_VIEWS,
  Availability,
  CalendarNote,
  Resource,
  SetActiveAppointmentPayload,
  SetStoredAppointmentsPayload,
  StoredAppointments,
  StoredAvailabilities,
} from "../../types";
import { eachLocalDayOfInterval, localShortDate, toCalendarAppointmentFromRetrieveResponse } from "../../utils";

export interface AppointmentsState {
  activeAppointment: SetActiveAppointmentPayload;
  activeNote: CalendarNote;
  calendarDate: Date;
  calendarDateString: string; // YYYY-mm-dd
  calendarView: APPOINTMENT_VIEWS;
  displayedOptomId?: string;
  patientSearches: string[];
  showAddAppointment: boolean;
  showCancelled: boolean;
  showEditAppointment: boolean;
  showMobileMenu: boolean;
  showPatientCard: boolean;
  showCalendarNote: boolean;
  storedAppointments?: StoredAppointments;
  storedAvailabilities?: StoredAvailabilities;
  calendarNotes: CalendarNote[];
  showFullDetail: boolean;
}

export const initialAppointmentsState: AppointmentsState = {
  activeAppointment: { timeslot: undefined, appointment: undefined },
  activeNote: {
    id: "",
    note: "",
    updatedAt: "",
  },
  calendarNotes: [],
  calendarDate: new Date(),
  calendarDateString: localShortDate(new Date()),
  calendarView: APPOINTMENT_VIEWS.DAY,
  displayedOptomId: undefined,
  patientSearches: [],
  showAddAppointment: false,
  showCancelled: false,
  showEditAppointment: false,
  showMobileMenu: false,
  showPatientCard: false,
  storedAppointments: undefined,
  storedAvailabilities: undefined,
  showFullDetail: true,
  showCalendarNote: false,
};

type RemoveAppointmentPayload = {
  id: string;
  distributionChannelKey: string;
};

type RemoveAvailabilitiesPayload = {
  id: string;
  distributionChannelKey: string;
};

export const availabilityFilterCriteria =
  (dcKey: string, dateString: string) =>
  (availability: Availability): boolean =>
    localShortDate(new Date(availability.startAt)) === dateString && availability.distributionChannel.key === dcKey;

export const appointmentFilterCriteria =
  (dcKey: string, dateString: string) =>
  (appointment: Appointment): boolean =>
    localShortDate(new Date(appointment.start)) === dateString && appointment?.resource?.distributionChannelKey === dcKey;

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: initialAppointmentsState,
  reducers: {
    setActiveAppointment: (state, action: PayloadAction<SetActiveAppointmentPayload>) => {
      const stateActiveAppointment = state.activeAppointment?.appointment;
      const replaceActive =
        stateActiveAppointment?.resource?.id &&
        action.payload.appointment?.resource?.id &&
        stateActiveAppointment?.resource?.id !== action.payload.appointment?.resource?.id;

      const dcKey = action.payload.appointment?.resource?.distributionChannelKey as string;
      // not sure if calendar date is always going to be the correct date.
      // the fn gets called when a user selects an appointment by clicking or dnd so
      // need to confirm that this works in 3day view
      const dateString = localShortDate(new Date(state.calendarDate));
      const appointments = state.storedAppointments?.[dcKey]?.[dateString] ?? [];

      /**
       * This section handles the case when an appointment is open for editing and
       * another appointment is selected, opening it for editting.
       * In this case we want to replace the activeAppointment state and
       * update appointments accordingly.
       */ if (replaceActive) {
        const isTemp = stateActiveAppointment?.resource?.source === APPOINTMENT_SOURCE_EXTENDED.TEMP;
        if (!isTemp) {
          const stateExistingIndex: number = appointments.findIndex((appointment: Appointment) => {
            return appointment.resource.id === stateActiveAppointment?.resource?.id;
          });
          // Set current active appointment back to not editing, making is visible again
          if (stateExistingIndex !== -1) {
            appointments[stateExistingIndex].edit = false;
          }
        }
        state.activeAppointment = initialAppointmentsState.activeAppointment;
      }

      // Set the next active appointment from payload to edit.
      const existingIndex: number = appointments.findIndex((appointment: Appointment) => {
        return appointment.resource.id === action.payload.appointment?.resource?.id;
      });

      if (existingIndex !== -1) {
        appointments[existingIndex].edit = true;
      }

      /**
       * Update the activeAppointment state
       */
      const patient = {
        ...state.activeAppointment.appointment?.resource?.patient,
        ...action.payload?.appointment?.resource?.patient,
      };

      state.activeAppointment = {
        ...state.activeAppointment,
        timeslot: action.payload.timeslot ?? state.activeAppointment.timeslot,
        appointment: {
          ...state.activeAppointment.appointment,
          ...action.payload?.appointment,
          resource: {
            ...state.activeAppointment.appointment?.resource,
            ...action.payload?.appointment?.resource,
            patient: isEmpty(patient) ? undefined : patient,
            optom: {
              ...state.activeAppointment.appointment?.resource?.optom,
              ...action.payload?.appointment?.resource?.optom,
            },
          } as Resource,
        },
      };
    },
    setMedicareIsValidated: (state, action: PayloadAction<boolean>) => {
      const patient = state.activeAppointment?.appointment?.resource?.patient;
      if (patient) {
        patient.isMedicareValidated = action.payload;
      }
    },
    resetActiveAppointmentPatient: (state) => {
      if (state.activeAppointment?.appointment?.resource?.patient) {
        state.activeAppointment.appointment.resource.patient = undefined;
      }
    },
    resetActiveAppointment: (state) => {
      const dcKey = state.activeAppointment?.appointment?.resource?.distributionChannelKey as string;
      const activeAppointmentStart = state.activeAppointment?.appointment?.start;
      if (activeAppointmentStart) {
        const dateString = localShortDate(new Date(activeAppointmentStart));
        const appointments = state.storedAppointments?.[dcKey]?.[dateString] ?? [];

        const existingIndex: number = appointments.findIndex((appointment: Appointment) => {
          return appointment.resource.id === state.activeAppointment.appointment?.resource?.id;
        });

        const isTemp = state.activeAppointment?.appointment?.resource?.source === APPOINTMENT_SOURCE_EXTENDED.TEMP;
        if (existingIndex !== -1) {
          // In most cases we expect an edited appointment to stay on the same day, ie details other than date will be changed.
          if (!isTemp) {
            appointments[existingIndex].edit = false;
          }
        } else {
          // active appointment was edited to a different day,
          // cycle through all of the store's appointments to find the one that is hidden
          Object.values(state.storedAppointments?.[dcKey] ?? {}).forEach((appointments: Appointment[]) => {
            appointments.forEach((appointment: Appointment) => {
              if (appointment.resource.id === state.activeAppointment?.appointment?.resource?.id) {
                if (!isTemp) {
                  appointment.edit = false;
                }
              }
            });
          });
        }
      }
      state.activeAppointment = initialAppointmentsState.activeAppointment;
    },
    setStoredAppointments: (state, action: PayloadAction<SetStoredAppointmentsPayload[]>) => {
      const payloadAppointments = action.payload;
      if (payloadAppointments.length > 0) {
        const calendarAppointments = payloadAppointments
          .map((a) => toCalendarAppointmentFromRetrieveResponse(a))
          // the new appointment poller can hijack the existing appointment state update and replace the edit flag
          // leading to duplicate appointments being displayed. Ensuring it is removed here.
          .map((appointment: Appointment) => {
            if (appointment.resource.id === state.activeAppointment?.appointment?.resource?.id) {
              appointment.edit = true;
            }
            return appointment;
          });

        const uniqueAppointmentDates = [...(new Set(calendarAppointments.map((a) => localShortDate(new Date(a.start)))) as unknown as [])];
        const dcKeys = [...(new Set(payloadAppointments.map((a) => a?.distributionChannel?.key)) as unknown as [])].filter(Boolean);
        dcKeys.forEach((dcKey: string) => {
          uniqueAppointmentDates.forEach((dateString: string) => {
            state.storedAppointments = {
              ...state.storedAppointments,
              [dcKey]: {
                ...(state.storedAppointments?.[dcKey] ?? {}),
                [dateString]: [
                  ...(new Map(
                    [
                      ...(state.storedAppointments?.[dcKey]?.[dateString] ?? []),
                      // payload appointments should get stored when duplicate ids are mapped
                      ...calendarAppointments.filter(appointmentFilterCriteria(dcKey, dateString)),
                    ].map((a) => [a.resource.id, a]),
                  ).values() as unknown as []),
                ],
              },
            };
          });
        });
      }
    },
    setAppointmentDaysRequested: (state, action: PayloadAction<{ dcKey: string; start: Date; end: Date }>) => {
      const { dcKey, start, end } = action.payload;
      const dates = eachLocalDayOfInterval({ start, end });
      const dateStrings = dates.map((d) => localShortDate(new Date(d)));
      dateStrings.forEach((dateString: string) => {
        state.storedAppointments = {
          ...state.storedAppointments,
          [action.payload.dcKey]: {
            ...(state.storedAppointments?.[dcKey] ?? {}),
            [dateString]: state.storedAppointments?.[dcKey]?.[dateString] ?? [],
          },
        };
      });
    },
    setAvailabilitiesDaysRequested: (state, action: PayloadAction<{ dcKey: string; start: Date; end: Date }>) => {
      const { dcKey, start, end } = action.payload;
      const dates = eachLocalDayOfInterval({ start, end });
      const dateStrings = dates.map((d) => localShortDate(new Date(d)));
      dateStrings.forEach((dateString: string) => {
        state.storedAvailabilities = {
          ...state.storedAvailabilities,
          [action.payload.dcKey]: {
            ...(state.storedAvailabilities?.[dcKey] ?? {}),
            [dateString]: state.storedAvailabilities?.[dcKey]?.[dateString] ?? [],
          },
        };
      });
    },
    resetStoredAppointments: (state) => {
      state.storedAppointments = initialAppointmentsState.storedAppointments;
    },
    removeFromStoredAppointments: (state, action: PayloadAction<RemoveAppointmentPayload>) => {
      const appointmentIdToRemove = action.payload.id;
      const dcKey = action.payload.distributionChannelKey as string;

      const allAppointments = Object.values(state.storedAppointments?.[dcKey] ?? {}).flatMap((appointment) => appointment);
      const appointmentToRemove = allAppointments.find((a) => a.resource.id === appointmentIdToRemove);

      if (appointmentToRemove?.start) {
        const dateKey = localShortDate(new Date(appointmentToRemove.start));
        const indexToRemove = state.storedAppointments?.[dcKey]?.[dateKey]?.findIndex((a) => a.resource.id === appointmentIdToRemove);

        if (indexToRemove !== -1) {
          state.storedAppointments?.[dcKey]?.[dateKey]?.splice(indexToRemove as number, 1);
        }
      }
    },
    removeFromStoredAvailabilities: (state, action: PayloadAction<RemoveAvailabilitiesPayload[]>) => {
      action.payload.forEach(({ id, distributionChannelKey }: RemoveAvailabilitiesPayload) => {
        const allAvailabilities = Object.values(state.storedAvailabilities?.[distributionChannelKey] ?? {}).flatMap((appointment) => appointment);
        const avalabilitiesToRemove = allAvailabilities.find((a) => a.id === id);

        if (avalabilitiesToRemove?.startAt) {
          const dateKey = localShortDate(new Date(avalabilitiesToRemove.startAt));
          const indexToRemove = state.storedAvailabilities?.[distributionChannelKey]?.[dateKey]?.findIndex((a) => a.id === id);

          if (indexToRemove !== -1) {
            state.storedAvailabilities?.[distributionChannelKey]?.[dateKey]?.splice(indexToRemove as number, 1);
          }
        }
      });
    },
    setStoredAvailabilities: (state, action: PayloadAction<Availability[]>) => {
      const payloadAvails = action.payload.filter((a) => a.distributionChannel && a.optometrist);
      const availDates = [...(new Set(payloadAvails.map((avail) => localShortDate(new Date(avail.startAt)))) as unknown as [])];
      const dcKeys = [...(new Set(payloadAvails.map((avail) => avail.distributionChannel?.key ?? "")) as unknown as [])];
      dcKeys.forEach((dcKey: string) => {
        availDates.forEach((dateString: string) => {
          state.storedAvailabilities = {
            ...state.storedAvailabilities,
            [dcKey]: {
              ...(state.storedAvailabilities?.[dcKey] ?? {}),
              [dateString]: payloadAvails.filter(availabilityFilterCriteria(dcKey, dateString)),
            },
          };
        });
      });
    },
    setCalendarDate: (state: AppointmentsState, action: PayloadAction<Date>) => {
      state.calendarDate = action.payload instanceof Date ? action.payload : new Date(action.payload);
      state.calendarDateString = localShortDate(state.calendarDate);
    },
    setCalendarView: (state: AppointmentsState, action: PayloadAction<APPOINTMENT_VIEWS>) => {
      state.calendarView = action.payload;
    },
    setDisplayOptom: (state: AppointmentsState, action: PayloadAction<string | undefined>) => {
      state.displayedOptomId = action.payload;
    },
    setPatientSearches: (state: AppointmentsState, action: PayloadAction<string>) => {
      state.patientSearches.unshift(action.payload);
      state.patientSearches.slice(0, 5);
    },
    showAddAppointment: (state, action: PayloadAction<boolean>) => {
      state.showAddAppointment = action.payload;
      if (action.payload) {
        state.showPatientCard = false;
        state.showEditAppointment = false;
      }
    },
    showEditAppointment: (state, action: PayloadAction<boolean>) => {
      state.showEditAppointment = action.payload;
      if (action.payload) {
        state.showPatientCard = false;
        state.showAddAppointment = false;
      }
    },
    showPatientCard: (state, action: PayloadAction<boolean>) => {
      state.showPatientCard = action.payload;
      if (action.payload) {
        state.showEditAppointment = false;
        state.showAddAppointment = false;
      }
    },
    toggleShowCancelled: (state: AppointmentsState) => {
      state.showCancelled = !state.showCancelled;
    },
    toggleShowMobileMenu: (state: AppointmentsState, action: PayloadAction<boolean | undefined>) => {
      state.showMobileMenu = action?.payload ?? !state.showMobileMenu;
    },
    toggleSurnameVisibility: (state: AppointmentsState) => {
      state.showFullDetail = !state.showFullDetail;
    },
    setShowCalendarNote: (state: AppointmentsState, action: PayloadAction<boolean>) => {
      state.showCalendarNote = action.payload;
    },
    resetActiveNote: (state: AppointmentsState) => {
      state.activeNote = initialAppointmentsState.activeNote;
    },
    setActiveNote: (state: AppointmentsState, action: PayloadAction<CalendarNote>) => {
      state.activeNote = action.payload;
    },
    setCalendarNotes: (state: AppointmentsState, action: PayloadAction<CalendarNote[]>) => {
      const sortedList = action.payload.slice().sort((a, b) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });

      state.calendarNotes = sortedList;
    },
  },
});

export const {
  removeFromStoredAppointments,
  removeFromStoredAvailabilities,
  resetActiveAppointment,
  resetActiveAppointmentPatient,
  resetActiveNote,
  resetStoredAppointments,
  setActiveAppointment,
  setActiveNote,
  setCalendarDate,
  setCalendarNotes,
  setCalendarView,
  setDisplayOptom,
  setPatientSearches,
  setMedicareIsValidated,
  setShowCalendarNote,
  setStoredAppointments,
  setStoredAvailabilities,
  setAppointmentDaysRequested,
  setAvailabilitiesDaysRequested,
  showAddAppointment,
  showEditAppointment,
  showPatientCard,
  toggleShowCancelled,
  toggleShowMobileMenu,
  toggleSurnameVisibility,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
