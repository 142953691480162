import { OverlappingPrescription, Patient, RecursivePartial } from "../../types";
import { isBefore } from "../../utils";
import { selectIsContactsService, selectIsGlassesService, selectOrderHasLeftEye, selectOrderHasRightEye } from "../lensBuilder";
import { RootState } from "../store";

export const selectActivePatient = (state: RootState): Patient | undefined => state.patient.activePatient;
export const selectActivePatientId = (state: RootState): string | undefined => state.patient.activePatient?.id;
export const selectFormPrescription = (state: RootState): RecursivePartial<OverlappingPrescription> | undefined => state.patient.formPrescription;
export const selectShowDetail = (state: RootState): boolean => state.patient.showDetail;
export const selectValidFormPrescription = (state: RootState): boolean => {
  const isContactsPrescription = selectIsContactsService(state);
  const isGlassesService = selectIsGlassesService(state);
  const hasRightEye = selectOrderHasRightEye(state);
  const hasLeftEye = selectOrderHasLeftEye(state);

  const {
    osCyl,
    odCyl,
    osSph,
    odSph,
    osPd,
    odPd,
    osAxis,
    odAxis,
    osHPrism,
    odHPrism,
    osVPrism,
    odVPrism,
    osVPrismType,
    odVPrismType,
    osHPrismType,
    odHPrismType,
    osSku,
    odSku,
    osSupplyChannelsKey,
    odSupplyChannelsKey,
    expiresDate,
    issueDate,
    optometristName,
  } = state.patient.formPrescription || {};

  // optom name is mandated
  if (!optometristName) {
    return false;
  }
  // If cyl present on any script, axis is required; if axis is present on any script, cyl is required
  if (hasLeftEye && (osCyl || osAxis)) {
    if (!osCyl || !osAxis) {
      return false;
    }
  }

  if (hasRightEye && (odCyl || odAxis)) {
    if (!odCyl || !odAxis) {
      return false;
    }
  }

  // If hPrism is present, hPrismType must be chosen
  // vPrism is present, vPrismType must be chosen
  // If hPrismType or vPrismType is selected, hPrism/vPrism must have a value
  if (hasLeftEye && (osHPrism || osHPrismType)) {
    if (!osHPrism || !osHPrismType) {
      return false;
    }
  }

  if (hasRightEye && (odHPrism || odHPrismType)) {
    if (!odHPrism || !odHPrismType) {
      return false;
    }
  }

  if (hasLeftEye && (osVPrism || osVPrismType)) {
    if (!osVPrism || !osVPrismType) {
      return false;
    }
  }

  if (hasRightEye && (odVPrism || odVPrismType)) {
    if (!odVPrism || !odVPrismType) {
      return false;
    }
  }

  if (!expiresDate || isBefore(new Date(expiresDate), new Date())) {
    return false;
  }

  if (issueDate && isBefore(new Date(), new Date(issueDate))) {
    return false;
  }

  if (isContactsPrescription) {
    if (hasLeftEye && (!osSku || !osSupplyChannelsKey)) {
      return false;
    }

    if (hasRightEye && (!odSku || !odSupplyChannelsKey)) {
      return false;
    }
  }

  if (isGlassesService) {
    if (hasLeftEye && (!osPd || !osSph)) {
      return false;
    }
    if (hasRightEye && (!odPd || !odSph)) {
      return false;
    }
  }

  if (!state.patient.activePatient?.id) {
    return false;
  }

  return true;
};
