import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { PosRetrieveBulkCustomerOrders, PosRetrieveBulkCustomerOrdersVariables } from "../../types";

export const RETRIEVE_CUSTOMER_ORDERS = gql`
  query PosRetrieveBulkCustomerOrders($distributionChannelKey: String!, $status: String, $endDate: String, $startDate: String) {
    posRetrieveBulkCustomerOrders(distributionChannelKey: $distributionChannelKey, status: $status, endDate: $endDate, startDate: $startDate) {
      distributionChannelKey
      batchNumber
      createdAt
      status
      dateReceived
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveBulkCustomerOrders>, "data" | "loading" | "error"> {
  getCustomerOrders: (request: PosRetrieveBulkCustomerOrdersVariables) => void;
  called: boolean;
}

export const useRetrieveCustomerOrders = (handleOnComplete?: (data: PosRetrieveBulkCustomerOrders) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called }] = useLazyQuery<PosRetrieveBulkCustomerOrders, PosRetrieveBulkCustomerOrdersVariables>(
    RETRIEVE_CUSTOMER_ORDERS,
    {
      onError: () => void 0,
      fetchPolicy: "no-cache",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    },
  );

  const getCustomerOrders = (variables: PosRetrieveBulkCustomerOrdersVariables): void => {
    fetch({ variables });
  };

  return {
    getCustomerOrders,
    data,
    error,
    loading,
    called,
  };
};
