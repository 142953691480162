import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React, { FC } from "react";

const LoadingFallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const LoadingFallback: FC = () => (
  <LoadingFallbackContainer>
    <CircularProgress color="primary" size={48} />
  </LoadingFallbackContainer>
);

export default LoadingFallback;
