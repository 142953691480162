import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";
import throttle from "lodash/throttle";
import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DISABLE_AUTO_FILL, PATHS, TEST_DATA_IDS } from "../constants";
import { useOrderByOrderNumberLazyQuery, useSearchPatientsQuery } from "../data/queries";
import { useAppDispatch } from "../state/hooks";
import { setActiveOrderNumber } from "../state/orders";
import { setActivePatient } from "../state/patient";
import {
  Patient,
  SearchPatientsElastic,
  SearchPatientsElastic_searchPatientsElastic_patientSearchElasticResponse,
  StoreOrderByNumber,
  StoreOrderByNumber_storeOrderByNumber,
} from "../types";
import { getCountryCode } from "../utils";

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const SEARCH_THROTTLE_MS = 600;

type SearchOption = SearchPatientsElastic_searchPatientsElastic_patientSearchElasticResponse | StoreOrderByNumber_storeOrderByNumber;

const GlobalSearch: FC = () => {
  const searchInputRef = useRef("");
  const [keyword, setKeyword] = useState("");
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOrderSearch = (data: StoreOrderByNumber): void => {
    const order = data.storeOrderByNumber;
    if (order) {
      setSearchOptions([order]);
    } else {
      setSearchOptions([]);
    }
  };

  const { getOrder, ...orderResp } = useOrderByOrderNumberLazyQuery(handleOrderSearch);

  const handlePatientSearch = (data: SearchPatientsElastic): void => {
    const patientSearchData = (data?.searchPatientsElastic?.patientSearchElasticResponse ??
      []) as unknown as SearchPatientsElastic_searchPatientsElastic_patientSearchElasticResponse[];
    setSearchOptions(patientSearchData);
  };

  const { searchPatients, ...patientResp } = useSearchPatientsQuery(handlePatientSearch);

  const countryCode = getCountryCode().toUpperCase();

  useEffect(() => {
    if (keyword) {
      // we expect order numbers to have a BN prefix followed by min 6 numbers
      const orderNumberInKeyword = keyword.match(/BN[0-9]{6,}$/i)?.[0];
      if (keyword.slice(0, 2).toLowerCase() !== "bn" && !orderNumberInKeyword) {
        searchPatients({ variables: { query: keyword, countryCode } });
      } else if (orderNumberInKeyword) {
        // order search is case sensitive and order numbers are upper case
        getOrder(orderNumberInKeyword?.toUpperCase());
      }
      searchInputRef.current = keyword;
    }
  }, [keyword]);

  useEffect(() => {
    if (orderResp.error) {
      setSearchOptions([]);
    }
  }, [orderResp.error]);

  const handleSelectOption = (e: React.SyntheticEvent, selectedOption: SearchOption | null): void => {
    if (selectedOption !== null) {
      if ("firstName" in selectedOption) {
        // elastic response patient is not the complete patient object
        dispatch(setActivePatient(selectedOption as Patient));
        navigate(PATHS.PATIENT);
      } else if ("orderNumber" in selectedOption && selectedOption.orderNumber) {
        dispatch(setActiveOrderNumber(selectedOption.orderNumber));
        navigate(PATHS.PATIENT_ORDER);
      }
      if (searchInputRef.current) {
        searchInputRef.current = "";
      }
    }
  };

  // prevents default filtering of options that exlude inexact matches
  const filterOptions = (options: SearchOption[]): SearchOption[] => options;

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    throttle(() => setKeyword(event.target.value), SEARCH_THROTTLE_MS, { leading: false, trailing: true })();
  };

  const formatLabel = (option: SearchOption): string => {
    if ("firstName" in option) {
      return `${option.preferredName || option.firstName || ""} ${option.lastName ?? ""}\u00A0\u00A0\u00A0\u00A0\u00A0${
        option.mobilePhone ?? ""
      }\u00A0\u00A0\u00A0\u00A0\u00A0${option?.externalId ?? ""}`;
    } else if ("orderNumber" in option) {
      return `Order number: ${option.orderNumber}`;
    }
    return "";
  };

  const loading = orderResp.loading || patientResp.loading;

  return (
    <SelectContainer data-testid={TEST_DATA_IDS.GLOBAL_SEARCH_INPUT}>
      <Autocomplete
        size="small"
        autoHighlight
        clearOnEscape
        disablePortal
        getOptionLabel={formatLabel}
        loading={loading}
        loadingText="Searching..."
        filterOptions={filterOptions}
        onChange={handleSelectOption}
        options={searchOptions}
        noOptionsText="No results"
        sx={{ width: "100%", backgroundColor: "white" }}
        renderOption={(props, options) => (
          <li {...props} key={options.id}>
            {formatLabel(options)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: DISABLE_AUTO_FILL }}
            type="text"
            autoComplete={DISABLE_AUTO_FILL}
            label="Enter your search term"
            variant="outlined"
            name="global-search"
            onChange={handleTextFieldChange}
          />
        )}
      />
    </SelectContainer>
  );
};

export default GlobalSearch;
