import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { StoreOrderByNumber, StoreOrderByNumberVariables } from "../../types";
import { ORDER_FIELD } from "../fragments";

export const ORDER_BY_ID = gql`
  ${ORDER_FIELD}
  query StoreOrderByNumber($orderNumber: String!) {
    storeOrderByNumber(orderNumber: $orderNumber) {
      ...OrderField
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<StoreOrderByNumber>, "data" | "loading" | "error"> {
  getOrder: (orderNumber: string) => void;
  called: boolean;
}

export const useOrderByOrderNumberLazyQuery = (handleOnComplete?: (data: StoreOrderByNumber) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called }] = useLazyQuery<StoreOrderByNumber, StoreOrderByNumberVariables>(ORDER_BY_ID, {
    onError: () => void 0,
    fetchPolicy: "network-only",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const getOrder = (orderNumber: string): void => {
    fetch({ variables: { orderNumber } });
  };

  return {
    getOrder,
    data,
    error,
    loading,
    called,
  };
};
