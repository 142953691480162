import isNil from "lodash/isNil";
import { DateTime } from "luxon";

import { PRESCRIPTION_FIELDS_EXPLICIT_POSITIVES } from "../constants";
import {
  AddItemPayload,
  ContactsPrescription,
  ContactsPrescriptionRequest,
  EyeType,
  GlassesPrescription,
  GlassesPrescriptionRequest,
  OverlappingPrescription,
  RecursivePartial,
} from "../types";
import { isAfter, localShortDate } from "./dateTime";
import { removeEmptyValues } from "./helpers";

export const decmialFmt = new Intl.NumberFormat("en", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export const mapPrescriptionToCartItems = (prescription: RecursivePartial<OverlappingPrescription>): AddItemPayload => {
  // cart properties we want from the prescription
  const validKeys = [
    "power",
    "cyl",
    "axis",
    "add",
    "intAdd",
    "nearpd",
    "height",
    "genericAdd",
    "pd",
    "sku",
    "vPrism",
    "hPrism",
    "vPrismType",
    "hPrismType",
  ];

  return Object.entries(prescription).reduce((acc, [key, value]) => {
    const nextKey = key
      .toLowerCase()
      .replace(/^(os|od)/, "")
      .replace(/^sph/, "power")
      .replace(/^genericadd$/, "genericAdd")
      .replace(/^intadd$/, "intAdd")
      .replace(/^vprism$/, "vPrism")
      .replace(/^hprism$/, "hPrism")
      .replace(/^vprismtype/, "vPrismType")
      .replace(/^hprismtype/, "hPrismType")
      .replace(/^supplychannelskey/, "supplyChannelKey");

    if (isNil(value) || `${value}`.length === 0 || !validKeys.includes(nextKey)) {
      return acc;
    }

    const eye = key.slice(0, 2);

    const explicitPositive = PRESCRIPTION_FIELDS_EXPLICIT_POSITIVES.indexOf(key) !== -1;
    // pass through rx values like prism type strings, format other number values
    const formattedValue = isNaN(Number(value)) ? (value as string | null) : formatPrescriptionValue(Number(value), explicitPositive);
    if (eye === "os") {
      acc[EyeType.LEFT_OS as EyeType] = {
        ...(acc[EyeType.LEFT_OS as EyeType] ?? {}),
        [nextKey]: `${formattedValue}`,
      };
    }

    if (eye === "od") {
      acc[EyeType.RIGHT_OD as EyeType] = {
        ...(acc[EyeType.RIGHT_OD as EyeType] ?? {}),
        [nextKey]: `${formattedValue}`,
      };
    }

    return acc;
  }, {} as AddItemPayload);
};

/**
 * generate an array of numbers between min and max given an increment
 */
export const genNumberRange = (min: number, max: number, increment?: number): number[] => {
  const incrementValue = increment || 1;
  const range = [];
  for (let i = max; i >= min; i -= incrementValue) {
    range.push(i);
  }
  return range;
};

export const mapToPrescriptionRequest = (
  formPrescription: RecursivePartial<OverlappingPrescription>,
  activePatientId: string,
): GlassesPrescriptionRequest => {
  const prescription = formPrescription as GlassesPrescription;
  const optometristName = prescription?.optometristName
    ? prescription.optometristName
    : prescription.optometrist
    ? `${prescription.optometrist.firstName} ${prescription.optometrist.lastName}`
    : undefined;

  const { expiresDate, patientId, ...optional } = removeEmptyValues({
    ...prescription,
    id: undefined,
    odSph: Number(prescription.odSph),
    odCyl: prescription?.odCyl && Number(prescription.odCyl),
    odAxis: prescription?.odAxis && Number(prescription.odAxis),
    odAdd: prescription?.odAdd && Number(prescription.odAdd),
    odIntAdd: prescription?.odIntAdd && Number(prescription.odIntAdd),
    odPd: Number(prescription.odPd),
    odNearPd: prescription?.odNearPd && Number(prescription.odNearPd),
    odHPrism: prescription?.odHPrism && Number(prescription.odHPrism),
    odVPrism: prescription?.odVPrism && Number(prescription.odVPrism),
    osSph: Number(prescription.osSph),
    osCyl: prescription?.osCyl && Number(prescription.osCyl),
    osAxis: prescription?.osAxis && Number(prescription.osAxis),
    osAdd: prescription?.osAdd && Number(prescription.osAdd),
    osIntAdd: prescription?.osIntAdd && Number(prescription.osIntAdd),
    osPd: Number(prescription.osPd),
    osNearPd: prescription?.osNearPd && Number(prescription.osNearPd),
    osHPrism: prescription?.osHPrism && Number(prescription.osHPrism),
    osVPrism: prescription?.osVPrism && Number(prescription.osVPrism),
    optometristId: prescription?.optometrist?.id,
    optometristName,
    patientId: activePatientId,
    practiceName: prescription?.practiceName,
  });

  const issueDate = optional?.issueDate ? localShortDate(new Date(optional.issueDate)) : undefined;

  return {
    ...optional,
    ...(issueDate ? { issueDate } : {}),
    patientId,
    expiresDate: localShortDate(new Date(expiresDate)),
  };
};

export const mapToContactsPrescriptionRequest = (
  formPrescription: RecursivePartial<OverlappingPrescription>,
  activePatientId: string,
): ContactsPrescriptionRequest => {
  const prescription = formPrescription as ContactsPrescription;
  const optometristName = prescription?.optometristName
    ? prescription.optometristName
    : prescription.optometrist
    ? `${prescription.optometrist.firstName} ${prescription.optometrist.lastName}`
    : undefined;

  const { expiresDate, patientId, ...optional } = removeEmptyValues({
    ...prescription,
    id: undefined,
    odPower: prescription?.odSku && prescription?.odPower ? Number(prescription.odPower) : null,
    odCyl: prescription?.odCyl && Number(prescription.odCyl),
    odAxis: prescription?.odAxis && Number(prescription.odAxis),
    odAdd: prescription?.odAdd && Number(prescription.odAdd),
    osPower: prescription?.osSku && prescription?.osPower ? Number(prescription.osPower) : null,
    osCyl: prescription?.osCyl && Number(prescription.osCyl),
    osAxis: prescription?.osAxis && Number(prescription.osAxis),
    osAdd: prescription?.osAdd && Number(prescription.osAdd),
    optometristId: prescription?.optometrist?.id,
    optometristName,
    patientId: activePatientId,
    practiceName: prescription?.practiceName,
  });

  const issueDate = optional?.issueDate ? localShortDate(new Date(optional.issueDate)) : undefined;

  return {
    ...optional,
    ...(issueDate ? { issueDate } : {}),
    patientId,
    expiresDate: localShortDate(new Date(expiresDate)),
  };
};

type ValidationResponse = {
  isValid: boolean;
  errorMessage: string;
};

export const prescriptionValidation = (
  name: keyof OverlappingPrescription,
  value: string | null,
  options?: string[],
  formatted?: string,
): ValidationResponse => {
  const validationName = name.toLowerCase().replace(/^(os|od)/, "");
  const valueNumber = Number(value?.replace(/[+]/g, ""));

  if (isValidGenericAddValue(value ?? "") && (name === "odAdd" || name === "osAdd")) {
    return { isValid: true, errorMessage: "" };
  }

  if (value) {
    switch (validationName) {
      case "axis": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 1 && valueNumber <= 180;
        return {
          isValid,
          errorMessage: isValid ? "" : "Axis must be a number between 1 and 180",
        };
      }

      // differs from api validation which is >= 1 && <= 100
      case "pd": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 10 && valueNumber <= 40;
        return {
          isValid,
          errorMessage: isValid ? "" : "PD must be a number between 10 and 40",
        };
      }

      case "nearpd": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 10 && valueNumber <= 40;
        return {
          isValid,
          errorMessage: isValid ? "" : "Near PD must be a number between 10 and 40",
        };
      }

      default: {
        if (options && formatted && options.length > 0 && !options.includes(formatted)) {
          return { isValid: false, errorMessage: "Select from available values" };
        } else {
          return { isValid: true, errorMessage: "" };
        }
      }
    }
  }
  return { isValid: true, errorMessage: "" };
};

/**
 * @param value if value is not NaN, format its string rep. to two decimal places
 * @param explicitPositive if true return string rep. of positive number with a + prefix
 */
export const formatPrescriptionValue = (value?: string | number | null, explicitPositive?: boolean): string => {
  if (!isNil(value) && !isNaN(Number(value))) {
    const [first, second = "00"] = Number(value).toString().replace(/[+-]/g, "").split(".");
    return `${Number(value) > 0 ? `${explicitPositive ? "+" : ""}` : Number(value) < 0 ? "-" : ""}${first || "0"}.${second.concat("00").slice(0, 2)}`;
  }
  return "";
};

export const isExpiredPrescription = (expiresDate: string | null): boolean =>
  expiresDate ? isAfter(DateTime.local().toJSDate(), DateTime.fromISO(expiresDate).toJSDate()) : true;

export const isValidGenericAddValue = (value: string): boolean => ["High", "Medium", "Low"].includes(value);
