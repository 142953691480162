import { DEMO_LENS_SKU, NO_COLOUR_TREATMENTS } from "../constants";
import {
  AddItemPayload,
  addToCartDraft,
  ContactsPrescription,
  EyeType,
  GlassesPrescription,
  LensMeasurment,
  LensPayload,
  LensType,
  LineItem,
  Variant,
} from "../types";
import { getCountryCode, mapPrescriptionToCartItems } from "../utils";

export const hasColourOptions = (treatment?: string): boolean => !!treatment && !NO_COLOUR_TREATMENTS.includes(treatment);

export const checkTypes = (type1?: string | null, type2?: string | null): boolean =>
  !!type1 && !!type2 && type1.toLowerCase() === type2.toLowerCase();

export type TransformKVPair = {
  key: string;
  value: string;
};

type TransformObject = LensMeasurment | ContactsPrescription | GlassesPrescription;

export const lensDataToAddItem = (toTransform?: Partial<TransformObject>): AddItemPayload => {
  const eyeItems = { [EyeType.LEFT_OS]: {}, [EyeType.RIGHT_OD]: {} };
  if (!toTransform) {
    return eyeItems;
  }

  const kvPairs = Object.entries(toTransform).map(([key, value]) => ({ key, value } as TransformKVPair));
  return kvPairs.reduce((acc, { key, value }) => {
    switch (key) {
      // right eye
      case "odHeight":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], height: value };
        break;
      case "odPd":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], pd: value };
        break;
      case "odNearPd":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], nearpd: value };
        break;
      case "odSph":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], sphere: value };
        break;
      case "odAdd":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], add: value };
        break;
      case "odAxis":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], axis: value };
        break;
      case "odCyl":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], cyl: value };
        break;
      // left eye
      case "osHeight":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], height: value };
        break;
      case "osPd":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], pd: value };
        break;
      case "osNearPd":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], nearpd: value };
        break;
      case "osSph":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], sphere: value };
        break;
      case "osAdd":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], add: value };
        break;
      case "osAxis":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], axis: value };
        break;
      case "osCyl":
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], cyl: value };
        break;
      // both eyes
      case "bvd":
      case "pantoscopic_tilt":
      case "frame_wrap":
        acc[EyeType.RIGHT_OD] = { ...acc[EyeType.RIGHT_OD], [key]: value };
        acc[EyeType.LEFT_OS] = { ...acc[EyeType.LEFT_OS], [key]: value };
        break;
      default:
        break;
    }
    return acc;
  }, eyeItems);
};

export const variantToCartItem = (variant: Variant): addToCartDraft => ({
  sku: variant.sku,
  quantity: 1,
  supplyChannelKey: variant?.supplyChannelKey ?? null,
  ...(variant?.sunOpt ? { sunOpt: variant.sunOpt } : {}),
});

/**
 * @param {addToCartDraft} fields
 * @param {string[]} requiredFields
 *
 * @returns {boolean}
 *
 * @description
 * Checks if the required fields of a lens type are present in the fields object
 * supplied by a lens itemsToAdd (via prescription selection/input) object
 */
export const lensHasRequiredFields = (fields?: addToCartDraft, requiredFields?: string[]): boolean => {
  if (!requiredFields || !fields) {
    return true;
  }
  return requiredFields.every((field) => !!fields[field as keyof addToCartDraft]);
};

export const cartContainsDemoLensItems = (lineItems: LineItem[]): boolean => {
  const demoLenskus = Object.values(DEMO_LENS_SKU).filter(Boolean);
  return lineItems.some(({ variant }: LineItem) => variant?.sku && demoLenskus.includes(variant.sku));
};

export const getDemoLensSku = (): string => {
  const countryCode = getCountryCode();
  switch (countryCode.toLowerCase()) {
    case "au":
      return DEMO_LENS_SKU.AU;
    case "nz":
      return DEMO_LENS_SKU.NZ;
    default:
      return "";
  }
};

export const createDemoLensItem = (): LensPayload => {
  const sku = getDemoLensSku();
  const cartItems = mapPrescriptionToCartItems({
    odSku: sku,
    odPd: 30,
    odSph: 0,
    odCyl: 0,
    odAdd: 0,
    odAxis: 0,
  });

  return {
    itemToAdd: {
      ...cartItems[EyeType.RIGHT_OD],
      quantity: 1,
      lensType: LensType.D,
      supplyChannelKey: "EOLT",
    },
  };
};
