import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { PosRetrieveReceivedBulkReplenishments, PosRetrieveReceivedBulkReplenishmentsVariables } from "../../types";

export const RECEIVED_BULK_REPLENISHMENTS = gql`
  query PosRetrieveReceivedBulkReplenishments($endDate: String!, $startDate: String!, $distributionChannelKey: String) {
    posRetrieveReceivedBulkReplenishments(endDate: $endDate, startDate: $startDate, distributionChannelKey: $distributionChannelKey) {
      date
      batchNumber
      essilorStoreCode
    }
  }
`;

type Request = {
  endDate: string;
  startDate: string;
  distributionChannelKey?: string;
};

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveReceivedBulkReplenishments>, "data" | "loading" | "error"> {
  getBulkReplenishments: (request: Request) => void;
  called: boolean;
}

export const useReceivedBulkReplenishmentsQuery = (handleOnComplete?: (data: PosRetrieveReceivedBulkReplenishments) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called }] = useLazyQuery<
    PosRetrieveReceivedBulkReplenishments,
    PosRetrieveReceivedBulkReplenishmentsVariables
  >(RECEIVED_BULK_REPLENISHMENTS, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const getBulkReplenishments = (variables: Request): void => {
    fetch({ variables });
  };

  return {
    getBulkReplenishments,
    data,
    error,
    loading,
    called,
  };
};
