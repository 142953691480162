import styled from "@emotion/styled";
import { Grow, MenuItem, MenuList, Paper, Popper, Stack } from "@mui/material";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PATHS } from "../constants";

const StyledPopper = styled(Popper)`
  position: fixed !important;
  padding: 0.5rem 1rem;
`;

type Props = {
  children?: React.ReactNode;
};

const SidebarReportMenu: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  const handleShowMenu = (show: boolean): void => {
    setOpen(show);
  };

  const handleSelect = (path: string): void => {
    navigate(path);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <Stack ref={anchorRef} onMouseEnter={() => handleShowMenu(true)} onMouseLeave={() => handleShowMenu(false)}>
      <StyledPopper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                <MenuItem onClick={() => handleSelect(PATHS.CL_SIGNUP_REPORT)}>Contact Lens Sign Up</MenuItem>
                <MenuItem onClick={() => handleSelect(PATHS.EOD_REPORT)}>End of Day</MenuItem>
                <MenuItem onClick={() => handleSelect(PATHS.SALES_REPORT)}>Daily &amp; Weekly Sales</MenuItem>
                <MenuItem onClick={() => handleSelect(PATHS.TODAY_ORDERS_DETAILS_REPORT)}>Today Orders Details</MenuItem>
              </MenuList>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      {children}
    </Stack>
  );
};

export default SidebarReportMenu;
