import { isMobileOnly } from "mobile-device-detect";

// context menu can appear on devices and disrupt calendar interactions
export const disableDeviceContextMenu = (): void => {
  // disable context menu on the calendar on mobiles
  if (isMobileOnly && window.location.pathname === "/") {
    // eslint-disable-next-line
    console.log("disabling context menu on devices");
    window.oncontextmenu = (e: MouseEvent): void => {
      e.preventDefault();
    };
  }
};
