import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { InventoriesReceived, InventoriesReceivedVariables, InventoryMovementsRequest } from "../../types";

export const INVENTORIES_RECEIVED = gql`
  query InventoriesReceived($inventoryMovementsRequest: InventoryMovementsRequest!) {
    inventoriesReceived(inventoryMovementsRequest: $inventoryMovementsRequest) {
      id
      createdAt
      batchNumber
      destinationChannel
      sourceChannel
      transferDate
      transferStatus
      inventoryEntries {
        sku
        description
        quantity
        transferType
        comment
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<InventoriesReceived>, "data" | "loading" | "error" | "variables"> {
  getInventoriesReceived: (inventoryMovementsRequest: InventoryMovementsRequest) => void;
  called: boolean;
}

export const useInventoriesReceivedQuery = (handleOnComplete?: (data: InventoriesReceived) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called, variables }] = useLazyQuery<InventoriesReceived, InventoriesReceivedVariables>(INVENTORIES_RECEIVED, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const getInventoriesReceived = (inventoryMovementsRequest: InventoryMovementsRequest): void => {
    fetch({ variables: { inventoryMovementsRequest } });
  };

  return {
    getInventoriesReceived,
    data,
    error,
    loading,
    called,
    variables,
  };
};
