import { setContext } from "@apollo/client/link/context";

import { AnonymousTokenMutation, AUTH_KEYS, RefreshTokenMutation, SelectPatient } from "../../../types";
import { addMinutes, getCountryCode, isAfter } from "../../../utils";

const storeCode = process.env.REACT_APP_STORE_COUNTRY_CODE;

const fetchAnonymousToken = async (): Promise<{ data: AnonymousTokenMutation } | void> => {
  if (!process.env.REACT_APP_GRAPHQL_SERVER_URI) {
    return;
  }
  const response = await fetch(process.env.REACT_APP_GRAPHQL_SERVER_URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `mutation {\n  anonymousToken(store: "${storeCode}") {\n    access_token\n    expiresAt\n    refresh_token\n  }\n}`,
    }),
  });
  return response.json();
};

type RefreshTokenMutationResponse = {
  data: RefreshTokenMutation;
};

type SelectPatientResponse = {
  data: SelectPatient;
};

const fetchRefreshToken = async (refreshToken: string): Promise<RefreshTokenMutationResponse | void> => {
  if (!process.env.REACT_APP_GRAPHQL_SERVER_URI || !refreshToken) {
    return;
  }
  const response = await fetch(process.env.REACT_APP_GRAPHQL_SERVER_URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `mutation {\n  refreshToken(\n    refreshToken: "${refreshToken}"\n    store: "${storeCode}"\n  ) {\n    access_token\n    refresh_token\n    expiresAt\n  }\n}`,
    }),
  });
  return response.json();
};

export const fetchPatientToken = async (patientId: string | null): Promise<SelectPatientResponse | void> => {
  const store = process.env.REACT_APP_STORE_COUNTRY_CODE ?? "";
  const countryCode = getCountryCode().toUpperCase();
  if (!process.env.REACT_APP_GRAPHQL_SERVER_URI || !patientId || !countryCode) {
    return;
  }
  const response = await fetch(process.env.REACT_APP_GRAPHQL_SERVER_URI, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Store-Auth": sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN) ?? "",
    },
    body: JSON.stringify({
      query: `query {\n  posSelectPatient(\n    id: "${patientId}"\n    store: "${store}"\n    countryCode: "${countryCode}"\n   ) {\n    access_token\n    refresh_token\n    expiresAt\n  }\n}`,
    }),
  });
  return response.json();
};

type AccessToken = string;

const saveTokenDetailToSession = (accessToken: string, refreshToken: string, expiresAt: string): void => {
  sessionStorage.setItem(AUTH_KEYS.ANON_ACCESS_TOKEN, accessToken);
  sessionStorage.setItem(AUTH_KEYS.ANON_REFRESH_TOKEN, refreshToken);
  sessionStorage.setItem(AUTH_KEYS.ANON_ACCESS_TOKEN_EXPIRES, expiresAt);
};

const getAnonToken = async (): Promise<AccessToken> => {
  const accessToken = sessionStorage.getItem(AUTH_KEYS.ANON_ACCESS_TOKEN);
  const tokenExpiry = sessionStorage.getItem(AUTH_KEYS.ANON_ACCESS_TOKEN_EXPIRES);
  const refreshToken = sessionStorage.getItem(AUTH_KEYS.ANON_REFRESH_TOKEN);

  if (!accessToken) {
    const response = await fetchAnonymousToken();

    if (response && response?.data?.anonymousToken) {
      const { access_token, refresh_token, expiresAt } = response.data.anonymousToken;
      saveTokenDetailToSession(access_token, refresh_token, expiresAt);
      return access_token;
    }
    // error fetching token
    return "";
  } else if (refreshToken && tokenExpiry && isAfter(new Date(), addMinutes(new Date(tokenExpiry), -15))) {
    // token in storage but has expired
    const response = await fetchRefreshToken(refreshToken);
    if (response && response?.data?.refreshToken) {
      const { access_token, expiresAt, refresh_token } = response.data.refreshToken;

      saveTokenDetailToSession(access_token, refresh_token, expiresAt);
      return access_token;
    } else {
      //if refresh failed, get a new token via fetchAnonymousToken, the refresh token only last 30 days
      const response = await fetchAnonymousToken();

      if (response && response?.data?.anonymousToken) {
        const { access_token, refresh_token, expiresAt } = response.data.anonymousToken;
        saveTokenDetailToSession(access_token, refresh_token, expiresAt);
        return access_token;
      }
    }

    return "";
  } else {
    // non-expired token found storage
    return accessToken;
  }
};

// patient auth token is retrieved from the select patient query, initiated in the global header component
// here we only check if the token is expired and if so, we refresh it
const getPatientAuthToken = async (): Promise<AccessToken> => {
  const accessToken = sessionStorage.getItem(AUTH_KEYS.PATIENT_AUTH_TOKEN);
  const refreshToken = sessionStorage.getItem(AUTH_KEYS.PATIENT_REFRESH_TOKEN);
  const tokenExpiry = sessionStorage.getItem(AUTH_KEYS.PATIENT_AUTH_EXPIRES);

  if (refreshToken && tokenExpiry && isAfter(new Date(), addMinutes(new Date(tokenExpiry), -15))) {
    const response = await fetchRefreshToken(refreshToken);
    const { access_token, refresh_token, expiresAt } = response?.data?.refreshToken ?? {};

    if (access_token && refresh_token && expiresAt) {
      sessionStorage.setItem(AUTH_KEYS.PATIENT_AUTH_TOKEN, access_token);
      sessionStorage.setItem(AUTH_KEYS.PATIENT_REFRESH_TOKEN, refresh_token);
      sessionStorage.setItem(AUTH_KEYS.PATIENT_AUTH_EXPIRES, expiresAt);
      return access_token;
    }
    // error fetching token
    return "";
  } else if (accessToken) {
    // non-expired token found storage
    return accessToken;
  }
  return "";
};

export const authLink = setContext(async (_, { headers }) => {
  const patientId = sessionStorage.getItem(AUTH_KEYS.PATIENT_ID);
  const storeToken = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN);
  const storeTokenExpiry = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_EXPIRES);

  if (!storeToken || !storeTokenExpiry || (storeTokenExpiry && isAfter(new Date(), addMinutes(new Date(Number(storeTokenExpiry)), -15)))) {
    window.location.reload(); //force to refresh the page when store token expiry
  }

  if (patientId) {
    const patientAuthorization = await getPatientAuthToken();
    return {
      headers: {
        ...headers,
        "X-Store-Auth": sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN),
        authorization: patientAuthorization,
      },
    };
  } else {
    const anonAuthorization = await getAnonToken();
    return {
      headers: {
        ...headers,
        "X-Store-Auth": sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN),
        authorization: anonAuthorization,
      },
    };
  }
});
