import { createTheme, CssBaseline, responsiveFontSizes, ThemeOptions, ThemeProvider } from "@mui/material";
import React, { FC } from "react";

import { COLOURS } from "../constants";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: COLOURS.ACTIVE_BLUE,
    },
  },
};

let theme = createTheme(themeOptions);

/*
 will increase font size at mui pre-defined breakpoints, can override these in themOptions
 to use this, text needs to be wrapped in the <Typography /> component
 */
theme = responsiveFontSizes(theme);

type Props = {
  children?: React.ReactNode;
};

const MuiStyles: FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

export default MuiStyles;
