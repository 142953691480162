import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";

export const wsLink = new WebSocketLink(
  new SubscriptionClient(
    process.env.REACT_APP_GRAPHQL_WEBSOCKET_URI ?? "",
    {
      reconnect: true,
      lazy: true,
    },
    null,
    [],
  ),
);
