import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { PatientRetrieveAppointmentsQuery, PatientRetrieveAppointmentsQueryVariables } from "../../types";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS, PATIENT_FIELDS } from "../fragments";

export const PATIENT_APPOINTMENTS = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  ${PATIENT_FIELDS}
  query PatientRetrieveAppointmentsQuery($patientId: String!) {
    patientRetrieveAppointments(patientId: $patientId) {
      id
      source
      status
      notes
      startAt
      endAt
      distributionChannel {
        key
        timezone
        name
      }
      patient {
        ...PatientFields
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
      appointmentType {
        ...AppointmentTypeFields
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PatientRetrieveAppointmentsQuery>, "data" | "loading" | "error" | "variables"> {
  getPatientAppointments: ({ variables }: { variables: PatientRetrieveAppointmentsQueryVariables }) => void;
  called: boolean;
}

export const usePatientAppointmentsQuery = (): ExtendedQueryResult => {
  const [getPatientAppointments, { data, error, loading, called, variables }] = useLazyQuery<
    PatientRetrieveAppointmentsQuery,
    PatientRetrieveAppointmentsQueryVariables
  >(PATIENT_APPOINTMENTS, {
    onError: () => void 0,
  });

  return { getPatientAppointments, data, error, loading, called, variables };
};
