import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GlobalSearch from "../components/GlobalSearch";
import { SmallButton } from "../components/LensBuilder/useStyles";
import StoreSelect from "../components/StoreSelect";
import { COLOURS, PATHS, SIZES } from "../constants";
import { setShowCalendarNote } from "../state/appointments";
import { selectActiveDistributionChannel, setActiveDistributionChannel } from "../state/authentication";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { DistributionChannels_distributionChannels } from "../types";

const HeaderContainer = styled.div`
  height: ${SIZES.HEADER_HEIGHT}rem;
  width: 100%;
  background-color: ${COLOURS.LIGHT_GREY_HEADER};
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  aligh-items: center;
`;

const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const activeDistributionChannel = useAppSelector(selectActiveDistributionChannel) as DistributionChannels_distributionChannels | undefined;

  const handleNewPatientClicked = (): void => {
    navigate(PATHS.NEW_PATIENT);
  };

  const handleStoreSelect = (store: DistributionChannels_distributionChannels): void => {
    const pathMatch = pathname.split("/")[1] === PATHS.APPOINTMENTS.split("/")[1];
    if (pathMatch) {
      dispatch(setShowCalendarNote(true));
    }
    dispatch(setActiveDistributionChannel(store));
  };

  return (
    <HeaderContainer>
      <Grid container spacing={1} sx={{ justifyContent: "flex-end", alignItems: "center", flexWrap: "nowrap", paddingRight: "1rem" }}>
        <Grid item sx={{ width: "16rem" }}>
          <StoreSelect onSelect={handleStoreSelect} selected={activeDistributionChannel} authStoreFilter />
        </Grid>
        <Grid item sx={{ width: "27rem" }}>
          <GlobalSearch />
        </Grid>
        <Grid item sx={{ width: "11rem" }}>
          <SmallButton
            variant="contained"
            size="small"
            onClick={handleNewPatientClicked}
            sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%", display: "block", lineHeight: "100%" }}
          >
            add new patient
          </SmallButton>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default Header;
