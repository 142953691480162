import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { Cart } from "../../types";
import { ADDRESS_FIELDS } from "../fragments";

export const CART_SHIPPING = gql`
  ${ADDRESS_FIELDS}
  query CartShipping {
    cart {
      shippingAddress {
        ...AddressFields
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Cart>, "data" | "loading" | "error" | "refetch"> {
  getCartShippingAddress: () => void;
  called: boolean;
}

export const useCartShippingQuery = (networkChange?: boolean): ExtendedQueryResult => {
  const [getCartShippingAddress, { data, error, loading, called, refetch }] = useLazyQuery<Cart>(CART_SHIPPING, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: networkChange ?? true,
    fetchPolicy: "network-only",
  });

  return {
    getCartShippingAddress,
    data,
    error,
    loading,
    called,
    refetch,
  };
};
