import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { RetrieveBulkReplenishments, RetrieveBulkReplenishmentsVariables } from "../../types/generated";

export const BULK_REPLENISHMENTS = gql`
  query RetrieveBulkReplenishments($distributionChannelKey: String!) {
    posRetrieveBulkReplenishments(distributionChannelKey: $distributionChannelKey) {
      essilorStoreCode
      batchNumber
      date
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<RetrieveBulkReplenishments>, "data" | "loading" | "error" | "variables"> {
  getBulkReplenishments: ({ variables }: { variables: RetrieveBulkReplenishmentsVariables }) => void;
  called: boolean;
}

export const useRetrieveBulkReplenishmentsLazyQuery = (): ExtendedQueryResult => {
  const [getBulkReplenishments, { data, error, loading, called, variables }] = useLazyQuery<
    RetrieveBulkReplenishments,
    RetrieveBulkReplenishmentsVariables
  >(BULK_REPLENISHMENTS, {
    onError: () => void 0,
  });

  return {
    getBulkReplenishments,
    data,
    error,
    loading,
    called,
    variables,
  };
};
