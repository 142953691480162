import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import Authentication from "./components/Authentication";
import FeatureFlagWrapper from "./components/FeatureFlags";
import SentryTracking from "./components/Sentry/SentryTracking";
import { apolloClient } from "./data/apolloClient";
import "./index.scss";
import App from "./layout/App";
import { store } from "./state/store";
import MuiStyles from "./theme";
import { disableDeviceContextMenu } from "./utils";
import { CustomLuxonAdapter } from "./utils/customLuxonAdapter";

disableDeviceContextMenu();
const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <SentryTracking>
    <Router>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <MuiStyles>
            <LocalizationProvider dateAdapter={CustomLuxonAdapter as never}>
              <Authentication>
                <FeatureFlagWrapper>
                  <App />
                </FeatureFlagWrapper>
              </Authentication>
            </LocalizationProvider>
          </MuiStyles>
        </Provider>
      </ApolloProvider>
    </Router>
  </SentryTracking>,
);
