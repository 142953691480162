import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { Orders } from "../../types";
import { LINE_ITEM_FIELDS } from "../fragments";

export const PATIENT_ORDERS = gql`
  ${LINE_ITEM_FIELDS}
  query Orders {
    orders {
      lineItems {
        ...LineItemFields
      }
      id
      status
      orderNumber
      createdAt
      customFields {
        specialInstructions
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Orders>, "data" | "loading" | "error" | "variables"> {
  getOrders: () => void;
  called: boolean;
}

export const usePatientOrdersQuery = (): ExtendedQueryResult => {
  const [getOrders, { data, error, loading, called, variables }] = useLazyQuery<Orders>(PATIENT_ORDERS, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getOrders, data, error, loading, called, variables };
};
