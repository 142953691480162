import isNil from "lodash/isNil";

import {
  Appointment,
  APPOINTMENT_SOURCE_EXTENDED,
  AppointmentStatus,
  AppointmentType,
  Optom,
  Patient,
  PosCreateAppointmentMutationVariables,
  PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments,
  PosUpdateAppointmentMutationVariables,
} from "../types";
import { localISOString } from "./dateTime";

export const toAppointmentCreateOperationVariables = (
  appointment: Appointment,
  distributionChannelKey: string,
): PosCreateAppointmentMutationVariables => {
  const { start, end, resource } = appointment;
  if (![start, end, resource.optom.id, resource.type.id, distributionChannelKey].every(Boolean)) {
    throw Error(
      `Transforming state appointment to operation variable appointment validation failed, fields missing", ${JSON.stringify({
        start,
        end,
        optometristId: resource.optom.id,
        appointmentTypeId: resource.type.id,
        distributionChannelKey,
      })}`,
    );
  }
  return {
    posCreateAppointmentAppointmentRequest: {
      distributionChannelKey,
      optometristId: resource.optom.id,
      appointmentTypeId: resource.type.id,
      status: AppointmentStatus.BOOKED,
      startAt: localISOString(new Date(start)),
      endAt: localISOString(new Date(end)),
      ...(resource.patient?.id ? { patientId: resource.patient?.id } : {}),
      ...(resource.notes ? { notes: resource.notes } : {}),
    },
  };
};

export const toAppointmentUpdateOperationVariables = (appointment: Appointment): PosUpdateAppointmentMutationVariables => {
  const { start, end, resource } = appointment;
  if (![start, end, resource.optom.id, resource.type.id, resource.id].every(Boolean)) {
    throw Error(
      `Transforming state appointment to operation variable appointment validation failed, fields missing", ${JSON.stringify({
        start,
        end,
        optometristId: resource.optom.id,
        appointmentTypeId: resource.type.id,
      })}`,
    );
  }

  return {
    posUpdateAppointmentAppointmentUpdateRequest: {
      id: resource.id,
      optometristId: resource.optom.id,
      appointmentTypeId: resource.type.id,
      status: resource.status as unknown as AppointmentStatus,
      startAt: localISOString(new Date(start)),
      endAt: localISOString(new Date(end)),
      ...(isNil(resource.patient?.id) ? {} : { patientId: resource.patient?.id }),
      ...(isNil(resource.notes) ? {} : { notes: resource.notes }),
    },
  };
};

interface OptionalEditAppointment extends PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments {
  edit?: boolean;
}

export const toCalendarAppointmentFromRetrieveResponse = (appointment: OptionalEditAppointment): Appointment => {
  return {
    end: new Date(appointment.endAt),
    start: new Date(appointment.startAt),
    resourceId: appointment.optometrist?.id ?? "",
    edit: appointment?.edit ?? false,
    resource: {
      id: appointment.id,
      distributionChannelKey: appointment?.distributionChannel?.key ?? "",
      optom: appointment.optometrist as Optom,
      source: appointment.source as unknown as APPOINTMENT_SOURCE_EXTENDED,
      status: appointment.status,
      type: appointment?.appointmentType as AppointmentType,
      patient: appointment?.patient as Patient,
      notes: appointment?.notes ?? "",
    },
  };
};
