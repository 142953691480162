import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { SkuFrameSearchQuery, SkuFrameSearchQueryVariables } from "../../types";
import { SEARCH_PRODUCT_FIELDS } from "../fragments";

export const SKU_SEARCH_FRAMES = gql`
  ${SEARCH_PRODUCT_FIELDS}
  query SkuFrameSearchQuery($searchCategorySlug: String, $queryString: String, $sunQueryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFields
      }
    }
    sunSearch: search(categorySlug: $searchCategorySlug, query: $sunQueryString) {
      products {
        ...SearchProductFields
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<SkuFrameSearchQuery>, "data" | "loading" | "error" | "variables"> {
  skuSearchFrames: (query: string) => void;
  called: boolean;
}

export const useSkuFrameSearchQuery = (handleOnComplete?: (data: SkuFrameSearchQuery) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called, variables }] = useLazyQuery<SkuFrameSearchQuery, SkuFrameSearchQueryVariables>(SKU_SEARCH_FRAMES, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: true,
  });

  const skuSearchFrames = (query: string): void => {
    const sunQueryString = query.replace("OPT", "SUN");
    fetch({ variables: { searchCategorySlug: "frame", queryString: query, sunQueryString } });
  };

  return {
    skuSearchFrames,
    data,
    error,
    loading,
    called,
    variables,
  };
};
