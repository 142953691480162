import { gql, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { AppointmentSet, OptometristQueryVariables } from "../../types";

export const OPTOMETRIST = gql`
  query OptometristQuery($countryCode: String!) {
    posRetrieveOptometrists(countryCode: $countryCode) {
      id
      countryCode
      lastName
      firstName
      email
      active
      appointmentSet
      appointmentTypes {
        id
      }
    }
  }
`;

export interface OptometristData {
  id: string;
  countryCode: string;
  lastName: string;
  firstName: string;
  email: string | null;
  active: boolean;
  appointmentTypes: AppointmentType[];
  appointmentSet?: AppointmentSet | null;
}

interface AppointmentType {
  id: string;
}

export interface OptometristQueryResponse {
  posRetrieveOptometrists: OptometristData[];
}

interface ExtendedQueryResult extends Pick<QueryResult<OptometristQueryResponse>, "data" | "loading" | "error" | "variables"> {
  getOptometrists: () => void;
}

export const useOptometristQuery = (countryCode: string): QueryResult<OptometristQueryResponse, OptometristQueryVariables> =>
  useQuery<OptometristQueryResponse, OptometristQueryVariables>(OPTOMETRIST, {
    variables: { countryCode },
    onError: () => void 0,
    fetchPolicy: "network-only",
    skip: !countryCode,
  });

export const useOptometristLazyQuery = (countryCode: string): ExtendedQueryResult => {
  const [getOptometrists, { data, error, loading, variables }] = useLazyQuery<OptometristQueryResponse, OptometristQueryVariables>(OPTOMETRIST, {
    variables: { countryCode },
    onError: () => void 0,
    fetchPolicy: "network-only",
  });
  return {
    getOptometrists,
    data,
    error,
    loading,
    variables,
  };
};
