import capitalize from "lodash/capitalize";
import isNil from "lodash/isNil";

import { eyeOptions, serviceOptions } from "../../constants";
import {
  AddOn,
  addToCartDraft,
  AddToCartDraftWithLineItemId,
  CartDemoLensPayload,
  CATEGORY_SLUG,
  ContactsPrescription,
  EYE_OPTION_TYPE,
  EyeOption,
  EyeType,
  GlassesPrescription,
  LENS_BUILDER_STEP,
  LensEye,
  LensThicknessOption,
  LensType,
  OrderDemoLens_orderDemoLens,
  PaymentInput,
  PrescriptionLensType,
  SelectedLens,
  ServiceOption,
  Stock,
  SubmitPosOrder_submitPosOrder,
  TreatmentOption,
  updateLineItemPrescriptionDraft,
  Variant,
} from "../../types";
import { convertAddToCartDraftToLineItemPrescription, hasColourOptions, variantToCartItem } from "../../utils";
import { RootState } from "../store";

export const validateLensItems = (lensEye: LensEye): addToCartDraft => {
  const itemToAdd = lensEye.itemToAdd;

  const { add, intAdd: _i, ...rest } = itemToAdd;
  switch (rest?.lensType) {
    case LensType.D: // single vision distance
      return rest;
    case LensType.N: // single vision reading
      return {
        ...rest,
        add,
      };
    default:
      return itemToAdd;
  }
};

export const selectLensEye = (state: RootState): LensEye => {
  if (
    state.lensBuilder.orderEyeConfig === EYE_OPTION_TYPE.BOTH ||
    state.lensBuilder.orderEyeConfig === EYE_OPTION_TYPE.RIGHT_ONLY ||
    !state.lensBuilder.orderEyeConfig
  ) {
    return state.lensBuilder.lenses[EyeType.RIGHT_OD];
  }
  return state.lensBuilder.lenses[EyeType.LEFT_OS];
};
export const selectEyeOptionLabel = (state: RootState): string | undefined => {
  const orderEyeConfig = state.lensBuilder.orderEyeConfig;
  if (orderEyeConfig === EYE_OPTION_TYPE.BOTH) {
    return eyeOptions.find((eo: EyeOption) => eo.type === EYE_OPTION_TYPE.BOTH)?.name as string;
  }
  if (orderEyeConfig === EYE_OPTION_TYPE.RIGHT_ONLY) {
    return eyeOptions.find((eo: EyeOption) => eo.type === EYE_OPTION_TYPE.RIGHT_ONLY)?.name as string;
  }
  if (orderEyeConfig === EYE_OPTION_TYPE.LEFT_ONLY) {
    return eyeOptions.find((eo: EyeOption) => eo.type === EYE_OPTION_TYPE.LEFT_ONLY)?.name as string;
  }
};
export const selectAddOns = (state: RootState): AddOn[] | undefined => state.lensBuilder.addOns;
export const selectBarcodeScannerOn = (state: RootState): boolean => state.lensBuilder.barcodeScannerOn;
export const selectColour = (state: RootState): string | undefined => {
  const lensEye = selectLensEye(state);
  return lensEye?.detail.color;
};
export const selectDemoLensInCart = (state: RootState): CartDemoLensPayload => state.lensBuilder.demoLensInCart;
export const selectDrawOpen = (state: RootState): boolean => state.lensBuilder.drawOpen;
export const selectEditMode = (state: RootState): boolean => state.lensBuilder.editMode;
export const selectFitToFrame = (state: RootState): boolean => state.lensBuilder.fitToFrame;
export const selectLenses = (state: RootState): SelectedLens => state.lensBuilder.lenses;
export const selectOrderSuccess = (state: RootState): OrderDemoLens_orderDemoLens | SubmitPosOrder_submitPosOrder | undefined =>
  state.lensBuilder.orderSuccess;
export const selectOrderEyeConfig = (state: RootState): EYE_OPTION_TYPE | undefined => state.lensBuilder.orderEyeConfig;
export const selectOrderHasRightEye = (state: RootState): boolean => state.lensBuilder.orderEyeConfig !== EYE_OPTION_TYPE.LEFT_ONLY;

export const selectOrderHasLeftEye = (state: RootState): boolean => state.lensBuilder.orderEyeConfig !== EYE_OPTION_TYPE.RIGHT_ONLY;

export const selectPaymentDetails = (state: RootState): PaymentInput[] => state.lensBuilder.paymentDetails;
export const selectPhysicalTraceRequired = (state: RootState): boolean => state.lensBuilder.physicalTraceRequired;
export const selectAddToCartDraftItems = (state: RootState): addToCartDraft[] => {
  const addOnItems = (state.lensBuilder.addOns ?? []).map(({ sku, supplyChannelKey }: AddOn) => ({
    sku,
    supplyChannelKey,
    quantity: 1,
  })) as addToCartDraft[];
  const lensOnlyService = selectIsLensOnly(state);
  const demoLensService = selectIsDemoLensService(state);
  const frameVariant = selectFrameVariant(state);
  const physicalTraceRequired = selectPhysicalTraceRequired(state);
  const fitToFrame = selectFitToFrame(state);

  /**
   * for lens only or demo lens service
   * 1. don't add frame to cart
   * 2. add frame sku to lens item
   */
  const frameItem = !!frameVariant && !lensOnlyService && !demoLensService ? variantToCartItem(frameVariant) : undefined;

  return [
    ...(selectOrderHasRightEye(state)
      ? [
          validateLensItems({
            ...state.lensBuilder.lenses[EyeType.RIGHT_OD],
            itemToAdd: {
              ...state.lensBuilder.lenses[EyeType.RIGHT_OD].itemToAdd,
              ...((physicalTraceRequired || demoLensService) && frameVariant?.sku ? { frameSku: frameVariant.sku } : {}),
              ...(lensOnlyService && frameVariant?.sku ? { frameSku: frameVariant.sku } : {}),
              ...(physicalTraceRequired ? { physicalTraceRequired } : {}),
              ...(fitToFrame ? { fitToFrame } : {}),
            },
          }),
        ]
      : []),
    ...(selectOrderHasLeftEye(state)
      ? [
          validateLensItems({
            ...state.lensBuilder.lenses[EyeType.LEFT_OS],
            itemToAdd: {
              ...state.lensBuilder.lenses[EyeType.LEFT_OS].itemToAdd,
              ...((physicalTraceRequired || demoLensService) && frameVariant?.sku ? { frameSku: frameVariant.sku } : {}),
              ...(lensOnlyService && frameVariant?.sku ? { frameSku: frameVariant.sku } : {}),
              ...(physicalTraceRequired ? { physicalTraceRequired } : {}),
              ...(fitToFrame ? { fitToFrame } : {}),
            },
          }),
        ]
      : []),
    ...addOnItems,
    ...(frameItem
      ? [
          {
            ...frameItem,
            ...(physicalTraceRequired ? { physicalTraceRequired, frameSku: frameItem.sku } : {}),
            ...(fitToFrame ? { fitToFrame } : {}),
          },
        ]
      : []),
  ];
};
export const selectTraceFileAvailable = (state: RootState): boolean => {
  const selectedFrame = selectFrameVariant(state);
  const bnTrackLibAvailable = typeof selectedFrame?.bnTrackLib === "boolean" && selectedFrame.bnTrackLib;
  const eoltTrackLibAvailable = typeof selectedFrame?.eoltTrackLib === "boolean" && selectedFrame.eoltTrackLib;
  return bnTrackLibAvailable || eoltTrackLibAvailable;
};
export const selectPrescription = (state: RootState): ContactsPrescription | GlassesPrescription | undefined => state.lensBuilder.prescription;
export const selectPrescriptionLensType = (state: RootState): PrescriptionLensType | undefined => {
  const lensEye = selectLensEye(state);
  return lensEye?.detail?.prescriptionLensType;
};
export const selectPrescriptionLensExtra = (state: RootState): string | undefined => {
  const lensEye = selectLensEye(state);
  return lensEye?.detail?.prescriptionLensExtra;
};
export const selectFullOrderType = (state: RootState): Record<EyeType, string> => {
  const leftDetail = state.lensBuilder.lenses[EyeType.LEFT_OS]?.detail;
  const rightDetail = state.lensBuilder.lenses[EyeType.RIGHT_OD]?.detail;

  return {
    [EyeType.RIGHT_OD]: `${rightDetail?.prescriptionLensType ?? ""} ${capitalize(rightDetail?.prescriptionLensExtra ?? "")}`.trim(),
    [EyeType.LEFT_OS]: `${leftDetail?.prescriptionLensType ?? ""} ${capitalize(leftDetail?.prescriptionLensExtra ?? "")}`.trim(),
  };
};

export const selectStocks = (state: RootState): Stock[] => {
  return (state.lensBuilder.frameVariant?.stocks ?? []).filter((stock: Stock) => !isNil(stock));
};
export const selectService = (state: RootState): ServiceOption | undefined => state.lensBuilder.service;
export const selectIsGlassesService = (state: RootState): boolean => state.lensBuilder.service?.slug === CATEGORY_SLUG.FRAME;
export const selectIsContactsService = (state: RootState): boolean => state.lensBuilder.service?.slug === CATEGORY_SLUG.CONTACT_LENSES;
export const selectIsLensOnly = (state: RootState): boolean =>
  state.lensBuilder.service?.displayName === serviceOptions[CATEGORY_SLUG.LENSES].displayName;
export const selectIsDemoLensService = (state: RootState): boolean => state.lensBuilder.service?.displayName === serviceOptions.demoLens.displayName;
export const selectIsOpticalGlassesService = (state: RootState): boolean => selectIsCustomisedFrameService(state) || selectIsLensOnly(state);
export const selectIsCustomisedFrameService = (state: RootState): boolean =>
  state.lensBuilder.service?.displayName === serviceOptions.framesCustomised.displayName;
export const selectFramesOnlyOrder = (state: RootState): boolean =>
  state.lensBuilder.service?.displayName === serviceOptions.framesOffTheShelf.displayName;
export const selectShowSupplyChannelSelect = (state: RootState): boolean =>
  !selectIsContactsService(state) && !selectIsLensOnly(state) && !selectIsDemoLensService(state);
export const selectShowTraceRequiredCheckbox = (state: RootState): boolean =>
  selectIsLensOnly(state) || selectIsCustomisedFrameService(state) || selectIsDemoLensService(state);
export const selectShowFitToFrameCheckbox = (state: RootState): boolean =>
  selectIsLensOnly(state) || selectIsCustomisedFrameService(state) || selectIsDemoLensService(state);
export const selectIsQuotableService = (state: RootState): boolean => selectIsCustomisedFrameService(state) || selectIsLensOnly(state);
export const selectIsQuote = (state: RootState): boolean | undefined => state.lensBuilder?.service?.quote;
// lineItemIds are set when converting quote cart items to lensbuilder state
// their presence indicates that the lensbuilding items originated  from a quote
export const selectItemsPresentFromQuoteCart = (state: RootState): boolean => {
  return (
    (state.lensBuilder?.frameVariant && "lineItemId" in state.lensBuilder.frameVariant && !!state.lensBuilder.frameVariant.lineItemId) ||
    (state.lensBuilder?.lenses[EyeType.RIGHT_OD]?.itemToAdd &&
      "lineItemId" in state.lensBuilder.lenses[EyeType.RIGHT_OD].itemToAdd &&
      !!state.lensBuilder?.lenses[EyeType.RIGHT_OD].itemToAdd.lineItemId) ||
    !!state.lensBuilder?.lenses[EyeType.LEFT_OS]?.itemToAdd?.lineItemId
  );
};
export const selectServiceSteps = (state: RootState): LENS_BUILDER_STEP[] =>
  state.lensBuilder.service?.steps ?? serviceOptions.framesCustomised.steps;
export const selectStep = (state: RootState): LENS_BUILDER_STEP => state.lensBuilder.step;
export const selectThickness = (state: RootState): LensThicknessOption | undefined => {
  const lensEye = selectLensEye(state);
  return lensEye?.detail?.thickness;
};
export const selectTreatment = (state: RootState): TreatmentOption | undefined => {
  const lensEye = selectLensEye(state);
  return lensEye?.detail?.treatment;
};
export const selectFrameVariant = (state: RootState): Variant | undefined => state.lensBuilder.frameVariant;
export const selectOSQuantity = (state: RootState): number => state.lensBuilder.lenses[EyeType.LEFT_OS]?.itemToAdd?.quantity;
export const selectODQuantity = (state: RootState): number => state.lensBuilder.lenses[EyeType.RIGHT_OD]?.itemToAdd?.quantity;
export const selectShowPolycarbOption = (state: RootState): boolean =>
  state.lensBuilder.lenses[EyeType.RIGHT_OD]?.detail?.prescriptionLensType === "Single Vision" ||
  state.lensBuilder.lenses[EyeType.LEFT_OS]?.detail?.prescriptionLensType === "Single Vision";

export const selectOsHeightRequired = (state: RootState): boolean => {
  const leftLens = state.lensBuilder.lenses[EyeType.LEFT_OS];
  const orderHasLeftEye = selectOrderHasLeftEye(state);
  return orderHasLeftEye && !!leftLens?.detail?.prescriptionLensType && !["Single Vision", "Plano"].includes(leftLens.detail.prescriptionLensType);
};
export const selectOdHeightRequired = (state: RootState): boolean => {
  const rightLens = state.lensBuilder.lenses[EyeType.RIGHT_OD];
  const orderHasRightEye = selectOrderHasRightEye(state);
  return orderHasRightEye && !!rightLens?.detail?.prescriptionLensType && !["Single Vision", "Plano"].includes(rightLens.detail.prescriptionLensType);
};
export const selectOsNearPdRequired = (state: RootState): boolean => {
  const leftLens = state.lensBuilder.lenses[EyeType.LEFT_OS];
  const orderHasLeftEye = selectOrderHasLeftEye(state);
  return (
    orderHasLeftEye &&
    !!leftLens?.detail?.prescriptionLensType &&
    (["Office", "Bifocal", "Premium Multifocal", "Everyday Multifocal", "Ultra Multifocal"].includes(leftLens.detail.prescriptionLensType) ||
      leftLens?.detail?.prescriptionLensExtra === "reading")
  );
};
export const selectOdNearPdRequired = (state: RootState): boolean => {
  const rightLens = state.lensBuilder.lenses[EyeType.RIGHT_OD];
  const orderHasRightEye = selectOrderHasRightEye(state);
  return (
    orderHasRightEye &&
    !!rightLens?.detail?.prescriptionLensType &&
    (["Office", "Bifocal", "Premium Multifocal", "Everyday Multifocal", "Ultra Multifocal"].includes(rightLens.detail.prescriptionLensType) ||
      rightLens?.detail?.prescriptionLensExtra === "reading")
  );
};
export const selectMeasurementStepComplete = (state: RootState): boolean => {
  const rightLens = state.lensBuilder.lenses[EyeType.RIGHT_OD];
  const leftLens = state.lensBuilder.lenses[EyeType.LEFT_OS];
  const orderHasRightEye = selectOrderHasRightEye(state);
  const orderHasLeftEye = selectOrderHasLeftEye(state);

  const odNearPdReq = selectOdNearPdRequired(state);
  const osNearPdReq = selectOsNearPdRequired(state);
  const odHeightReq = selectOdHeightRequired(state);
  const osHeightReq = selectOsHeightRequired(state);

  const incomplete =
    (odNearPdReq && !rightLens?.itemToAdd?.nearpd) ||
    (osNearPdReq && !leftLens?.itemToAdd?.nearpd) ||
    (odHeightReq && !rightLens?.itemToAdd?.height) ||
    (osHeightReq && !leftLens?.itemToAdd?.height) ||
    (!rightLens?.itemToAdd?.pd && orderHasRightEye) ||
    (!leftLens?.itemToAdd?.pd && orderHasLeftEye);

  return !incomplete;
};

export const selectLensStepComplete = (state: RootState): Record<string, boolean> => {
  const complete = {
    eye: false,
    type: false,
    thickness: false,
    colour: false,
    treatment: false,
    lensReview: false,
  };

  const rightLens = state.lensBuilder.lenses[EyeType.RIGHT_OD];
  const leftLens = state.lensBuilder.lenses[EyeType.LEFT_OS];

  // plano lens types don't have thickness
  const leftIsPlano = leftLens?.detail?.prescriptionLensType === "Plano";
  const rightIsPlano = rightLens?.detail?.prescriptionLensType === "Plano";

  switch (state.lensBuilder.orderEyeConfig) {
    case EYE_OPTION_TYPE.BOTH: {
      return {
        ...complete,
        eye: true,
        type: !!leftLens?.detail?.prescriptionLensType && !!rightLens?.detail?.prescriptionLensType,
        thickness: leftIsPlano || rightIsPlano || (!!leftLens?.detail?.thickness && !!rightLens?.detail?.thickness),
        treatment: !!leftLens?.detail?.treatment && !!rightLens?.detail?.treatment,
        colour: hasColourOptions(leftLens?.detail?.treatment?.type)
          ? !isNil(leftLens?.detail?.color)
          : hasColourOptions(rightLens?.detail?.treatment?.type)
          ? !isNil(rightLens?.detail?.color)
          : true,
        lensReview: !!leftLens?.itemToAdd?.sku && !!rightLens?.itemToAdd?.sku,
      };
    }
    case EYE_OPTION_TYPE.LEFT_ONLY: {
      return {
        ...complete,
        eye: true,
        type: !!leftLens?.detail?.prescriptionLensType,
        thickness: leftIsPlano || !!leftLens?.detail?.thickness,
        treatment: !!leftLens?.detail?.treatment,
        colour: hasColourOptions(leftLens?.detail?.treatment?.type) ? !isNil(leftLens?.detail?.color) : true,
        lensReview: !!leftLens?.itemToAdd?.sku,
      };
    }
    case EYE_OPTION_TYPE.RIGHT_ONLY: {
      return {
        ...complete,
        eye: true,
        type: !!rightLens?.detail?.prescriptionLensType,
        thickness: rightIsPlano || !!rightLens?.detail?.thickness,
        treatment: !!rightLens?.detail?.treatment,
        colour: hasColourOptions(rightLens?.detail?.treatment?.type) ? !isNil(rightLens?.detail?.color) : true,
        lensReview: !!rightLens?.itemToAdd?.sku,
      };
    }
    default:
      return complete;
  }
};
export const selectStepCompletion = (state: RootState): Record<number, boolean> => {
  const { type, thickness, colour, treatment, lensReview } = selectLensStepComplete(state);
  return {
    [LENS_BUILDER_STEP.SERVICE_TYPE]: !!state.lensBuilder.service,
    [LENS_BUILDER_STEP.SEARCH_PRODUCT]: !!state.lensBuilder.frameVariant?.sku,
    [LENS_BUILDER_STEP.ENTER_PRESCRIPTION]: state.lensBuilder.prescriptionValuesAddedToCart,
    [LENS_BUILDER_STEP.SELECT_EYE_OPTION]: !!state.lensBuilder.orderEyeConfig,
    [LENS_BUILDER_STEP.LENS_TYPE]: type && thickness && colour,
    [LENS_BUILDER_STEP.LENS_TREATMENT]: treatment,
    [LENS_BUILDER_STEP.LENS_REVIEW]: lensReview,
    [LENS_BUILDER_STEP.ADD_ON]: true, // never a mandatory step
    // instead of adding a flag to state, pre-complete measurement when the previous step is complete
    [LENS_BUILDER_STEP.MEASUREMENT]: selectMeasurementStepComplete(state),
  };
};

export const selectServiceIsComplete = (state: RootState): boolean => {
  const activeServiceSteps = selectServiceSteps(state);
  const stepCompletion = selectStepCompletion(state);
  // we don't rely on completion of review step for anything so we remove it from the list
  return activeServiceSteps.filter((step) => step !== LENS_BUILDER_STEP.REVIEW).every((step) => stepCompletion[step]);
};

export const selectUpdateLineItemPrescriptionRequest = (state: RootState): updateLineItemPrescriptionDraft => {
  const items = selectAddToCartDraftItems(state) as AddToCartDraftWithLineItemId[];

  return items.reduce(
    (acc: updateLineItemPrescriptionDraft, item: AddToCartDraftWithLineItemId) => {
      const nextLineItemPrescription = convertAddToCartDraftToLineItemPrescription(item);
      return {
        ...acc,
        lineItemPrescriptions: [...acc.lineItemPrescriptions, ...(nextLineItemPrescription?.lineItemId ? [nextLineItemPrescription] : [])],
        pantoscopic_tilt: acc.pantoscopic_tilt || item.pantoscopic_tilt,
        frame_wrap: acc.frame_wrap || item.frame_wrap,
      };
    },
    { lineItemPrescriptions: [], pantoscopic_tilt: null, frame_wrap: null },
  );
};

export const selectUpdateLineItemPrescriptionRequestIsValid = (state: RootState): boolean =>
  selectUpdateLineItemPrescriptionRequest(state).lineItemPrescriptions.length > 0;
