import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { GraphQLError } from "graphql";

import { LOG_IGNORE_PATHS } from "../../../constants";

export const ignoreError = (paths: GraphQLError["path"]): boolean =>
  (paths ?? []).every((path) => LOG_IGNORE_PATHS.some(({ path: ignorePath }) => `${path}`.match(ignorePath)));

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, ...rest }) => {
      if (!ignoreError(path)) {
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Other: ${JSON.stringify(rest)}`,
        );
      }
    });
  }

  if (networkError) {
    Sentry.captureMessage(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});

export default errorLink;
