import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { SelectPatient, SelectPatientVariables } from "../../types";
import { PATIENT_FIELDS } from "../fragments";

export const SELECT_PATIENT = gql`
  ${PATIENT_FIELDS}
  query SelectPatient($posSelectPatientId: String!, $store: String!, $countryCode: String!) {
    posSelectPatient(id: $posSelectPatientId, store: $store, countryCode: $countryCode) {
      ...PatientFields
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<SelectPatient>, "data" | "loading" | "error"> {
  selectPatient: ({ variables }: { variables: SelectPatientVariables }) => void;
  called: boolean;
}

export const useLazySelectPatientQuery = (): ExtendedQueryResult => {
  const [selectPatient, { data, error, loading, called }] = useLazyQuery<SelectPatient, SelectPatientVariables>(SELECT_PATIENT, {
    onError: () => void 0,
    // these are important, do not remove
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    selectPatient,
    data,
    error,
    loading,
    called,
  };
};
