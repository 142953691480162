import styled from "@emotion/styled";
import AccountBoxOutlined from "@mui/icons-material/AccountBoxOutlined";
import AssessmentOutlined from "@mui/icons-material/AssessmentOutlined";
import CalendarTodayOutlined from "@mui/icons-material/CalendarTodayOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Person from "@mui/icons-material/Person";
import ReceiptOutlined from "@mui/icons-material/ReceiptOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import Tooltip from "@mui/material/Tooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, forwardRef } from "react";

import ExamIcon from "../components/Exam/ExamIcon";
import SidebarReportMenu from "../components/SidebarReportMenu";
import SideNavItem from "../components/SideNavItem";
import { CALENDAR_COMBINED_HEADER_HEIGHT, COLOURS, PATHS, SIZES, ZINDEX } from "../constants";
import BNBrand from "../icons/BNBrand";
import ContactLensIcon from "../icons/ContactLens";

const SideBarContainer = styled.div`
  min-height: 100vh;
  background-color: ${COLOURS.NAVY};
  z-index: ${ZINDEX.LAYOUT_SIDEBAR};
  position: fixed;
  left: 0;
  top: 0;
  overflow-y: hidden;
`;

const BrandContainer = styled.div`
  height: ${CALENDAR_COMBINED_HEADER_HEIGHT}rem;
  width: ${SIZES.SIDEBAR_WIDTH}rem;
  svg {
    margin: 1.25rem 0.5rem;
  }
`;

const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${SIZES.SIDEBAR_WIDTH}rem;
`;

// for dashboard and profile nav items
const PersonalNavItemsContainer = styled(NavItemsContainer)`
  margin-bottom: 6rem;
`;

const NavIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - ${CALENDAR_COMBINED_HEADER_HEIGHT}rem);
`;

type ContentProps = React.HTMLProps<HTMLDivElement>;

const Content = forwardRef<HTMLDivElement, ContentProps>((props, ref) => (
  <div ref={ref} {...props}>
    {props.children}
  </div>
));
Content.displayName = "Sidebar Content";

const SideBar: FC = () => {
  const { exam, storeOrder, setting, orders, contactsLensesSubscription } = useFlags();

  return (
    <SideBarContainer>
      <BrandContainer>
        <BNBrand />
      </BrandContainer>
      <NavIconContainer>
        <NavItemsContainer>
          <Tooltip placement="right" title="APPOINTMENTS">
            <Content type="Sidebar Content">
              <SideNavItem path={PATHS.APPOINTMENTS}>
                <CalendarTodayOutlined />
              </SideNavItem>
            </Content>
          </Tooltip>
          <Tooltip placement="right" title="DISPENSING">
            <Content>
              <SideNavItem path={PATHS.DISPENSING}>
                <ShoppingCartOutlined />
              </SideNavItem>
            </Content>
          </Tooltip>
          <Tooltip placement="right" title="PATIENT DETAILS">
            <Content>
              <SideNavItem path={PATHS.PATIENT}>
                <AccountBoxOutlined />
              </SideNavItem>
            </Content>
          </Tooltip>
          {orders && (
            <Tooltip placement="right" title="ORDERS">
              <Content>
                <SideNavItem path={PATHS.ORDERS}>
                  <ListAltIcon />
                </SideNavItem>
              </Content>
            </Tooltip>
          )}
          {storeOrder && (
            <Tooltip placement="right" title="INVENTORY">
              <Content>
                <SideNavItem path={PATHS.INVENTORY}>
                  <ReceiptOutlined />
                </SideNavItem>
              </Content>
            </Tooltip>
          )}
          {contactsLensesSubscription && (
            <Tooltip placement="right" title="ORDER CONTACT LENSES">
              <Content>
                <SideNavItem path={PATHS.CONTACT_LENS}>
                  <ContactLensIcon />
                </SideNavItem>
              </Content>
            </Tooltip>
          )}

          {exam && (
            <Tooltip placement="right" title="EXAM">
              <Content>
                <SideNavItem path={PATHS.EXAM}>
                  <ExamIcon />
                </SideNavItem>
              </Content>
            </Tooltip>
          )}
          {setting && (
            <Tooltip placement="right" title="SETTING">
              <Content>
                <SideNavItem path={PATHS.SETTING}>
                  <SettingsIcon />
                </SideNavItem>
              </Content>
            </Tooltip>
          )}
          <Tooltip placement="right" title="REPORT">
            <Content>
              <SidebarReportMenu>
                <SideNavItem path={PATHS.REPORT} disabled>
                  <AssessmentOutlined />
                </SideNavItem>
              </SidebarReportMenu>
            </Content>
          </Tooltip>
        </NavItemsContainer>
        <PersonalNavItemsContainer>
          <Tooltip placement="right" title="AVAILABILITY">
            <Content>
              <SideNavItem path={PATHS.AVAILABILITY}>
                <Person />
              </SideNavItem>
            </Content>
          </Tooltip>
        </PersonalNavItemsContainer>
      </NavIconContainer>
    </SideBarContainer>
  );
};

export default SideBar;
