import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { PosRetrieveDataList, PosRetrieveDataListVariables } from "../../types";

//a generic list api for all kind of data, e.g refund reason
export const LIST_DATA = gql`
  query PosRetrieveDataList($countryCode: String!, $type: PosListType) {
    posRetrieveDataList(countryCode: $countryCode, type: $type) {
      id
      type
      displayName
      countryCode
      active
      createdAt
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveDataList>, "data" | "loading" | "error"> {
  getDataList: ({ variables }: { variables: PosRetrieveDataListVariables }) => void;
  called: boolean;
}

export const useDataLazyListQuery = (): ExtendedQueryResult => {
  const [getDataList, { data, error, loading, called }] = useLazyQuery<PosRetrieveDataList, PosRetrieveDataListVariables>(LIST_DATA, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    getDataList,
    data,
    error,
    loading,
    called,
  };
};
