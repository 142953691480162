import React, { FC } from "react";

import GoogleAuth from "./GoogleAuth";
import PinAuth from "./PinAuth";

type Props = {
  children?: React.ReactNode;
};

const Authentication: FC<Props> = ({ children }) => (
  <GoogleAuth>
    <PinAuth>{children}</PinAuth>
  </GoogleAuth>
);

export default Authentication;
