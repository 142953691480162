import { gql, QueryResult, useQuery } from "@apollo/client";

import { PosRetrieveHealthFundList } from "../../types";

export const PRIVATE_HEALTH_FUNDS = gql`
  query PosRetrieveHealthFundList($countryCode: String!) {
    posRetrieveHealthFundList(countryCode: $countryCode) {
      id
      code
      name
      healthCareProviderKey
      order
    }
  }
`;

export const usePosRetrieveHealthFundListQuery = (countryCode: string): QueryResult<PosRetrieveHealthFundList> =>
  useQuery<PosRetrieveHealthFundList>(PRIVATE_HEALTH_FUNDS, {
    variables: {
      countryCode,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
