import {
  CATEGORY_SLUG,
  EYE_OPTION_TYPE,
  EyeOption,
  LENS_BUILDER_STEP,
  LensThicknessOption,
  LensType,
  PRESCRIPTION_OPTION_TYPE,
  PrescriptionSource,
  PrescriptionTypeOption,
  PRODUCT_TYPE,
  SPHERE_TYPE,
  TreatmentOption,
} from "../types";

export const PRESCRIPTION_FIELDS_EXPLICIT_POSITIVES = [
  "osSph",
  "odSph",
  "osPower",
  "odPower",
  "osCyl",
  "odCyl",
  "osAdd",
  "odAdd",
  "osIntAdd",
  "odIntAdd",
];

export const TREATMENT_OPTIONS: TreatmentOption[] = [
  {
    name: "Clear Lenses",
    image: `${process.env.PUBLIC_URL}/assets/lens-type/clear.jpg`,
    type: "Not Applicable",
    description: "Clear optical lenses",
    productType: [PRODUCT_TYPE.OPTICAL],
  },
  {
    name: "Blue Light Filter",
    image: `${process.env.PUBLIC_URL}/assets/lens-type/blue-filter.jpg`,
    type: "Blue Light Filter",
    description: "Reduces blue light emissions from digital devices",
    productType: [PRODUCT_TYPE.OPTICAL],
  },
  {
    name: "Polarised",
    image: `${process.env.PUBLIC_URL}/assets/lens-type/polorize.png`,
    type: "Polarised",
    description: "Polarised lenses reduce glare for increased clarity",
    productType: [PRODUCT_TYPE.SUNGLASSES],
  },
  {
    name: "Solid Tint",
    image: `${process.env.PUBLIC_URL}/assets/transitions/tint-grey.svg`,
    type: "Solid Tint",
    description: "Standard tinted lenses",
    productType: [PRODUCT_TYPE.SUNGLASSES],
  },
  {
    name: "Gradient Tint",
    image: `${process.env.PUBLIC_URL}/assets/transitions/tint-grey.svg`,
    type: "Gradient Tint",
    description: "Gradient tinted lenses",
    productType: [PRODUCT_TYPE.SUNGLASSES],
  },
  {
    name: "Transitions",
    image: `${process.env.PUBLIC_URL}/assets/lens-type/transitions.jpg`,
    type: "Transitions",
    description: "Transition lenses are clear indoors and dark outdoors",
    productType: [PRODUCT_TYPE.OPTICAL],
  },
  {
    name: "Transitions and Blue Light Filter",
    image: `${process.env.PUBLIC_URL}/assets/lens-type/blue-filter.jpg`,
    type: "Transitions Blue Light Filter",
    description: "Transitions with a cosmetic blue light filter",
    productType: [PRODUCT_TYPE.OPTICAL],
  },
];

export const NO_COLOUR_TREATMENTS = ["Not Applicable", "Blue Light Filter"];

export const PRESCRIPTION_TYPES: PrescriptionTypeOption[] = [
  {
    header: "Single Vision Distance",
    extra: "distance",
    type: "Single Vision",
    image: `${process.env.PUBLIC_URL}/assets/lens/distance.svg`,
    lensType: LensType.D,
  },
  {
    header: "Single Vision Reading",
    extra: "reading",
    type: "Single Vision",
    image: `${process.env.PUBLIC_URL}/assets/lens/reading.svg`,
    lensType: LensType.N,
  },
  {
    header: "Single Vision Intermediate",
    extra: "intermediate",
    type: "Single Vision",
    image: `${process.env.PUBLIC_URL}/assets/lens/reading.svg`,
    lensType: LensType.I,
  },
  {
    header: "Bifocal Lens",
    type: "Bifocal",
    image: `${process.env.PUBLIC_URL}/assets/lens/bifocal.svg`,
    lensType: LensType.B,
    requiredFields: ["add"],
  },
  {
    header: "Progressive / Multifocal - Everyday",
    type: "Everyday Multifocal",
    image: `${process.env.PUBLIC_URL}/assets/lens/progressive.svg`,
    lensType: LensType.M,
    requiredFields: ["add"],
  },
  {
    header: "Progressive / Multifocal - Premium",
    type: "Premium Multifocal",
    image: `${process.env.PUBLIC_URL}/assets/lens/progressive.svg`,
    lensType: LensType.M,
    requiredFields: ["add"],
  },
  {
    header: "Progressive / Multifocal - Ultra",
    type: "Ultra Multifocal",
    image: `${process.env.PUBLIC_URL}/assets/lens/progressive.svg`,
    lensType: LensType.M,
    requiredFields: ["add"],
  },
  {
    header: "Office Lens",
    type: "Office",
    image: `${process.env.PUBLIC_URL}/assets/lens/office.svg`,
    lensType: LensType.P,
  },
  {
    header: "Relax Lens",
    type: "Relax",
    image: `${process.env.PUBLIC_URL}/assets/lens/relax.svg`,
    lensType: LensType.M,
    requiredFields: ["add"],
  },
  {
    header: "Plano",
    type: "Plano",
    image: `${process.env.PUBLIC_URL}/assets/lens/pl.svg`,
    lensType: LensType.D,
  },
];

export const PRESCRIPTION_SOURCE: PrescriptionSource[] = [
  {
    name: "Enter my prescription",
    type: PRESCRIPTION_OPTION_TYPE.ENTER_SCRIPT,
    image: `${process.env.PUBLIC_URL}/assets/add-prescription/enter-script.jpg`,
    description: "Enter a valid prescription",
  },
  {
    name: "No valid prescription",
    type: PRESCRIPTION_OPTION_TYPE.INVALID_SCRIPT,
    image: `${process.env.PUBLIC_URL}/assets/add-prescription/no-valid.jpg`,
    description: "Book an eye exam",
  },
];

export const RightOnlyOption: EyeOption = {
  name: "Right lens only",
  image: `${process.env.PUBLIC_URL}/assets/eye-selection/right.svg`,
  type: EYE_OPTION_TYPE.RIGHT_ONLY,
  description: "I only need right lens",
};

export const LeftOnlyOption: EyeOption = {
  name: "Left lens only",
  image: `${process.env.PUBLIC_URL}/assets/eye-selection/left.svg`,
  type: EYE_OPTION_TYPE.LEFT_ONLY,
  description: "I only need the left lens",
};

export const eyeOptions: EyeOption[] = [
  {
    name: "Right and left lens",
    image: `${process.env.PUBLIC_URL}/assets/eye-selection/rl.svg`,
    type: EYE_OPTION_TYPE.BOTH,
    description: "I need both left and right lenses",
  },
  RightOnlyOption,
  LeftOnlyOption,
];

export const LENS_THICKNESS_OPTIONS: LensThicknessOption[] = [
  {
    name: "Standard (1.5 index)",
    lensIndex: 1.5,
    description: "Best for prescription below +/- 2.0",
    type: "standard",
    image: `${process.env.PUBLIC_URL}/assets/lens/standard.svg`,
    price: {
      amount: 0,
      currencyCode: "",
    },
  },
  {
    name: "BN Plus (1.59 index)",
    description: "Polycarbonate - thinner, lighter, shatter-resistant, 100% recyclable",
    lensIndex: 1.59,
    type: "polycarbonate",
    image: `${process.env.PUBLIC_URL}/assets/lens/thin.svg`,
    price: {
      amount: 0,
      currencyCode: "",
    },
  },
  {
    name: "Thin (1.6 index)",
    description: "Best for prescription between +/- 2.0-3.0",
    lensIndex: 1.6,
    type: "thin",
    image: `${process.env.PUBLIC_URL}/assets/lens/thin.svg`,
    price: {
      amount: 0,
      currencyCode: "",
    },
  },
  {
    name: "Thinner (1.67 index)",
    description: "Best for prescription between +/- 3.0-4.0",
    lensIndex: 1.67,
    type: "thinner",
    image: `${process.env.PUBLIC_URL}/assets/lens/thinner.svg`,
    price: {
      amount: 0,
      currencyCode: "",
    },
  },
  {
    name: "Thinnest (1.74 index)",
    description: "Best for prescription above +/- 4.0",
    lensIndex: 1.74,
    type: "thinnest",
    image: `${process.env.PUBLIC_URL}/assets/lens/thinnest.svg`,
    price: {
      amount: 0,
      currencyCode: "",
    },
  },
];

// dictates which steps applies for each service category.
// relevant to the progress bar component and the setStep(Next) action that moves the user through the dispensing flow
export const serviceOptions = {
  framesCustomised: {
    slug: CATEGORY_SLUG.FRAME,
    requiresPatient: true,
    displayName: "Frames (Customised)",
    steps: [
      LENS_BUILDER_STEP.SERVICE_TYPE,
      LENS_BUILDER_STEP.SEARCH_PRODUCT,
      LENS_BUILDER_STEP.ENTER_PRESCRIPTION,
      LENS_BUILDER_STEP.SELECT_EYE_OPTION,
      LENS_BUILDER_STEP.LENS_TYPE,
      LENS_BUILDER_STEP.LENS_TREATMENT,
      LENS_BUILDER_STEP.LENS_REVIEW,
      LENS_BUILDER_STEP.ADD_ON,
      LENS_BUILDER_STEP.MEASUREMENT,
      LENS_BUILDER_STEP.REVIEW,
    ],
  },
  framesOffTheShelf: {
    slug: CATEGORY_SLUG.FRAME,
    displayName: "Frames (Off the shelf)",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.LENSES]: {
    slug: CATEGORY_SLUG.FRAME,
    requiresPatient: true,
    displayName: "Lens Only",
    steps: [
      LENS_BUILDER_STEP.SERVICE_TYPE,
      LENS_BUILDER_STEP.SEARCH_PRODUCT,
      LENS_BUILDER_STEP.ENTER_PRESCRIPTION,
      LENS_BUILDER_STEP.SELECT_EYE_OPTION,
      LENS_BUILDER_STEP.LENS_TYPE,
      LENS_BUILDER_STEP.LENS_TREATMENT,
      LENS_BUILDER_STEP.LENS_REVIEW,
      LENS_BUILDER_STEP.ADD_ON,
      LENS_BUILDER_STEP.MEASUREMENT,
      LENS_BUILDER_STEP.REVIEW,
    ],
  },
  [CATEGORY_SLUG.CONTACT_LENSES]: {
    slug: CATEGORY_SLUG.CONTACT_LENSES,
    requiresPatient: true,
    displayName: "Contact Lenses",
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.ENTER_PRESCRIPTION, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.SERVICES]: {
    slug: CATEGORY_SLUG.SERVICES,
    displayName: "Services",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.ACCESSORIES]: {
    slug: CATEGORY_SLUG.ACCESSORIES,
    displayName: "Accessories",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  demoLens: {
    slug: CATEGORY_SLUG.FRAME,
    displayName: "Demo Lens",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
};

export const serviceOptionsNew = {
  framesCustomised: {
    slug: CATEGORY_SLUG.FRAME,
    requiresPatient: true,
    displayName: "Frames (Customised)",
    steps: [
      LENS_BUILDER_STEP.SERVICE_TYPE,
      LENS_BUILDER_STEP.SEARCH_PRODUCT,
      LENS_BUILDER_STEP.ENTER_PRESCRIPTION,
      LENS_BUILDER_STEP.LENS_TYPE,
      LENS_BUILDER_STEP.MEASUREMENT,
      LENS_BUILDER_STEP.REVIEW,
    ],
  },
  framesOffTheShelf: {
    slug: CATEGORY_SLUG.FRAME,
    displayName: "Frames (Off the shelf)",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.LENSES]: {
    slug: CATEGORY_SLUG.FRAME,
    requiresPatient: true,
    displayName: "Lens Only",
    steps: [
      LENS_BUILDER_STEP.SERVICE_TYPE,
      LENS_BUILDER_STEP.SEARCH_PRODUCT,
      LENS_BUILDER_STEP.ENTER_PRESCRIPTION,
      LENS_BUILDER_STEP.LENS_TYPE,
      LENS_BUILDER_STEP.MEASUREMENT,
      LENS_BUILDER_STEP.REVIEW,
    ],
  },
  [CATEGORY_SLUG.CONTACT_LENSES]: {
    slug: CATEGORY_SLUG.CONTACT_LENSES,
    requiresPatient: true,
    displayName: "Contact Lenses",
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.ENTER_PRESCRIPTION, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.SERVICES]: {
    slug: CATEGORY_SLUG.SERVICES,
    displayName: "Services",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  [CATEGORY_SLUG.ACCESSORIES]: {
    slug: CATEGORY_SLUG.ACCESSORIES,
    displayName: "Accessories",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
  demoLens: {
    slug: CATEGORY_SLUG.FRAME,
    displayName: "Demo Lens",
    requiresPatient: true,
    steps: [LENS_BUILDER_STEP.SERVICE_TYPE, LENS_BUILDER_STEP.SEARCH_PRODUCT, LENS_BUILDER_STEP.REVIEW],
  },
};

export const quoteSkippedSteps = {
  framesCustomised: [
    LENS_BUILDER_STEP.ENTER_PRESCRIPTION,
    LENS_BUILDER_STEP.SELECT_EYE_OPTION,
    LENS_BUILDER_STEP.MEASUREMENT,
    LENS_BUILDER_STEP.REVIEW,
  ],
  lensOnly: [LENS_BUILDER_STEP.ENTER_PRESCRIPTION, LENS_BUILDER_STEP.MEASUREMENT, LENS_BUILDER_STEP.REVIEW],
};

export const sphereRequiredFields = {
  [SPHERE_TYPE.MULTI_FOCAL]: ["power", "add"],
  [SPHERE_TYPE.TORIC]: ["power", "cyl", "axis"],
  [SPHERE_TYPE.SPHERIC]: ["power"],
  [SPHERE_TYPE.MULTI_FOCAL_TORIC]: ["power", "add", "cyl", "axis"],
};

export const DEMO_LENS_SKU = {
  AU: "BNL4559",
  NZ: "BNL4559",
};

export const DISABLE_AUTO_FILL = "off";
