import styled from "@emotion/styled";
import React, { FC } from "react";

import { TEST_DATA_IDS } from "../constants";

const Container = styled.div``;

const ContactLens: FC = () => (
  <Container data-testid={TEST_DATA_IDS.SIDEBAR_CONTACT_LENS}>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Nav/small/dispensing" transform="translate(-13.000000, -441.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="contact-lens" transform="translate(13.000000, 441.000000)">
            <path
              d="M22.1494823,1.85051772 C19.6821253,-0.616839241 15.6675199,-0.616839241 13.2001629,1.85051772 C11.800383,3.25029761 11.1951336,5.14798325 11.3835709,6.97868459 L6.97868459,11.3835709 C5.14798325,11.1951336 3.25025073,11.800383 1.85051772,13.2001629 C-0.616839241,15.6675199 -0.616839241,19.6821253 1.85051772,22.1494823 C4.31787469,24.6168392 8.33248014,24.6168392 10.7998371,22.1494823 C12.2009764,20.748343 12.8060383,18.8483136 12.6158197,17.0159717 L17.0159717,12.6158197 C18.8483136,12.8059914 20.7483899,12.2009295 22.1494823,10.7998371 C24.6168392,8.33248014 24.6168392,4.31782781 22.1494823,1.85051772 Z M9.80547871,21.155077 C7.88646495,23.0740907 4.76393675,23.0740907 2.844923,21.155077 C0.925909252,19.2360632 0.925909252,16.113535 2.844923,14.1945213 C4.76393675,12.2755075 7.88646495,12.2755075 9.80547871,14.1945213 C11.7244925,16.113535 11.7244925,19.2360632 9.80547871,21.155077 L9.80547871,21.155077 Z M12.2335076,15.409567 C11.9259141,14.6037396 11.4482115,13.8484904 10.799884,13.2001629 C10.1504315,12.5507104 9.39368223,12.0723984 8.58621427,11.7648518 L11.7649455,8.58612052 C12.0724921,9.39358848 12.5507573,10.1503377 13.2002566,10.7997902 C13.8485841,11.4481177 14.6038334,11.9258204 15.4096607,12.2334138 L12.2335076,15.409567 Z M21.1551239,9.80543183 C19.2360632,11.7244925 16.1135819,11.7244456 14.1945682,9.80543183 C12.2755544,7.88641808 12.2755075,4.76388988 14.1945682,2.84487613 C16.1136288,0.925862377 19.2361101,0.925862377 21.1551239,2.84487613 C23.0741376,4.76388988 23.0741845,7.8863712 21.1551239,9.80543183 Z"
              id="Shape"
            ></path>
            <path
              d="M20.9492491,6.37123206 L18.7898606,6.23356032 C19.2565476,5.38920177 19.149954,4.32396843 18.457939,3.63195348 C17.6138148,2.78782931 16.2140349,2.81407928 15.3376608,3.69045343 L14.2807243,4.7473899 C14.28049,4.74762427 14.2803025,4.74785864 14.280115,4.74809302 C14.2798806,4.74832739 14.2796462,4.74846802 14.2794587,4.74870239 C14.0048652,5.02329588 14.0048652,5.46846732 14.2794587,5.7430608 L18.2569392,9.72054129 C18.5315327,9.99513477 18.9767041,9.99513477 19.2512976,9.72054129 C19.5258911,9.44594781 19.5258911,9.00077637 19.2512976,8.72618288 L18.1254081,7.60029336 L20.8598585,7.77462131 C21.0698115,7.78802755 21.2640613,7.7078245 21.4019674,7.56991839 C21.5186391,7.45324663 21.5949516,7.29527803 21.6062953,7.11766883 C21.6309515,6.73015358 21.3368112,6.39593516 20.9492491,6.37123206 Z M17.4050807,5.75792016 C17.2757527,5.88724816 17.0564247,6.10831044 16.8517687,6.31502899 C16.6499251,6.11520106 15.9843945,5.45252983 15.7738323,5.24309254 L16.3320661,4.68485871 C16.6546126,4.36231215 17.1728153,4.33549967 17.4636275,4.62635876 C17.7544397,4.91717098 17.7276272,5.43532672 17.4050807,5.75792016 L17.4050807,5.75792016 Z"
              id="Shape"
            ></path>
            <path
              d="M8.84130777,18.1351112 C8.84037027,18.1360487 8.8393859,18.1370331 8.8384484,18.1379706 C8.53362057,18.4462203 8.20652714,18.7758449 7.94894927,19.0340791 L4.46871829,15.5538481 C4.19412481,15.2792546 3.74895337,15.2792546 3.47435989,15.5538481 C3.19976641,15.8284416 3.19976641,16.273613 3.47435989,16.5482065 L7.44846538,20.522312 C7.6906214,20.764468 8.07199602,20.7969523 8.35160513,20.5992338 C8.39308946,20.5698901 9.38908849,19.5811098 9.83838492,19.1267509 C10.1114315,18.8506105 10.1089003,18.4054391 9.8328068,18.1323924 C9.55765082,17.8602833 9.11458876,17.8618302 8.84130777,18.1351112 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </Container>
);

export default ContactLens;
