import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AutoRefractionPrescriptionData,
  CurrentRefractionPrescriptionData,
  DiagnosisManagementData,
  DilatedFundus,
  ExamHistoryData,
  ExamOcularData,
  ExamRefractionData,
  ExamVisionAidData,
  ExamVisionCoverTestData,
  ExamVisionData,
  ExamVisionOptionalData,
  ExamVisionPinholeData,
  ExamVisionUnaidData,
  FinalRefractionPrescriptionData,
  OcularIopData,
  OcularOptionalData,
  OcularSegmentData,
  RefractionPrescriptionData,
} from "../../types";

export interface ExamState {
  examHistory: ExamHistoryData;
  examRefraction: ExamRefractionData;
  examVision: ExamVisionData;
  examOcular: ExamOcularData;
  examDiagnosisManagement: DiagnosisManagementData;
}

const initAutoRefraction: AutoRefractionPrescriptionData = {
  odSph: "",
  odCyl: "",
  odAxis: "",
  odVa: "",

  autoPd: "",

  osSph: "",
  osCyl: "",
  osAxis: "",
  osVa: "",

  note: "",
};
const initCurrentRx: CurrentRefractionPrescriptionData = {
  lensType: "",
  pd: "",

  odSph: "",
  odCyl: "",
  odAxis: "",
  odVa: "",
  odAdd: "",

  osSph: "",
  osCyl: "",
  osAxis: "",
  osVa: "",
  osAdd: "",

  note: "",
};

const initFinalRx: FinalRefractionPrescriptionData = {
  odSph: "",
  odCyl: "",
  odAxis: "",
  odVa: "",
  odAdd: "",
  odNva: "",
  odIntAdd: "",
  odHPrism: "",
  odHPrism2: "",
  odVPrism: "",
  odVPrism2: "",
  odBVD: "",

  osSph: "",
  osCyl: "",
  osAxis: "",
  osVa: "",
  osAdd: "",
  osNva: "",
  osIntAdd: "",
  osHPrism: "",
  osHPrism2: "",
  osVPrism: "",
  osVPrism2: "",
  osBVD: "",
  note: "",
};

const initRefractionPrescriptionData: RefractionPrescriptionData = {
  odSph: "",
  odCyl: "",
  odAxis: "",
  odVa: "",

  osSph: "",
  osCyl: "",
  osAxis: "",
  osVa: "",
  odAdd: "",
  odIntAdd: "",
  odNva: "",
  osAdd: "",
  osIntAdd: "",
  osNva: "",
};

export const initialExamState: ExamState = {
  examHistory: {
    presentingComplaint: "",
    clHistory: "",
    generalHealth: "",
    pastOcular: "",
    familyOcular: "",
    occupation: "",
    occupationDescription: "",
    drivingDetail: "",
  },
  examVision: {
    aidData: {
      lensType: "",
      aidedNear: "",
      aidDistance: "",
      odAidDistance: "",
      osAidDistance: "",
      ouAidDistance: "",
    },
    unaidData: {
      unaidedNear: "",
      unadDistance: "",
      odUnaidDistance: "",
      osUnaidDistance: "",
      ouUnaidDistance: "",
    },

    pinholeData: {
      odPinhole: "",
      osPinhole: "",
    },
    coverTestData: {
      odAidDistance: "",
      osAidDistance: "",

      odUnaidDistance: "",
      osUnaidDistance: "",

      odUnaidedNear: "",
      osUnaidedNear: "",

      odAidedNear: "",
      osAidedNear: "",

      npcBlur: "",
      npaBlur: "",

      npcBreak: "",
      npaBreak: "",

      npcRecovery: "",
      npaRecovery: "",
    },
    optionalData: {
      confrontation: "",
      pupils: "",
      excursion: "",
      colorVision: {
        test: "",
        result: "",
        diagnosis: "",
      },
    },
  },
  examRefraction: {
    currentRx: [initCurrentRx],
    autoRefraction: initAutoRefraction,
    finalRx: [initFinalRx],
    subjectiveRx: [initFinalRx],
    retinoscopy: initRefractionPrescriptionData,
    cycloplegic: initRefractionPrescriptionData,
    refractionNote: "",
    binocularVision: "",
  },
  examOcular: {
    iopData: {
      odIop: "",
      osIop: "",
      odCorrected: "",
      osCorrected: "",
      odPachymetry: "",
      osPachymetry: "",
      device: "",
      time: "",
    },
    ocularSegmentData: {
      anterior: "",
      posterior: "",
      anteriorFileUrl: "",
      posteriorFileUrl: "",
    },
    dilatedFundus: {
      isConsent: false,
      time: "",
      odDrop: "",
      osDrop: "",

      odDrop2: "",
      osDrop2: "",
    },
    optionalData: {
      amsler: "",
      vfe: "",
      vfeFileUrl: "",
    },
  },
  examDiagnosisManagement: {
    diagnosis: "",
    management: "",
  },
};

export const examSlice = createSlice({
  name: "exam",
  initialState: initialExamState,
  reducers: {
    setExamHistory: (state, action: PayloadAction<ExamHistoryData>) => {
      state.examHistory = action.payload;
    },

    setExamDiagnosis: (state, action: PayloadAction<DiagnosisManagementData>) => {
      state.examDiagnosisManagement = action.payload;
    },

    setVisionAid: (state, action: PayloadAction<ExamVisionAidData>) => {
      state.examVision.aidData = action.payload;
    },

    setVisionUnaid: (state, action: PayloadAction<ExamVisionUnaidData>) => {
      state.examVision.unaidData = action.payload;
    },

    setVisionPinhole: (state, action: PayloadAction<ExamVisionPinholeData>) => {
      state.examVision.pinholeData = action.payload;
    },

    setVisionCoverTest: (state, action: PayloadAction<ExamVisionCoverTestData>) => {
      state.examVision.coverTestData = action.payload;
    },

    setVisionOptional: (state, action: PayloadAction<ExamVisionOptionalData>) => {
      state.examVision.optionalData = action.payload;
    },

    setRefractionCurrentRx: (state, action: PayloadAction<CurrentRefractionPrescriptionData[]>) => {
      state.examRefraction.currentRx = action.payload;
    },

    setRefractionSubjectiveRx: (state, action: PayloadAction<FinalRefractionPrescriptionData[]>) => {
      //save type as final rx
      state.examRefraction.subjectiveRx = action.payload;
    },

    setRefractionFinalRx: (state, action: PayloadAction<FinalRefractionPrescriptionData[]>) => {
      state.examRefraction.finalRx = action.payload;
    },

    setAutoRefraction: (state, action: PayloadAction<AutoRefractionPrescriptionData>) => {
      state.examRefraction.autoRefraction = action.payload;
    },

    setRentinoscopyRefraction: (state, action: PayloadAction<RefractionPrescriptionData>) => {
      state.examRefraction.retinoscopy = action.payload;
    },

    setCycloplegicRefraction: (state, action: PayloadAction<RefractionPrescriptionData>) => {
      state.examRefraction.cycloplegic = action.payload;
    },

    setRefractionNote: (state, action: PayloadAction<string>) => {
      state.examRefraction.refractionNote = action.payload;
    },

    setBinocularVisionnNote: (state, action: PayloadAction<string>) => {
      state.examRefraction.binocularVision = action.payload;
    },

    setOcularIop: (state, action: PayloadAction<OcularIopData>) => {
      state.examOcular.iopData = action.payload;
    },

    setOcularSegment: (state, action: PayloadAction<OcularSegmentData>) => {
      state.examOcular.ocularSegmentData = action.payload;
    },

    setOcularOptional: (state, action: PayloadAction<OcularOptionalData>) => {
      state.examOcular.optionalData = action.payload;
    },

    setOcularDilatedFundus: (state, action: PayloadAction<DilatedFundus>) => {
      state.examOcular.dilatedFundus = action.payload;
    },
  },
});

export const {
  setExamHistory,
  setExamDiagnosis,
  setVisionAid,
  setVisionUnaid,
  setVisionPinhole,
  setVisionCoverTest,
  setVisionOptional,
  setRefractionCurrentRx,
  setRefractionSubjectiveRx,
  setRefractionFinalRx,
  setAutoRefraction,
  setRentinoscopyRefraction,
  setCycloplegicRefraction,
  setRefractionNote,
  setBinocularVisionnNote,
  setOcularIop,
  setOcularSegment,
  setOcularOptional,
  setOcularDilatedFundus,
} = examSlice.actions;
export default examSlice.reducer;
