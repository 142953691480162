/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddBundleToCartMutation
// ====================================================

export interface AddBundleToCartMutation_addBundleToCart_lineItems_name {
  en: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_productSlug {
  en: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_productType {
  key: string | null;
  id: string;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_variant_images {
  url: string;
  label: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
  eoltTraceLib: boolean | null;
  bnTraceLib: boolean | null;
  images: AddBundleToCartMutation_addBundleToCart_lineItems_variant_images[];
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_price_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_price_discounted {
  value: AddBundleToCartMutation_addBundleToCart_lineItems_price_discounted_value;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_price {
  value: AddBundleToCartMutation_addBundleToCart_lineItems_price_value;
  discounted: AddBundleToCartMutation_addBundleToCart_lineItems_price_discounted | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_discounts_includedDiscounts_amount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_discounts_includedDiscounts {
  name: string | null;
  description: string | null;
  amount: AddBundleToCartMutation_addBundleToCart_lineItems_discounts_includedDiscounts_amount | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_discounts {
  totalDiscount: AddBundleToCartMutation_addBundleToCart_lineItems_discounts_totalDiscount | null;
  includedDiscounts: (AddBundleToCartMutation_addBundleToCart_lineItems_discounts_includedDiscounts | null)[] | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_supplyChannel {
  key: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_distributionChannel_name {
  en: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_distributionChannel {
  id: string;
  name: AddBundleToCartMutation_addBundleToCart_lineItems_distributionChannel_name | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_shippingDetails_targets {
  addressKey: string;
  quantity: number;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_shippingDetails {
  targets: AddBundleToCartMutation_addBundleToCart_lineItems_shippingDetails_targets[];
  valid: boolean;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems_customFields {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  bvd: string | null;
  bundleNumber: number | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  lensType: LensType | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  frameSku: string | null;
  sunOpt: boolean | null;
  genericAdd: string | null;
  isQuote: boolean | null;
  fitToFrame: boolean | null;
}

export interface AddBundleToCartMutation_addBundleToCart_lineItems {
  id: string;
  productId: string;
  name: AddBundleToCartMutation_addBundleToCart_lineItems_name | null;
  productSlug: AddBundleToCartMutation_addBundleToCart_lineItems_productSlug | null;
  productType: AddBundleToCartMutation_addBundleToCart_lineItems_productType | null;
  variant: AddBundleToCartMutation_addBundleToCart_lineItems_variant | null;
  price: AddBundleToCartMutation_addBundleToCart_lineItems_price;
  quantity: number;
  discounts: AddBundleToCartMutation_addBundleToCart_lineItems_discounts | null;
  supplyChannel: AddBundleToCartMutation_addBundleToCart_lineItems_supplyChannel | null;
  distributionChannel: AddBundleToCartMutation_addBundleToCart_lineItems_distributionChannel | null;
  shippingDetails: AddBundleToCartMutation_addBundleToCart_lineItems_shippingDetails | null;
  totalPrice: AddBundleToCartMutation_addBundleToCart_lineItems_totalPrice | null;
  customFields: AddBundleToCartMutation_addBundleToCart_lineItems_customFields | null;
}

export interface AddBundleToCartMutation_addBundleToCart_customFields {
  specialInstructions: string | null;
  contactMethod: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface AddBundleToCartMutation_addBundleToCart_shippingAddress_country {
  code: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_shippingAddress {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: AddBundleToCartMutation_addBundleToCart_shippingAddress_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_shippingInfo_shippingMethod {
  key: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_shippingInfo {
  shippingMethodName: string;
  shippingMethod: AddBundleToCartMutation_addBundleToCart_shippingInfo_shippingMethod | null;
}

export interface AddBundleToCartMutation_addBundleToCart_paymentInfo_payments_amountPlanned {
  centAmount: number;
  fractionDigits: number | null;
  currencyCode: string;
  type: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_paymentInfo_payments {
  key: string | null;
  amountPlanned: AddBundleToCartMutation_addBundleToCart_paymentInfo_payments_amountPlanned | null;
  creditCardNumber: string | null;
  paymentMethod: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_paymentInfo {
  payments: AddBundleToCartMutation_addBundleToCart_paymentInfo_payments[];
}

export interface AddBundleToCartMutation_addBundleToCart_discountCodes_discountCodeRef {
  typeId: string;
  id: string;
}

export interface AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode_name {
  en: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode_description {
  en: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode {
  code: string;
  validFrom: any | null;
  validUntil: any | null;
  name: AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode_name | null;
  description: AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode_description | null;
  id: string | null;
}

export interface AddBundleToCartMutation_addBundleToCart_discountCodes {
  discountCodeRef: AddBundleToCartMutation_addBundleToCart_discountCodes_discountCodeRef | null;
  state: DiscountCodeState | null;
  discountCode: AddBundleToCartMutation_addBundleToCart_discountCodes_discountCode | null;
}

export interface AddBundleToCartMutation_addBundleToCart {
  id: string;
  createdAt: any | null;
  isQuote: boolean | null;
  lineItems: AddBundleToCartMutation_addBundleToCart_lineItems[];
  customFields: AddBundleToCartMutation_addBundleToCart_customFields | null;
  totalPrice: AddBundleToCartMutation_addBundleToCart_totalPrice;
  shippingAddress: AddBundleToCartMutation_addBundleToCart_shippingAddress | null;
  shippingInfo: AddBundleToCartMutation_addBundleToCart_shippingInfo | null;
  paymentInfo: AddBundleToCartMutation_addBundleToCart_paymentInfo | null;
  discountCodes: (AddBundleToCartMutation_addBundleToCart_discountCodes | null)[] | null;
}

export interface AddBundleToCartMutation {
  addBundleToCart: AddBundleToCartMutation_addBundleToCart | null;
}

export interface AddBundleToCartMutationVariables {
  addBundleToCartAddBundleToCartRequest: addBundleToCartDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SpecialInstructions
// ====================================================

export interface SpecialInstructions_specialInstructions_customFields {
  specialInstructions: string | null;
}

export interface SpecialInstructions_specialInstructions {
  customFields: SpecialInstructions_specialInstructions_customFields | null;
}

export interface SpecialInstructions {
  specialInstructions: SpecialInstructions_specialInstructions | null;
}

export interface SpecialInstructionsVariables {
  instructions: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitHealthFundRefund
// ====================================================

export interface SubmitHealthFundRefund_submitHealthFundRefund {
  id: string;
}

export interface SubmitHealthFundRefund {
  submitHealthFundRefund: SubmitHealthFundRefund_submitHealthFundRefund | null;
}

export interface SubmitHealthFundRefundVariables {
  hfRefundRequest: HFRefundRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ApplyPromotion
// ====================================================

export interface ApplyPromotion_applyPromotion_totalPrice {
  centAmount: number;
}

export interface ApplyPromotion_applyPromotion_discountCodes_discountCode {
  code: string;
  id: string | null;
}

export interface ApplyPromotion_applyPromotion_discountCodes {
  discountCode: ApplyPromotion_applyPromotion_discountCodes_discountCode | null;
}

export interface ApplyPromotion_applyPromotion {
  id: string;
  version: number;
  totalPrice: ApplyPromotion_applyPromotion_totalPrice;
  discountCodes: (ApplyPromotion_applyPromotion_discountCodes | null)[] | null;
}

export interface ApplyPromotion {
  applyPromotion: ApplyPromotion_applyPromotion | null;
}

export interface ApplyPromotionVariables {
  promoCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCalculateRefund
// ====================================================

export interface PosCalculateRefund_calculateRefundAmount_amountOrigional {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosCalculateRefund_calculateRefundAmount_amountPreviouslyRefunded {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosCalculateRefund_calculateRefundAmount_refundAmount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosCalculateRefund_calculateRefundAmount_paymentMethods_amountOrigional {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosCalculateRefund_calculateRefundAmount_paymentMethods_amountPreviouslyRefunded {
  type: string | null;
  currencyCode: string;
  fractionDigits: number | null;
  centAmount: number;
}

export interface PosCalculateRefund_calculateRefundAmount_paymentMethods_amountAvailableForRefund {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosCalculateRefund_calculateRefundAmount_paymentMethods {
  paymentMethod: string | null;
  amountOrigional: PosCalculateRefund_calculateRefundAmount_paymentMethods_amountOrigional | null;
  amountPreviouslyRefunded: PosCalculateRefund_calculateRefundAmount_paymentMethods_amountPreviouslyRefunded | null;
  amountAvailableForRefund: PosCalculateRefund_calculateRefundAmount_paymentMethods_amountAvailableForRefund | null;
}

export interface PosCalculateRefund_calculateRefundAmount {
  orderId: string | null;
  orderNumber: string | null;
  amountOrigional: PosCalculateRefund_calculateRefundAmount_amountOrigional;
  amountPreviouslyRefunded: PosCalculateRefund_calculateRefundAmount_amountPreviouslyRefunded | null;
  refundAmount: PosCalculateRefund_calculateRefundAmount_refundAmount | null;
  paymentMethods: (PosCalculateRefund_calculateRefundAmount_paymentMethods | null)[] | null;
}

export interface PosCalculateRefund {
  /**
   * calculate Refund Amount
   */
  calculateRefundAmount: PosCalculateRefund_calculateRefundAmount | null;
}

export interface PosCalculateRefundVariables {
  refundCalculationRequest: OrderRefundCalculationRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreateAppointmentMutation
// ====================================================

export interface PosCreateAppointmentMutation_posCreateAppointment_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
}

export interface PosCreateAppointmentMutation_posCreateAppointment_patient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PosCreateAppointmentMutation_posCreateAppointment_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosCreateAppointmentMutation_posCreateAppointment_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (PosCreateAppointmentMutation_posCreateAppointment_optometrist_appointmentTypes | null)[] | null;
}

export interface PosCreateAppointmentMutation_posCreateAppointment_appointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosCreateAppointmentMutation_posCreateAppointment {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The ISO 3166 alpha-2 country code for this appointment.
   */
  countryCode: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * The source of the appointment. Currently one of STORE or ONLINE.
   */
  source: AppointmentSource;
  /**
   * Generic notes field related to this appointment
   */
  notes: string | null;
  /**
   * The status of the appointment. Currently one of BOOKED (default), CONFIRMED, CANCELLED, COMPLETED, WAITING. Additional status are expected to be required.
   */
  status: AppointmentStatus;
  /**
   * Use the storeId property from the DynamoDB record to find the Store associated with this appointment.
   */
  distributionChannel: PosCreateAppointmentMutation_posCreateAppointment_distributionChannel | null;
  /**
   * Use the patientId property from the DynamoDB record to find the Patient associated with this appointment or null if there is none.
   * TODO: How do we link up patients?
   */
  patient: PosCreateAppointmentMutation_posCreateAppointment_patient | null;
  /**
   * Use the optometristId property from the DynamoDB record to find the Optometrist associated with this appointment.
   */
  optometrist: PosCreateAppointmentMutation_posCreateAppointment_optometrist | null;
  /**
   * Use the appointmentTypeId property from the DynamoDB record to find the AppointmentType associated with this appointment.
   */
  appointmentType: PosCreateAppointmentMutation_posCreateAppointment_appointmentType | null;
}

export interface PosCreateAppointmentMutation {
  posCreateAppointment: PosCreateAppointmentMutation_posCreateAppointment | null;
}

export interface PosCreateAppointmentMutationVariables {
  posCreateAppointmentAppointmentRequest?: AppointmentRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreateAppointmentTypeMutation
// ====================================================

export interface PosCreateAppointmentTypeMutation_posCreateAppointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * color in hex code
   */
  color: string | null;
}

export interface PosCreateAppointmentTypeMutation {
  /**
   * Create POS Appointment Type
   */
  posCreateAppointmentType: PosCreateAppointmentTypeMutation_posCreateAppointmentType | null;
}

export interface PosCreateAppointmentTypeMutationVariables {
  posAppointmentTypeRquest?: AppointmentTypeRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreateAvailabilityMutation
// ====================================================

export interface PosCreateAvailabilityMutation_posCreateAvailability_availabilities_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The Store Name
   */
  name: string | null;
}

export interface PosCreateAvailabilityMutation_posCreateAvailability_availabilities_optometrist {
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
}

export interface PosCreateAvailabilityMutation_posCreateAvailability_availabilities {
  /**
   * The automatically assigned ID for this availability. Use a UUID or KUID.
   */
  id: string;
  /**
   * The distribution channel for this availability
   */
  distributionChannel: PosCreateAvailabilityMutation_posCreateAvailability_availabilities_distributionChannel | null;
  /**
   * The ISO 8601 date and time the appointment starts in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no earlier than the startAt value.
   */
  peakStartAt: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no later than the endAt value.
   */
  peakEndAt: string | null;
  /**
   * Ths optometrist this availability is for
   */
  optometrist: PosCreateAvailabilityMutation_posCreateAvailability_availabilities_optometrist | null;
}

export interface PosCreateAvailabilityMutation_posCreateAvailability {
  /**
   * availability is returned in case of success
   */
  availabilities: (PosCreateAvailabilityMutation_posCreateAvailability_availabilities | null)[] | null;
  /**
   * Error in case of failure
   */
  errors: (string | null)[] | null;
}

export interface PosCreateAvailabilityMutation {
  /**
   * Create an availability
   */
  posCreateAvailability: PosCreateAvailabilityMutation_posCreateAvailability | null;
}

export interface PosCreateAvailabilityMutationVariables {
  availabilityRequests?: (AvailabilityRequest | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreateCalendarNoteMutation
// ====================================================

export interface PosCreateCalendarNoteMutation_createCalendarNote {
  id: string;
  date: string;
  note: string;
  updatedAt: string;
}

export interface PosCreateCalendarNoteMutation {
  createCalendarNote: PosCreateCalendarNoteMutation_createCalendarNote | null;
}

export interface PosCreateCalendarNoteMutationVariables {
  distributionChannelKey: string;
  date: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactsPrescription
// ====================================================

export interface CreateContactsPrescription_createContactsPrescription_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface CreateContactsPrescription_createContactsPrescription {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odSku: string | null;
  odSupplyChannelsKey: string | null;
  osSupplyChannelsKey: string | null;
  odPower: number | null;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  odGenericAdd: string | null;
  osSku: string | null;
  osPower: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  osGenericAdd: string | null;
  optometrist: CreateContactsPrescription_createContactsPrescription_optometrist | null;
}

export interface CreateContactsPrescription {
  createContactsPrescription: CreateContactsPrescription_createContactsPrescription | null;
}

export interface CreateContactsPrescriptionVariables {
  contactsPrescriptionRequest?: ContactsPrescriptionRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateCustomerShippingAddress
// ====================================================

export interface UpdateCustomerShippingAddress_updateCustomerShippingAddress_addresses_country {
  code: string | null;
}

export interface UpdateCustomerShippingAddress_updateCustomerShippingAddress_addresses {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: UpdateCustomerShippingAddress_updateCustomerShippingAddress_addresses_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface UpdateCustomerShippingAddress_updateCustomerShippingAddress {
  addresses: UpdateCustomerShippingAddress_updateCustomerShippingAddress_addresses[];
}

export interface UpdateCustomerShippingAddress {
  updateCustomerShippingAddress: UpdateCustomerShippingAddress_updateCustomerShippingAddress | null;
}

export interface UpdateCustomerShippingAddressVariables {
  addressRequest: addressDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateGlassesPrescription
// ====================================================

export interface CreateGlassesPrescription_createGlassesPrescription_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface CreateGlassesPrescription_createGlassesPrescription {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  optometrist: CreateGlassesPrescription_createGlassesPrescription_optometrist | null;
  odSph: number;
  odIntAdd: number | null;
  odPd: number | null;
  odNearPd: number | null;
  odHPrismType: HPrismType | null;
  odHPrism: number | null;
  odVPrismType: VPrismType | null;
  odVPrism: number | null;
  osSph: number;
  osIntAdd: number | null;
  osPd: number | null;
  osNearPd: number | null;
  osHPrismType: HPrismType | null;
  osHPrism: number | null;
  osVPrismType: VPrismType | null;
  osVPrism: number | null;
}

export interface CreateGlassesPrescription {
  createGlassesPrescription: CreateGlassesPrescription_createGlassesPrescription | null;
}

export interface CreateGlassesPrescriptionVariables {
  glassesPrescriptionRequest: GlassesPrescriptionRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreateOptometristMutation
// ====================================================

export interface PosCreateOptometristMutation_posCreateOptometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
}

export interface PosCreateOptometristMutation {
  /**
   * Create an new optometrist
   */
  posCreateOptometrist: PosCreateOptometristMutation_posCreateOptometrist | null;
}

export interface PosCreateOptometristMutationVariables {
  posCreateOptometristOptometristRequest?: OptometristRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosCreatePatientMutation
// ====================================================

export interface PosCreatePatientMutation_posCreatePatient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PosCreatePatientMutation {
  /**
   * Create a POS Patient
   */
  posCreatePatient: PosCreatePatientMutation_posCreatePatient | null;
}

export interface PosCreatePatientMutationVariables {
  firstName: string;
  store: string;
  countryCode: string;
  dateOfBirth?: string | null;
  lastName?: string | null;
  preferredName?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  landline?: string | null;
  workNumber?: string | null;
  marketingEmailsOptin?: boolean | null;
  medicare?: string | null;
  medicareReference?: string | null;
  medicareExpiry?: string | null;
  isMedicareValidated?: boolean | null;
  notes?: string | null;
  privateHealthFundCode?: string | null;
  privateHealthFundMemberNumber?: string | null;
  privateHealthFundMemberReference?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosDeleteAppointment
// ====================================================

export interface PosDeleteAppointment_posDeleteAppointment {
  isSuccess: boolean | null;
}

export interface PosDeleteAppointment {
  posDeleteAppointment: PosDeleteAppointment_posDeleteAppointment | null;
}

export interface PosDeleteAppointmentVariables {
  posDeleteAppointmentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosDeleteAvailabilityMutation
// ====================================================

export interface PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The Store Name
   */
  name: string | null;
}

export interface PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities_optometrist {
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
}

export interface PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities {
  /**
   * The automatically assigned ID for this availability. Use a UUID or KUID.
   */
  id: string;
  /**
   * The distribution channel for this availability
   */
  distributionChannel: PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities_distributionChannel | null;
  /**
   * The ISO 8601 date and time the appointment starts in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * Ths optometrist this availability is for
   */
  optometrist: PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities_optometrist | null;
}

export interface PosDeleteAvailabilityMutation_posDeleteAvailability {
  /**
   * availability is returned in case of success
   */
  availabilities: (PosDeleteAvailabilityMutation_posDeleteAvailability_availabilities | null)[] | null;
  /**
   * Error in case of failure
   */
  errors: (string | null)[] | null;
}

export interface PosDeleteAvailabilityMutation {
  /**
   * Delete an availability of an Appointment
   */
  posDeleteAvailability: PosDeleteAvailabilityMutation_posDeleteAvailability | null;
}

export interface PosDeleteAvailabilityMutationVariables {
  ids: (string | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosDeleteCalendarNoteMutation
// ====================================================

export interface PosDeleteCalendarNoteMutation_deleteCalendarNote {
  id: string;
  date: string;
  note: string;
  updatedAt: string;
}

export interface PosDeleteCalendarNoteMutation {
  deleteCalendarNote: PosDeleteCalendarNoteMutation_deleteCalendarNote | null;
}

export interface PosDeleteCalendarNoteMutationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveItem
// ====================================================

export interface RemoveItem_removeItem {
  id: string;
}

export interface RemoveItem {
  removeItem: RemoveItem_removeItem | null;
}

export interface RemoveItemVariables {
  removeItemRequest: removeItemDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IncrementInventory
// ====================================================

export interface IncrementInventory_incrementInventory {
  id: string | null;
}

export interface IncrementInventory {
  incrementInventory: IncrementInventory_incrementInventory | null;
}

export interface IncrementInventoryVariables {
  incrementInventoryRequest: IncrementInventoryDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosLoadBulkCustomerOrderTracking
// ====================================================

export interface PosLoadBulkCustomerOrderTracking_newPosLoadBulkCustomerOrderTracking {
  status: string | null;
}

export interface PosLoadBulkCustomerOrderTracking {
  /**
   * This is the new API for customer order Loading which will respond back with String. It will asynchronously process the orders and
   * there will be no connection time out.
   */
  newPosLoadBulkCustomerOrderTracking: PosLoadBulkCustomerOrderTracking_newPosLoadBulkCustomerOrderTracking | null;
}

export interface PosLoadBulkCustomerOrderTrackingVariables {
  batchNumber: string;
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosLoadBulkReplenishments
// ====================================================

export interface PosLoadBulkReplenishments_posLoadBulkReplenishments {
  essilorStoreCode: string | null;
  batchNumber: string | null;
  date: string | null;
}

export interface PosLoadBulkReplenishments {
  /**
   * Load the Bulk Replenishment against inventory in CT
   */
  posLoadBulkReplenishments: PosLoadBulkReplenishments_posLoadBulkReplenishments | null;
}

export interface PosLoadBulkReplenishmentsVariables {
  batchNumber: string;
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrderDemoLens
// ====================================================

export interface OrderDemoLens_orderDemoLens {
  orderNumber: string | null;
}

export interface OrderDemoLens {
  /**
   * API to order a dummy lense
   */
  orderDemoLens: OrderDemoLens_orderDemoLens | null;
}

export interface OrderDemoLensVariables {
  addBundleToCartRequest: addBundleToCartDraft;
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrderLineItemStatesUpdate
// ====================================================

export interface OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems_state_state {
  key: string | null;
  id: string;
}

export interface OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems_state {
  state: OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems_state_state | null;
}

export interface OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems {
  state: (OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems_state | null)[] | null;
}

export interface OrderLineItemStatesUpdate_orderLineItemStatesUpdate {
  id: string;
  lineItems: OrderLineItemStatesUpdate_orderLineItemStatesUpdate_lineItems[];
}

export interface OrderLineItemStatesUpdate {
  orderLineItemStatesUpdate: OrderLineItemStatesUpdate_orderLineItemStatesUpdate | null;
}

export interface OrderLineItemStatesUpdateVariables {
  orderLineItemStateUpdateRequest: OrderLineItemStateUpdateRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PhysicalTraceComplete
// ====================================================

export interface PhysicalTraceComplete_physicalTraceCompleted {
  customerId: string | null;
}

export interface PhysicalTraceComplete {
  physicalTraceCompleted: PhysicalTraceComplete_physicalTraceCompleted | null;
}

export interface PhysicalTraceCompleteVariables {
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReceiveInventory
// ====================================================

export interface ReceiveInventory_receiveInventory {
  sku: string | null;
  id: string | null;
}

export interface ReceiveInventory {
  receiveInventory: (ReceiveInventory_receiveInventory | null)[] | null;
}

export interface ReceiveInventoryVariables {
  batchNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosPartialOrderRefund
// ====================================================

export interface PosPartialOrderRefund_partialOrderRefund_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface PosPartialOrderRefund_partialOrderRefund_shippingInfo {
  shippingMethodName: string;
}

export interface PosPartialOrderRefund_partialOrderRefund_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_name {
  en: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_productType {
  key: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_supplyChannel {
  key: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_distributionChannel_name {
  en: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_distributionChannel {
  key: string | null;
  name: PosPartialOrderRefund_partialOrderRefund_lineItems_distributionChannel_name | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_variant_images {
  url: string;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: PosPartialOrderRefund_partialOrderRefund_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_returnInfos {
  comment: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_discounts {
  totalDiscount: PosPartialOrderRefund_partialOrderRefund_lineItems_discounts_totalDiscount | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_state_state_name {
  en: string | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_state_state {
  id: string;
  key: string | null;
  name: PosPartialOrderRefund_partialOrderRefund_lineItems_state_state_name | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_state {
  quantity: number;
  state: PosPartialOrderRefund_partialOrderRefund_lineItems_state_state | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: PosPartialOrderRefund_partialOrderRefund_lineItems_name | null;
  productType: PosPartialOrderRefund_partialOrderRefund_lineItems_productType | null;
  supplyChannel: PosPartialOrderRefund_partialOrderRefund_lineItems_supplyChannel | null;
  distributionChannel: PosPartialOrderRefund_partialOrderRefund_lineItems_distributionChannel | null;
  variant: PosPartialOrderRefund_partialOrderRefund_lineItems_variant | null;
  returnInfos: (PosPartialOrderRefund_partialOrderRefund_lineItems_returnInfos | null)[] | null;
  totalPrice: PosPartialOrderRefund_partialOrderRefund_lineItems_totalPrice | null;
  discounts: PosPartialOrderRefund_partialOrderRefund_lineItems_discounts | null;
  state: (PosPartialOrderRefund_partialOrderRefund_lineItems_state | null)[] | null;
  customFields: PosPartialOrderRefund_partialOrderRefund_lineItems_customFields | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo {
  amountPlanned: PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo_transactions | null)[] | null;
}

export interface PosPartialOrderRefund_partialOrderRefund {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: PosPartialOrderRefund_partialOrderRefund_totalPrice;
  patientId: string | null;
  shippingInfo: PosPartialOrderRefund_partialOrderRefund_shippingInfo | null;
  shippingAddress: PosPartialOrderRefund_partialOrderRefund_shippingAddress | null;
  customFields: PosPartialOrderRefund_partialOrderRefund_customFields | null;
  lineItems: PosPartialOrderRefund_partialOrderRefund_lineItems[];
  fullPaymentInfo: (PosPartialOrderRefund_partialOrderRefund_fullPaymentInfo | null)[] | null;
}

export interface PosPartialOrderRefund {
  /**
   * Partial Order Refund - Either Bundle number or lineItem is required for return
   */
  partialOrderRefund: PosPartialOrderRefund_partialOrderRefund | null;
}

export interface PosPartialOrderRefundVariables {
  partialOrderRefundRequest: PartialOrderRefundRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosRefundPayment
// ====================================================

export interface PosRefundPayment_refundPayment_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface PosRefundPayment_refundPayment_shippingInfo {
  shippingMethodName: string;
}

export interface PosRefundPayment_refundPayment_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface PosRefundPayment_refundPayment_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_name {
  en: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_productType {
  key: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_supplyChannel {
  key: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_distributionChannel_name {
  en: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_distributionChannel {
  key: string | null;
  name: PosRefundPayment_refundPayment_lineItems_distributionChannel_name | null;
}

export interface PosRefundPayment_refundPayment_lineItems_variant_images {
  url: string;
}

export interface PosRefundPayment_refundPayment_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: PosRefundPayment_refundPayment_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_returnInfos {
  comment: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface PosRefundPayment_refundPayment_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface PosRefundPayment_refundPayment_lineItems_discounts {
  totalDiscount: PosRefundPayment_refundPayment_lineItems_discounts_totalDiscount | null;
}

export interface PosRefundPayment_refundPayment_lineItems_state_state_name {
  en: string | null;
}

export interface PosRefundPayment_refundPayment_lineItems_state_state {
  id: string;
  key: string | null;
  name: PosRefundPayment_refundPayment_lineItems_state_state_name | null;
}

export interface PosRefundPayment_refundPayment_lineItems_state {
  quantity: number;
  state: PosRefundPayment_refundPayment_lineItems_state_state | null;
}

export interface PosRefundPayment_refundPayment_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface PosRefundPayment_refundPayment_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: PosRefundPayment_refundPayment_lineItems_name | null;
  productType: PosRefundPayment_refundPayment_lineItems_productType | null;
  supplyChannel: PosRefundPayment_refundPayment_lineItems_supplyChannel | null;
  distributionChannel: PosRefundPayment_refundPayment_lineItems_distributionChannel | null;
  variant: PosRefundPayment_refundPayment_lineItems_variant | null;
  returnInfos: (PosRefundPayment_refundPayment_lineItems_returnInfos | null)[] | null;
  totalPrice: PosRefundPayment_refundPayment_lineItems_totalPrice | null;
  discounts: PosRefundPayment_refundPayment_lineItems_discounts | null;
  state: (PosRefundPayment_refundPayment_lineItems_state | null)[] | null;
  customFields: PosRefundPayment_refundPayment_lineItems_customFields | null;
}

export interface PosRefundPayment_refundPayment_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface PosRefundPayment_refundPayment_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface PosRefundPayment_refundPayment_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: PosRefundPayment_refundPayment_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface PosRefundPayment_refundPayment_fullPaymentInfo {
  amountPlanned: PosRefundPayment_refundPayment_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (PosRefundPayment_refundPayment_fullPaymentInfo_transactions | null)[] | null;
}

export interface PosRefundPayment_refundPayment {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: PosRefundPayment_refundPayment_totalPrice;
  patientId: string | null;
  shippingInfo: PosRefundPayment_refundPayment_shippingInfo | null;
  shippingAddress: PosRefundPayment_refundPayment_shippingAddress | null;
  customFields: PosRefundPayment_refundPayment_customFields | null;
  lineItems: PosRefundPayment_refundPayment_lineItems[];
  fullPaymentInfo: (PosRefundPayment_refundPayment_fullPaymentInfo | null)[] | null;
}

export interface PosRefundPayment {
  /**
   * submit Refund Payment
   */
  refundPayment: PosRefundPayment_refundPayment | null;
}

export interface PosRefundPaymentVariables {
  refundPaymentRequest: RefundPaymentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemovePromotion
// ====================================================

export interface RemovePromotion_removePromotion_totalPrice {
  centAmount: number;
}

export interface RemovePromotion_removePromotion_discountCodes_discountCode {
  code: string;
  id: string | null;
}

export interface RemovePromotion_removePromotion_discountCodes {
  discountCode: RemovePromotion_removePromotion_discountCodes_discountCode | null;
}

export interface RemovePromotion_removePromotion {
  id: string;
  version: number;
  totalPrice: RemovePromotion_removePromotion_totalPrice;
  discountCodes: (RemovePromotion_removePromotion_discountCodes | null)[] | null;
}

export interface RemovePromotion {
  removePromotion: RemovePromotion_removePromotion | null;
}

export interface RemovePromotionVariables {
  discountId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendInvoiceMutation
// ====================================================

export interface ResendInvoiceMutation_resendInvoice {
  isSuccess: boolean | null;
}

export interface ResendInvoiceMutation {
  resendInvoice: ResendInvoiceMutation_resendInvoice | null;
}

export interface ResendInvoiceMutationVariables {
  resendInvoiceRequest: ResendInvoiceRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCommunicationForOrder
// ====================================================

export interface SendCommunicationForOrder_posSendCommunicationForOrder {
  status: string | null;
}

export interface SendCommunicationForOrder {
  posSendCommunicationForOrder: SendCommunicationForOrder_posSendCommunicationForOrder | null;
}

export interface SendCommunicationForOrderVariables {
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendInventory
// ====================================================

export interface SendInventory_sendInventory_supplyChannel_name {
  en: string | null;
}

export interface SendInventory_sendInventory_supplyChannel {
  id: string;
  key: string | null;
  name: SendInventory_sendInventory_supplyChannel_name | null;
}

export interface SendInventory_sendInventory {
  sku: string | null;
  supplyChannel: SendInventory_sendInventory_supplyChannel | null;
  batchNumber: string | null;
  quantityOnStock: number | null;
  availableQuantity: number | null;
  restockableInDays: number | null;
  expectedDelivery: any | null;
}

export interface SendInventory {
  sendInventory: (SendInventory_sendInventory | null)[] | null;
}

export interface SendInventoryVariables {
  sendInventoryRequest: SendInventoryDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendSMSMutation
// ====================================================

export interface SendSMSMutation_sendSMS {
  isSuccess: boolean | null;
}

export interface SendSMSMutation {
  sendSMS: SendSMSMutation_sendSMS | null;
}

export interface SendSMSMutationVariables {
  sendSmsSendSmsRequest: sendSMSDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetContactMethod
// ====================================================

export interface SetContactMethod_setContactMethod_customFields {
  contactMethod: string | null;
}

export interface SetContactMethod_setContactMethod {
  customFields: SetContactMethod_setContactMethod_customFields | null;
}

export interface SetContactMethod {
  setContactMethod: SetContactMethod_setContactMethod | null;
}

export interface SetContactMethodVariables {
  contactMethod: ContactMethodType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetShippingMethod
// ====================================================

export interface SetShippingMethod_setShippingMethod {
  id: string;
}

export interface SetShippingMethod {
  setShippingMethod: SetShippingMethod_setShippingMethod | null;
}

export interface SetShippingMethodVariables {
  shippingMethodKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetContactMethodOnOrder
// ====================================================

export interface SetContactMethodOnOrder_setContactMethodOnOrder_customFields {
  contactMethod: string | null;
}

export interface SetContactMethodOnOrder_setContactMethodOnOrder {
  customFields: SetContactMethodOnOrder_setContactMethodOnOrder_customFields | null;
  orderNumber: string | null;
}

export interface SetContactMethodOnOrder {
  setContactMethodOnOrder: SetContactMethodOnOrder_setContactMethodOnOrder | null;
}

export interface SetContactMethodOnOrderVariables {
  contactMethod: ContactMethodType;
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetShippingAddress
// ====================================================

export interface SetShippingAddress_setShippingAddress {
  id: string;
}

export interface SetShippingAddress {
  setShippingAddress: SetShippingAddress_setShippingAddress | null;
}

export interface SetShippingAddressVariables {
  addressRequest: addressDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitPosOrder
// ====================================================

export interface SubmitPosOrder_submitPosOrder_shippingAddress {
  firstName: string | null;
}

export interface SubmitPosOrder_submitPosOrder {
  orderNumber: string | null;
  shippingAddress: SubmitPosOrder_submitPosOrder_shippingAddress | null;
}

export interface SubmitPosOrder {
  submitPosOrder: SubmitPosOrder_submitPosOrder | null;
}

export interface SubmitPosOrderVariables {
  paymentDetails: (PaymentInput | null)[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdateAppointmentMutation
// ====================================================

export interface PosUpdateAppointmentMutation_posUpdateAppointment_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
}

export interface PosUpdateAppointmentMutation_posUpdateAppointment_patient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PosUpdateAppointmentMutation_posUpdateAppointment_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosUpdateAppointmentMutation_posUpdateAppointment_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (PosUpdateAppointmentMutation_posUpdateAppointment_optometrist_appointmentTypes | null)[] | null;
}

export interface PosUpdateAppointmentMutation_posUpdateAppointment_appointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosUpdateAppointmentMutation_posUpdateAppointment {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The ISO 3166 alpha-2 country code for this appointment.
   */
  countryCode: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * The source of the appointment. Currently one of STORE or ONLINE.
   */
  source: AppointmentSource;
  /**
   * Generic notes field related to this appointment
   */
  notes: string | null;
  /**
   * The status of the appointment. Currently one of BOOKED (default), CONFIRMED, CANCELLED, COMPLETED, WAITING. Additional status are expected to be required.
   */
  status: AppointmentStatus;
  /**
   * Use the storeId property from the DynamoDB record to find the Store associated with this appointment.
   */
  distributionChannel: PosUpdateAppointmentMutation_posUpdateAppointment_distributionChannel | null;
  /**
   * Use the patientId property from the DynamoDB record to find the Patient associated with this appointment or null if there is none.
   * TODO: How do we link up patients?
   */
  patient: PosUpdateAppointmentMutation_posUpdateAppointment_patient | null;
  /**
   * Use the optometristId property from the DynamoDB record to find the Optometrist associated with this appointment.
   */
  optometrist: PosUpdateAppointmentMutation_posUpdateAppointment_optometrist | null;
  /**
   * Use the appointmentTypeId property from the DynamoDB record to find the AppointmentType associated with this appointment.
   */
  appointmentType: PosUpdateAppointmentMutation_posUpdateAppointment_appointmentType | null;
}

export interface PosUpdateAppointmentMutation {
  posUpdateAppointment: PosUpdateAppointmentMutation_posUpdateAppointment | null;
}

export interface PosUpdateAppointmentMutationVariables {
  posUpdateAppointmentAppointmentUpdateRequest?: AppointmentUpdateRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdateAppointmentTypeMutation
// ====================================================

export interface PosUpdateAppointmentTypeMutation_posUpdateAppointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * color in hex code
   */
  color: string | null;
}

export interface PosUpdateAppointmentTypeMutation {
  /**
   * Update POS Appointment Type
   */
  posUpdateAppointmentType: PosUpdateAppointmentTypeMutation_posUpdateAppointmentType | null;
}

export interface PosUpdateAppointmentTypeMutationVariables {
  posUpdateAppointmentTypeRquest?: AppointmentTypeUpdateRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdateAvailabilityMutation
// ====================================================

export interface PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The Store Name
   */
  name: string | null;
}

export interface PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities_optometrist {
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
}

export interface PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities {
  /**
   * The automatically assigned ID for this availability. Use a UUID or KUID.
   */
  id: string;
  /**
   * The distribution channel for this availability
   */
  distributionChannel: PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities_distributionChannel | null;
  /**
   * The ISO 8601 date and time the appointment starts in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no earlier than the startAt value.
   */
  peakStartAt: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no later than the endAt value.
   */
  peakEndAt: string | null;
  /**
   * Ths optometrist this availability is for
   */
  optometrist: PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities_optometrist | null;
}

export interface PosUpdateAvailabilityMutation_posUpdateAvailability {
  /**
   * availability is returned in case of success
   */
  availabilities: (PosUpdateAvailabilityMutation_posUpdateAvailability_availabilities | null)[] | null;
  /**
   * Error in case of failure
   */
  errors: (string | null)[] | null;
}

export interface PosUpdateAvailabilityMutation {
  /**
   * Update an availability
   */
  posUpdateAvailability: PosUpdateAvailabilityMutation_posUpdateAvailability | null;
}

export interface PosUpdateAvailabilityMutationVariables {
  availabilityRequests?: (AvailabilityUpdateRequest | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateBundleToReceiveAtStore
// ====================================================

export interface UpdateBundleToReceiveAtStore_updateBundleToReceiveAtStore {
  id: string;
}

export interface UpdateBundleToReceiveAtStore {
  updateBundleToReceiveAtStore: UpdateBundleToReceiveAtStore_updateBundleToReceiveAtStore | null;
}

export interface UpdateBundleToReceiveAtStoreVariables {
  orderId: string;
  bundleNumber: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdateCalendarNoteMutation
// ====================================================

export interface PosUpdateCalendarNoteMutation_updateCalendarNote {
  id: string;
  date: string;
  note: string;
  updatedAt: string;
}

export interface PosUpdateCalendarNoteMutation {
  updateCalendarNote: PosUpdateCalendarNoteMutation_updateCalendarNote | null;
}

export interface PosUpdateCalendarNoteMutationVariables {
  id: string;
  note: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddHealthFundRefundComment
// ====================================================

export interface AddHealthFundRefundComment_addHealthFundRefundComment {
  id: string;
  comment: string | null;
}

export interface AddHealthFundRefundComment {
  addHealthFundRefundComment: AddHealthFundRefundComment_addHealthFundRefundComment | null;
}

export interface AddHealthFundRefundCommentVariables {
  hfRefundCommentRequest: HealthFundRefundComment;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitHealthFundRefundUpdate
// ====================================================

export interface SubmitHealthFundRefundUpdate_submitHealthFundRefundUpdate {
  id: string;
  staffName: string | null;
  refundType: RefundType;
  customerName: string;
  healthFund: string;
  dateOfService: string;
  healthFundNumber: string;
  healthFundReference: string;
  distributionChannelKey: string;
  providersName: string | null;
  providerNumber: string;
  totalPrice: number | null;
  healthFundPayment: number | null;
  reason: string | null;
  status: HealthFundRefundStatus | null;
  createdAt: any;
  orderNumber: string;
  hfRefundAmount: number | null;
  comment: string | null;
}

export interface SubmitHealthFundRefundUpdate {
  submitHealthFundRefundUpdate: SubmitHealthFundRefundUpdate_submitHealthFundRefundUpdate | null;
}

export interface SubmitHealthFundRefundUpdateVariables {
  hfRefundUpdateRequest: HealthFundRefundUpdate;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateLineItemPrescription
// ====================================================

export interface UpdateLineItemPrescription_updateLineItemPrescription_lineItems_name {
  en: string | null;
}

export interface UpdateLineItemPrescription_updateLineItemPrescription_lineItems_customFields {
  isQuote: boolean | null;
}

export interface UpdateLineItemPrescription_updateLineItemPrescription_lineItems {
  id: string;
  name: UpdateLineItemPrescription_updateLineItemPrescription_lineItems_name | null;
  customFields: UpdateLineItemPrescription_updateLineItemPrescription_lineItems_customFields | null;
}

export interface UpdateLineItemPrescription_updateLineItemPrescription {
  customerId: string | null;
  patientId: string | null;
  lineItems: UpdateLineItemPrescription_updateLineItemPrescription_lineItems[];
}

export interface UpdateLineItemPrescription {
  updateLineItemPrescription: UpdateLineItemPrescription_updateLineItemPrescription | null;
}

export interface UpdateLineItemPrescriptionVariables {
  updateLineItemPrescriptionRequest?: updateLineItemPrescriptionDraft | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdateOptometristMutation
// ====================================================

export interface PosUpdateOptometristMutation_posUpdateOptometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
}

export interface PosUpdateOptometristMutation {
  posUpdateOptometrist: PosUpdateOptometristMutation_posUpdateOptometrist | null;
}

export interface PosUpdateOptometristMutationVariables {
  posUpdateOptometristOptometristRequest?: OptometristUpdateRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrderStateAsCollected
// ====================================================

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_shippingInfo {
  shippingMethodName: string;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_name {
  en: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_productType {
  key: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_supplyChannel {
  key: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_distributionChannel_name {
  en: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_distributionChannel {
  key: string | null;
  name: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_distributionChannel_name | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_variant_images {
  url: string;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_returnInfos {
  comment: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_discounts {
  totalDiscount: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_discounts_totalDiscount | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state_state_name {
  en: string | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state_state {
  id: string;
  key: string | null;
  name: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state_state_name | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state {
  quantity: number;
  state: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state_state | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_name | null;
  productType: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_productType | null;
  supplyChannel: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_supplyChannel | null;
  distributionChannel: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_distributionChannel | null;
  variant: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_variant | null;
  returnInfos: (UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_returnInfos | null)[] | null;
  totalPrice: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_totalPrice | null;
  discounts: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_discounts | null;
  state: (UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_state | null)[] | null;
  customFields: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems_customFields | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo {
  amountPlanned: UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo_transactions | null)[] | null;
}

export interface UpdateOrderStateAsCollected_updateOrderStateAsCollected {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: UpdateOrderStateAsCollected_updateOrderStateAsCollected_totalPrice;
  patientId: string | null;
  shippingInfo: UpdateOrderStateAsCollected_updateOrderStateAsCollected_shippingInfo | null;
  shippingAddress: UpdateOrderStateAsCollected_updateOrderStateAsCollected_shippingAddress | null;
  customFields: UpdateOrderStateAsCollected_updateOrderStateAsCollected_customFields | null;
  lineItems: UpdateOrderStateAsCollected_updateOrderStateAsCollected_lineItems[];
  fullPaymentInfo: (UpdateOrderStateAsCollected_updateOrderStateAsCollected_fullPaymentInfo | null)[] | null;
}

export interface UpdateOrderStateAsCollected {
  updateOrderStateAsCollected: UpdateOrderStateAsCollected_updateOrderStateAsCollected | null;
}

export interface UpdateOrderStateAsCollectedVariables {
  updateOrderStateAsCollectedId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PosUpdatePatient
// ====================================================

export interface PosUpdatePatient_posUpdatePatient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PosUpdatePatient {
  posUpdatePatient: PosUpdatePatient_posUpdatePatient | null;
}

export interface PosUpdatePatientVariables {
  updatePatientRequest?: PatientUpdateRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: WriteOffInventory
// ====================================================

export interface WriteOffInventory_writeOffInventory {
  sku: string | null;
}

export interface WriteOffInventory {
  writeOffInventory: WriteOffInventory_writeOffInventory | null;
}

export interface WriteOffInventoryVariables {
  writeOffInventoryRequest: WriteOffInventoryDraft;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveStoreAppointmentsQuery
// ====================================================

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
}

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_patient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_optometrist_appointmentTypes | null)[] | null;
}

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_appointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The source of the appointment. Currently one of STORE or ONLINE.
   */
  source: AppointmentSource;
  /**
   * The status of the appointment. Currently one of BOOKED (default), CONFIRMED, CANCELLED, COMPLETED, WAITING. Additional status are expected to be required.
   */
  status: AppointmentStatus;
  /**
   * Generic notes field related to this appointment
   */
  notes: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * Use the storeId property from the DynamoDB record to find the Store associated with this appointment.
   */
  distributionChannel: PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_distributionChannel | null;
  /**
   * Use the patientId property from the DynamoDB record to find the Patient associated with this appointment or null if there is none.
   * TODO: How do we link up patients?
   */
  patient: PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_patient | null;
  /**
   * Use the optometristId property from the DynamoDB record to find the Optometrist associated with this appointment.
   */
  optometrist: PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_optometrist | null;
  /**
   * Use the appointmentTypeId property from the DynamoDB record to find the AppointmentType associated with this appointment.
   */
  appointmentType: PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_appointmentType | null;
}

export interface PosRetrieveStoreAppointmentsQuery {
  /**
   * Retrieve the Appointments for a store between given time
   */
  posRetrieveStoreAppointments: (PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments | null)[] | null;
}

export interface PosRetrieveStoreAppointmentsQueryVariables {
  appointmentRetrieveRequest: AppointmentRetrieveRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppointmentTypesByCountQuery
// ====================================================

export interface AppointmentTypesByCountQuery_posRetrieveAppointmentTypesByCountry_distributionChannels {
  /**
   * The distribution channel key
   */
  key: string | null;
}

export interface AppointmentTypesByCountQuery_posRetrieveAppointmentTypesByCountry {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The ISO 3166 alpha-2 country code for this appointment type.
   */
  countryCode: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * The subheading for the appointment type used for online booking, showing price related info
   */
  publicSubHeading: string | null;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * The SMS message sent to confirm you booking.
   */
  bookingConfirmationSMS: string;
  /**
   * The SMS reminder message sent the day before your appointment.
   */
  dayBeforeReminderSMS: string;
  /**
   * The SMS reminder message sent the day of your appointment if you have not confirmed your appointment.
   */
  dayOfReminderSMS: string;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * A list of stores that can use this appointment type.  Use the storeIds property from the DynamoDB record to find the Store's using this appointment type.
   */
  distributionChannels: (AppointmentTypesByCountQuery_posRetrieveAppointmentTypesByCountry_distributionChannels | null)[] | null;
  /**
   * online position of the appointment
   */
  onlinePosition: number | null;
}

export interface AppointmentTypesByCountQuery {
  /**
   * Retrieve POS Appointment Types
   */
  posRetrieveAppointmentTypesByCountry: (AppointmentTypesByCountQuery_posRetrieveAppointmentTypesByCountry | null)[] | null;
}

export interface AppointmentTypesByCountQueryVariables {
  posRetrieveAppointmentTypesByCountryCountryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveStoreAvailabilitiesQuery
// ====================================================

export interface PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_distributionChannel {
  /**
   * The Store Name
   */
  name: string | null;
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The timezone the store operates in.
   */
  timezone: string | null;
  /**
   * peak hours for current distribution channel, e.g ["MONDAY, 9:00, 13:00"]
   */
  peakAppointmentTimes: (string | null)[] | null;
}

export interface PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_optometrist_appointmentTypes | null)[] | null;
}

export interface PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities {
  /**
   * The automatically assigned ID for this availability. Use a UUID or KUID.
   */
  id: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * The ISO 8601 date and time the appointment starts in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no earlier than the startAt value.
   */
  peakStartAt: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no later than the endAt value.
   */
  peakEndAt: string | null;
  /**
   * The distribution channel for this availability
   */
  distributionChannel: PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_distributionChannel | null;
  /**
   * Ths optometrist this availability is for
   */
  optometrist: PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities_optometrist | null;
}

export interface PosRetrieveStoreAvailabilitiesQuery {
  /**
   * Retrieve the Availabilities for a store between given time
   */
  posRetrieveStoreAvailabilities: (PosRetrieveStoreAvailabilitiesQuery_posRetrieveStoreAvailabilities | null)[] | null;
}

export interface PosRetrieveStoreAvailabilitiesQueryVariables {
  availabilityRetrieveRequest: AvailabilityRetrieveRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RetrieveBulkCustomerOrder
// ====================================================

export interface RetrieveBulkCustomerOrder_posRetrieveBulkCustomerOrders {
  distributionChannelKey: string | null;
  batchNumber: string | null;
  createdAt: string | null;
}

export interface RetrieveBulkCustomerOrder {
  /**
   * Retrieve Bulk customer orders received per store
   */
  posRetrieveBulkCustomerOrders: (RetrieveBulkCustomerOrder_posRetrieveBulkCustomerOrders | null)[] | null;
}

export interface RetrieveBulkCustomerOrderVariables {
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RetrieveBulkReplenishments
// ====================================================

export interface RetrieveBulkReplenishments_posRetrieveBulkReplenishments {
  essilorStoreCode: string | null;
  batchNumber: string | null;
  date: string | null;
}

export interface RetrieveBulkReplenishments {
  /**
   * Retrieve Bulk store Replenishments
   */
  posRetrieveBulkReplenishments: (RetrieveBulkReplenishments_posRetrieveBulkReplenishments | null)[] | null;
}

export interface RetrieveBulkReplenishmentsVariables {
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RetrieveCalendarNote
// ====================================================

export interface RetrieveCalendarNote_retrieveCalendarNote {
  id: string;
  date: string;
  note: string;
  updatedAt: string;
}

export interface RetrieveCalendarNote {
  retrieveCalendarNote: (RetrieveCalendarNote_retrieveCalendarNote | null)[] | null;
}

export interface RetrieveCalendarNoteVariables {
  date: string;
  distributionChannelKey: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Cart
// ====================================================

export interface Cart_cart_lineItems_name {
  en: string | null;
}

export interface Cart_cart_lineItems_productSlug {
  en: string | null;
}

export interface Cart_cart_lineItems_productType {
  key: string | null;
  id: string;
}

export interface Cart_cart_lineItems_variant_images {
  url: string;
  label: string | null;
}

export interface Cart_cart_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
  eoltTraceLib: boolean | null;
  bnTraceLib: boolean | null;
  images: Cart_cart_lineItems_variant_images[];
}

export interface Cart_cart_lineItems_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface Cart_cart_lineItems_price_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface Cart_cart_lineItems_price_discounted {
  value: Cart_cart_lineItems_price_discounted_value;
}

export interface Cart_cart_lineItems_price {
  value: Cart_cart_lineItems_price_value;
  discounted: Cart_cart_lineItems_price_discounted | null;
}

export interface Cart_cart_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface Cart_cart_lineItems_discounts_includedDiscounts_amount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface Cart_cart_lineItems_discounts_includedDiscounts {
  name: string | null;
  description: string | null;
  amount: Cart_cart_lineItems_discounts_includedDiscounts_amount | null;
}

export interface Cart_cart_lineItems_discounts {
  totalDiscount: Cart_cart_lineItems_discounts_totalDiscount | null;
  includedDiscounts: (Cart_cart_lineItems_discounts_includedDiscounts | null)[] | null;
}

export interface Cart_cart_lineItems_supplyChannel {
  key: string | null;
}

export interface Cart_cart_lineItems_distributionChannel_name {
  en: string | null;
}

export interface Cart_cart_lineItems_distributionChannel {
  id: string;
  name: Cart_cart_lineItems_distributionChannel_name | null;
}

export interface Cart_cart_lineItems_shippingDetails_targets {
  addressKey: string;
  quantity: number;
}

export interface Cart_cart_lineItems_shippingDetails {
  targets: Cart_cart_lineItems_shippingDetails_targets[];
  valid: boolean;
}

export interface Cart_cart_lineItems_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface Cart_cart_lineItems_customFields {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  bvd: string | null;
  bundleNumber: number | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  lensType: LensType | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  frameSku: string | null;
  sunOpt: boolean | null;
  genericAdd: string | null;
  isQuote: boolean | null;
  fitToFrame: boolean | null;
}

export interface Cart_cart_lineItems {
  id: string;
  productId: string;
  name: Cart_cart_lineItems_name | null;
  productSlug: Cart_cart_lineItems_productSlug | null;
  productType: Cart_cart_lineItems_productType | null;
  variant: Cart_cart_lineItems_variant | null;
  price: Cart_cart_lineItems_price;
  quantity: number;
  discounts: Cart_cart_lineItems_discounts | null;
  supplyChannel: Cart_cart_lineItems_supplyChannel | null;
  distributionChannel: Cart_cart_lineItems_distributionChannel | null;
  shippingDetails: Cart_cart_lineItems_shippingDetails | null;
  totalPrice: Cart_cart_lineItems_totalPrice | null;
  customFields: Cart_cart_lineItems_customFields | null;
}

export interface Cart_cart_customFields {
  specialInstructions: string | null;
  contactMethod: string | null;
}

export interface Cart_cart_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface Cart_cart_shippingAddress_country {
  code: string | null;
}

export interface Cart_cart_shippingAddress {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: Cart_cart_shippingAddress_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface Cart_cart_shippingInfo_shippingMethod {
  key: string | null;
}

export interface Cart_cart_shippingInfo {
  shippingMethodName: string;
  shippingMethod: Cart_cart_shippingInfo_shippingMethod | null;
}

export interface Cart_cart_paymentInfo_payments_amountPlanned {
  centAmount: number;
  fractionDigits: number | null;
  currencyCode: string;
  type: string | null;
}

export interface Cart_cart_paymentInfo_payments {
  key: string | null;
  amountPlanned: Cart_cart_paymentInfo_payments_amountPlanned | null;
  creditCardNumber: string | null;
  paymentMethod: string | null;
}

export interface Cart_cart_paymentInfo {
  payments: Cart_cart_paymentInfo_payments[];
}

export interface Cart_cart_discountCodes_discountCodeRef {
  typeId: string;
  id: string;
}

export interface Cart_cart_discountCodes_discountCode_name {
  en: string | null;
}

export interface Cart_cart_discountCodes_discountCode_description {
  en: string | null;
}

export interface Cart_cart_discountCodes_discountCode {
  code: string;
  validFrom: any | null;
  validUntil: any | null;
  name: Cart_cart_discountCodes_discountCode_name | null;
  description: Cart_cart_discountCodes_discountCode_description | null;
  id: string | null;
}

export interface Cart_cart_discountCodes {
  discountCodeRef: Cart_cart_discountCodes_discountCodeRef | null;
  state: DiscountCodeState | null;
  discountCode: Cart_cart_discountCodes_discountCode | null;
}

export interface Cart_cart {
  id: string;
  createdAt: any | null;
  isQuote: boolean | null;
  lineItems: Cart_cart_lineItems[];
  customFields: Cart_cart_customFields | null;
  totalPrice: Cart_cart_totalPrice;
  shippingAddress: Cart_cart_shippingAddress | null;
  shippingInfo: Cart_cart_shippingInfo | null;
  paymentInfo: Cart_cart_paymentInfo | null;
  discountCodes: (Cart_cart_discountCodes | null)[] | null;
}

export interface Cart {
  cart: Cart_cart | null;
}

export interface CartVariables {
  distributionChannelKey?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartContactMethod
// ====================================================

export interface CartContactMethod_cart_customFields {
  contactMethod: string | null;
}

export interface CartContactMethod_cart {
  customFields: CartContactMethod_cart_customFields | null;
}

export interface CartContactMethod {
  cart: CartContactMethod_cart | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartDiscountCodes
// ====================================================

export interface CartDiscountCodes_cart_discountCodes_discountCode {
  code: string;
  id: string | null;
}

export interface CartDiscountCodes_cart_discountCodes {
  discountCode: CartDiscountCodes_cart_discountCodes_discountCode | null;
}

export interface CartDiscountCodes_cart {
  discountCodes: (CartDiscountCodes_cart_discountCodes | null)[] | null;
}

export interface CartDiscountCodes {
  cart: CartDiscountCodes_cart | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CartShipping
// ====================================================

export interface CartShipping_cart_shippingAddress_country {
  code: string | null;
}

export interface CartShipping_cart_shippingAddress {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: CartShipping_cart_shippingAddress_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface CartShipping_cart {
  shippingAddress: CartShipping_cart_shippingAddress | null;
}

export interface CartShipping {
  cart: CartShipping_cart | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Addresses
// ====================================================

export interface Addresses_customer_addresses_country {
  code: string | null;
}

export interface Addresses_customer_addresses {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: Addresses_customer_addresses_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface Addresses_customer {
  addresses: Addresses_customer_addresses[];
}

export interface Addresses {
  customer: Addresses_customer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveDiscountCodes
// ====================================================

export interface PosRetrieveDiscountCodes_posRetrieveDiscountCodes {
  code: string;
  id: string | null;
}

export interface PosRetrieveDiscountCodes {
  /**
   * Retrieve list of Active Discounts
   */
  posRetrieveDiscountCodes: (PosRetrieveDiscountCodes_posRetrieveDiscountCodes | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DistributionChannels
// ====================================================

export interface DistributionChannels_distributionChannels_address {
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  country: string | null;
}

export interface DistributionChannels_distributionChannels {
  key: string | null;
  id: string | null;
  name: string | null;
  timezone: string | null;
  email: string | null;
  address: DistributionChannels_distributionChannels_address | null;
  latitude: string | null;
  longitude: string | null;
  storeMigrated30: boolean | null;
  storeMigratedPOS: boolean | null;
  peakAppointmentTimes: (string | null)[] | null;
}

export interface DistributionChannels {
  distributionChannels: (DistributionChannels_distributionChannels | null)[] | null;
}

export interface DistributionChannelsVariables {
  distributionChannelsCountry: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RetrieveHealthFundRefunds
// ====================================================

export interface RetrieveHealthFundRefunds_retrieveHealthFundRefunds {
  id: string;
  staffName: string | null;
  refundType: RefundType;
  customerName: string;
  healthFund: string;
  dateOfService: string;
  healthFundNumber: string;
  healthFundReference: string;
  distributionChannelKey: string;
  providerNumber: string;
  providersName: string | null;
  totalPrice: number | null;
  healthFundPayment: number | null;
  reason: string | null;
  status: HealthFundRefundStatus | null;
  createdAt: any;
  orderNumber: string;
  hfRefundAmount: number | null;
  comment: string | null;
  storeNotes: string | null;
}

export interface RetrieveHealthFundRefunds {
  retrieveHealthFundRefunds: (RetrieveHealthFundRefunds_retrieveHealthFundRefunds | null)[] | null;
}

export interface RetrieveHealthFundRefundsVariables {
  retrieveHfRefundRequest?: RetrieveHFRefundRequest | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InventoriesReceived
// ====================================================

export interface InventoriesReceived_inventoriesReceived_inventoryEntries {
  sku: string;
  description: string | null;
  quantity: number;
  transferType: string;
  comment: string | null;
}

export interface InventoriesReceived_inventoriesReceived {
  id: string;
  createdAt: string;
  batchNumber: string | null;
  destinationChannel: string;
  sourceChannel: string;
  transferDate: string | null;
  transferStatus: TransferStatus;
  inventoryEntries: (InventoriesReceived_inventoriesReceived_inventoryEntries | null)[] | null;
}

export interface InventoriesReceived {
  inventoriesReceived: (InventoriesReceived_inventoriesReceived | null)[] | null;
}

export interface InventoriesReceivedVariables {
  inventoryMovementsRequest: InventoryMovementsRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InventoriesSent
// ====================================================

export interface InventoriesSent_inventoriesSent_inventoryEntries {
  sku: string;
  description: string | null;
  quantity: number;
  transferType: string;
  comment: string | null;
}

export interface InventoriesSent_inventoriesSent {
  id: string;
  createdAt: string;
  sourceChannel: string;
  destinationChannel: string;
  transferDate: string | null;
  transferStatus: TransferStatus;
  inventoryEntries: (InventoriesSent_inventoriesSent_inventoryEntries | null)[] | null;
  batchNumber: string | null;
}

export interface InventoriesSent {
  inventoriesSent: (InventoriesSent_inventoriesSent | null)[] | null;
}

export interface InventoriesSentVariables {
  inventoryMovementsRequest: InventoryMovementsRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LensBuilderQuery
// ====================================================

export interface LensBuilderQuery_productLensBuilder_facets_terms {
  term: string | null;
}

export interface LensBuilderQuery_productLensBuilder_facets {
  name: string | null;
  terms: (LensBuilderQuery_productLensBuilder_facets_terms | null)[] | null;
}

export interface LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice {
  currencyCode: string | null;
  amount: number | null;
}

export interface LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted {
  value: LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted_value | null;
}

export interface LensBuilderQuery_productLensBuilder_products_variants_currentPrice {
  cashPrice: LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice | null;
  discounted: LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted | null;
}

export interface LensBuilderQuery_productLensBuilder_products_variants_stocks {
  availableQuantity: number | null;
  distributionChannelKey: string | null;
  key: string | null;
  available: boolean | null;
  name: string | null;
}

export interface LensBuilderQuery_productLensBuilder_products_variants {
  sku: string | null;
  name: string | null;
  lensSKUs: (string | null)[] | null;
  currentPrice: LensBuilderQuery_productLensBuilder_products_variants_currentPrice | null;
  stocks: (LensBuilderQuery_productLensBuilder_products_variants_stocks | null)[] | null;
}

export interface LensBuilderQuery_productLensBuilder_products {
  key: string | null;
  variants: (LensBuilderQuery_productLensBuilder_products_variants | null)[] | null;
}

export interface LensBuilderQuery_productLensBuilder {
  facets: (LensBuilderQuery_productLensBuilder_facets | null)[] | null;
  products: (LensBuilderQuery_productLensBuilder_products | null)[] | null;
}

export interface LensBuilderQuery {
  productLensBuilder: LensBuilderQuery_productLensBuilder | null;
}

export interface LensBuilderQueryVariables {
  searchCategorySlug?: string | null;
  facets?: (FacetRequest | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LineItemStateTransitions
// ====================================================

export interface LineItemStateTransitions_lineItemStateTransitions_transitions_name {
  en: string | null;
}

export interface LineItemStateTransitions_lineItemStateTransitions_transitions {
  id: string;
  key: string | null;
  name: LineItemStateTransitions_lineItemStateTransitions_transitions_name | null;
}

export interface LineItemStateTransitions_lineItemStateTransitions {
  transitions: LineItemStateTransitions_lineItemStateTransitions_transitions[] | null;
}

export interface LineItemStateTransitions {
  /**
   * Retrieve the lineItem State Transitions
   */
  lineItemStateTransitions: LineItemStateTransitions_lineItemStateTransitions | null;
}

export interface LineItemStateTransitionsVariables {
  stateId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListContactsPrescriptions
// ====================================================

export interface ListContactsPrescriptions_listContactsPrescriptions_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ListContactsPrescriptions_listContactsPrescriptions {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odSku: string | null;
  odSupplyChannelsKey: string | null;
  osSupplyChannelsKey: string | null;
  odPower: number | null;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  odGenericAdd: string | null;
  osSku: string | null;
  osPower: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  osGenericAdd: string | null;
  optometrist: ListContactsPrescriptions_listContactsPrescriptions_optometrist | null;
}

export interface ListContactsPrescriptions {
  /**
   * Retrieve the Contacts Prescriptions for a patient
   */
  listContactsPrescriptions: (ListContactsPrescriptions_listContactsPrescriptions | null)[] | null;
}

export interface ListContactsPrescriptionsVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ListGlassesPrescriptions
// ====================================================

export interface ListGlassesPrescriptions_listGlassesPrescriptions_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ListGlassesPrescriptions_listGlassesPrescriptions {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  optometrist: ListGlassesPrescriptions_listGlassesPrescriptions_optometrist | null;
  odSph: number;
  odIntAdd: number | null;
  odPd: number | null;
  odNearPd: number | null;
  odHPrismType: HPrismType | null;
  odHPrism: number | null;
  odVPrismType: VPrismType | null;
  odVPrism: number | null;
  osSph: number;
  osIntAdd: number | null;
  osPd: number | null;
  osNearPd: number | null;
  osHPrismType: HPrismType | null;
  osHPrism: number | null;
  osVPrismType: VPrismType | null;
  osVPrism: number | null;
}

export interface ListGlassesPrescriptions {
  /**
   * Retrieve the Glasses Prescriptions for a patient
   */
  listGlassesPrescriptions: (ListGlassesPrescriptions_listGlassesPrescriptions | null)[] | null;
}

export interface ListGlassesPrescriptionsVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MedicareValidation
// ====================================================

export interface MedicareValidation_validateMedicare_patient {
  code: number | null;
  message: string | null;
}

export interface MedicareValidation_validateMedicare_concession {
  code: number | null;
  message: string | null;
}

export interface MedicareValidation_validateMedicare {
  patientVerified: boolean | null;
  isUpdateRequired: boolean | null;
  concessionVerified: boolean | null;
  patient: MedicareValidation_validateMedicare_patient | null;
  concession: MedicareValidation_validateMedicare_concession | null;
}

export interface MedicareValidation {
  /**
   * Validate the medicare details of the patient
   * cardNumber - 10 digits total including checksum and issue number
   * reference - 1 digit
   * expiry - MM/YY
   */
  validateMedicare: MedicareValidation_validateMedicare | null;
}

export interface MedicareValidationVariables {
  medicareValidationInput: MedicareValidationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OptometristQuery
// ====================================================

export interface OptometristQuery_posRetrieveOptometrists_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
}

export interface OptometristQuery_posRetrieveOptometrists {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The ISO 3166 alpha-2 country code for this optometrist.
   */
  countryCode: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * true if the optometrist is active
   */
  active: boolean;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (OptometristQuery_posRetrieveOptometrists_appointmentTypes | null)[] | null;
}

export interface OptometristQuery {
  /**
   * Retrieve the optometrists for a single country
   */
  posRetrieveOptometrists: (OptometristQuery_posRetrieveOptometrists | null)[] | null;
}

export interface OptometristQueryVariables {
  countryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoreOrderByNumber
// ====================================================

export interface StoreOrderByNumber_storeOrderByNumber_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrderByNumber_storeOrderByNumber_shippingInfo {
  shippingMethodName: string;
}

export interface StoreOrderByNumber_storeOrderByNumber_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_name {
  en: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_productType {
  key: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_supplyChannel {
  key: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_distributionChannel_name {
  en: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_distributionChannel {
  key: string | null;
  name: StoreOrderByNumber_storeOrderByNumber_lineItems_distributionChannel_name | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_variant_images {
  url: string;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: StoreOrderByNumber_storeOrderByNumber_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_returnInfos {
  comment: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_discounts {
  totalDiscount: StoreOrderByNumber_storeOrderByNumber_lineItems_discounts_totalDiscount | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_state_state_name {
  en: string | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_state_state {
  id: string;
  key: string | null;
  name: StoreOrderByNumber_storeOrderByNumber_lineItems_state_state_name | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_state {
  quantity: number;
  state: StoreOrderByNumber_storeOrderByNumber_lineItems_state_state | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: StoreOrderByNumber_storeOrderByNumber_lineItems_name | null;
  productType: StoreOrderByNumber_storeOrderByNumber_lineItems_productType | null;
  supplyChannel: StoreOrderByNumber_storeOrderByNumber_lineItems_supplyChannel | null;
  distributionChannel: StoreOrderByNumber_storeOrderByNumber_lineItems_distributionChannel | null;
  variant: StoreOrderByNumber_storeOrderByNumber_lineItems_variant | null;
  returnInfos: (StoreOrderByNumber_storeOrderByNumber_lineItems_returnInfos | null)[] | null;
  totalPrice: StoreOrderByNumber_storeOrderByNumber_lineItems_totalPrice | null;
  discounts: StoreOrderByNumber_storeOrderByNumber_lineItems_discounts | null;
  state: (StoreOrderByNumber_storeOrderByNumber_lineItems_state | null)[] | null;
  customFields: StoreOrderByNumber_storeOrderByNumber_lineItems_customFields | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo {
  amountPlanned: StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo_transactions | null)[] | null;
}

export interface StoreOrderByNumber_storeOrderByNumber {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: StoreOrderByNumber_storeOrderByNumber_totalPrice;
  patientId: string | null;
  shippingInfo: StoreOrderByNumber_storeOrderByNumber_shippingInfo | null;
  shippingAddress: StoreOrderByNumber_storeOrderByNumber_shippingAddress | null;
  customFields: StoreOrderByNumber_storeOrderByNumber_customFields | null;
  lineItems: StoreOrderByNumber_storeOrderByNumber_lineItems[];
  fullPaymentInfo: (StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo | null)[] | null;
}

export interface StoreOrderByNumber {
  storeOrderByNumber: StoreOrderByNumber_storeOrderByNumber | null;
}

export interface StoreOrderByNumberVariables {
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrderContactMethod
// ====================================================

export interface OrderContactMethod_storeOrderByNumber_customFields {
  contactMethod: string | null;
}

export interface OrderContactMethod_storeOrderByNumber {
  customFields: OrderContactMethod_storeOrderByNumber_customFields | null;
}

export interface OrderContactMethod {
  storeOrderByNumber: OrderContactMethod_storeOrderByNumber | null;
}

export interface OrderContactMethodVariables {
  orderNumber: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Orders
// ====================================================

export interface Orders_orders_lineItems_name {
  en: string | null;
}

export interface Orders_orders_lineItems_productSlug {
  en: string | null;
}

export interface Orders_orders_lineItems_productType {
  key: string | null;
  id: string;
}

export interface Orders_orders_lineItems_variant_images {
  url: string;
  label: string | null;
}

export interface Orders_orders_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
  eoltTraceLib: boolean | null;
  bnTraceLib: boolean | null;
  images: Orders_orders_lineItems_variant_images[];
}

export interface Orders_orders_lineItems_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface Orders_orders_lineItems_price_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface Orders_orders_lineItems_price_discounted {
  value: Orders_orders_lineItems_price_discounted_value;
}

export interface Orders_orders_lineItems_price {
  value: Orders_orders_lineItems_price_value;
  discounted: Orders_orders_lineItems_price_discounted | null;
}

export interface Orders_orders_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface Orders_orders_lineItems_discounts_includedDiscounts_amount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface Orders_orders_lineItems_discounts_includedDiscounts {
  name: string | null;
  description: string | null;
  amount: Orders_orders_lineItems_discounts_includedDiscounts_amount | null;
}

export interface Orders_orders_lineItems_discounts {
  totalDiscount: Orders_orders_lineItems_discounts_totalDiscount | null;
  includedDiscounts: (Orders_orders_lineItems_discounts_includedDiscounts | null)[] | null;
}

export interface Orders_orders_lineItems_supplyChannel {
  key: string | null;
}

export interface Orders_orders_lineItems_distributionChannel_name {
  en: string | null;
}

export interface Orders_orders_lineItems_distributionChannel {
  id: string;
  name: Orders_orders_lineItems_distributionChannel_name | null;
}

export interface Orders_orders_lineItems_shippingDetails_targets {
  addressKey: string;
  quantity: number;
}

export interface Orders_orders_lineItems_shippingDetails {
  targets: Orders_orders_lineItems_shippingDetails_targets[];
  valid: boolean;
}

export interface Orders_orders_lineItems_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface Orders_orders_lineItems_customFields {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  bvd: string | null;
  bundleNumber: number | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  lensType: LensType | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  frameSku: string | null;
  sunOpt: boolean | null;
  genericAdd: string | null;
  isQuote: boolean | null;
  fitToFrame: boolean | null;
}

export interface Orders_orders_lineItems {
  id: string;
  productId: string;
  name: Orders_orders_lineItems_name | null;
  productSlug: Orders_orders_lineItems_productSlug | null;
  productType: Orders_orders_lineItems_productType | null;
  variant: Orders_orders_lineItems_variant | null;
  price: Orders_orders_lineItems_price;
  quantity: number;
  discounts: Orders_orders_lineItems_discounts | null;
  supplyChannel: Orders_orders_lineItems_supplyChannel | null;
  distributionChannel: Orders_orders_lineItems_distributionChannel | null;
  shippingDetails: Orders_orders_lineItems_shippingDetails | null;
  totalPrice: Orders_orders_lineItems_totalPrice | null;
  customFields: Orders_orders_lineItems_customFields | null;
}

export interface Orders_orders_customFields {
  specialInstructions: string | null;
}

export interface Orders_orders {
  lineItems: Orders_orders_lineItems[];
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  customFields: Orders_orders_customFields | null;
}

export interface Orders {
  orders: (Orders_orders | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PatientRetrieveAppointmentsQuery
// ====================================================

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The timezone the store operates in.
   */
  timezone: string | null;
  /**
   * The Store Name
   */
  name: string | null;
}

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_patient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_optometrist_appointmentTypes | null)[] | null;
}

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_appointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface PatientRetrieveAppointmentsQuery_patientRetrieveAppointments {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The source of the appointment. Currently one of STORE or ONLINE.
   */
  source: AppointmentSource;
  /**
   * The status of the appointment. Currently one of BOOKED (default), CONFIRMED, CANCELLED, COMPLETED, WAITING. Additional status are expected to be required.
   */
  status: AppointmentStatus;
  /**
   * Generic notes field related to this appointment
   */
  notes: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * Use the storeId property from the DynamoDB record to find the Store associated with this appointment.
   */
  distributionChannel: PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_distributionChannel | null;
  /**
   * Use the patientId property from the DynamoDB record to find the Patient associated with this appointment or null if there is none.
   * TODO: How do we link up patients?
   */
  patient: PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_patient | null;
  /**
   * Use the optometristId property from the DynamoDB record to find the Optometrist associated with this appointment.
   */
  optometrist: PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_optometrist | null;
  /**
   * Use the appointmentTypeId property from the DynamoDB record to find the AppointmentType associated with this appointment.
   */
  appointmentType: PatientRetrieveAppointmentsQuery_patientRetrieveAppointments_appointmentType | null;
}

export interface PatientRetrieveAppointmentsQuery {
  /**
   * Retrieve the Appointments for a patient
   */
  patientRetrieveAppointments: (PatientRetrieveAppointmentsQuery_patientRetrieveAppointments | null)[] | null;
}

export interface PatientRetrieveAppointmentsQueryVariables {
  patientId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentMethods
// ====================================================

export interface PaymentMethods_paymentMethods {
  groups: any | null;
  paymentMethods: any | null;
  storedPaymentMethods: any | null;
}

export interface PaymentMethods {
  paymentMethods: PaymentMethods_paymentMethods | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoreCarts
// ====================================================

export interface StoreCarts_storeCarts_carts_shippingAddress {
  firstName: string | null;
  lastName: string | null;
}

export interface StoreCarts_storeCarts_carts_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface StoreCarts_storeCarts_carts_customFields {
  specialInstructions: string | null;
}

export interface StoreCarts_storeCarts_carts_shippingInfo {
  shippingMethodName: string;
}

export interface StoreCarts_storeCarts_carts_lineItems_name {
  en: string | null;
}

export interface StoreCarts_storeCarts_carts_lineItems_variant_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface StoreCarts_storeCarts_carts_lineItems_variant_price {
  value: StoreCarts_storeCarts_carts_lineItems_variant_price_value;
}

export interface StoreCarts_storeCarts_carts_lineItems_variant {
  name: string | null;
  sku: string | null;
  price: StoreCarts_storeCarts_carts_lineItems_variant_price | null;
}

export interface StoreCarts_storeCarts_carts_lineItems_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface StoreCarts_storeCarts_carts_lineItems {
  id: string;
  name: StoreCarts_storeCarts_carts_lineItems_name | null;
  quantity: number;
  variant: StoreCarts_storeCarts_carts_lineItems_variant | null;
  totalPrice: StoreCarts_storeCarts_carts_lineItems_totalPrice | null;
}

export interface StoreCarts_storeCarts_carts {
  id: string;
  shippingAddress: StoreCarts_storeCarts_carts_shippingAddress | null;
  patientId: string | null;
  updatedAt: any | null;
  totalPrice: StoreCarts_storeCarts_carts_totalPrice;
  customFields: StoreCarts_storeCarts_carts_customFields | null;
  shippingInfo: StoreCarts_storeCarts_carts_shippingInfo | null;
  lineItems: StoreCarts_storeCarts_carts_lineItems[];
}

export interface StoreCarts_storeCarts {
  limit: number | null;
  carts: (StoreCarts_storeCarts_carts | null)[] | null;
}

export interface StoreCarts {
  storeCarts: StoreCarts_storeCarts | null;
}

export interface StoreCartsVariables {
  storeCartsRequest: StoreCartsRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveDataList
// ====================================================

export interface PosRetrieveDataList_posRetrieveDataList {
  id: string;
  type: PosListType;
  displayName: string;
  countryCode: string;
  active: boolean;
  createdAt: string;
}

export interface PosRetrieveDataList {
  /**
   * Retrieve list of {type} for {countryCode} country
   */
  posRetrieveDataList: (PosRetrieveDataList_posRetrieveDataList | null)[] | null;
}

export interface PosRetrieveDataListVariables {
  countryCode: string;
  type?: PosListType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveHealthFundList
// ====================================================

export interface PosRetrieveHealthFundList_posRetrieveHealthFundList {
  id: string;
  code: string;
  name: string;
  healthCareProviderKey: string;
  order: number | null;
}

export interface PosRetrieveHealthFundList {
  /**
   * Retrieve list of Health Funds for {countryCode} country
   */
  posRetrieveHealthFundList: (PosRetrieveHealthFundList_posRetrieveHealthFundList | null)[] | null;
}

export interface PosRetrieveHealthFundListVariables {
  countryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductDetails
// ====================================================

export interface ProductDetails_productDetails_variants_stockAvailability_supplyChannel {
  key: string | null;
}

export interface ProductDetails_productDetails_variants_stockAvailability {
  supplyChannel: ProductDetails_productDetails_variants_stockAvailability_supplyChannel | null;
}

export interface ProductDetails_productDetails_variants {
  stockAvailability: ProductDetails_productDetails_variants_stockAvailability | null;
}

export interface ProductDetails_productDetails {
  variants: (ProductDetails_productDetails_variants | null)[] | null;
}

export interface ProductDetails {
  productDetails: ProductDetails_productDetails | null;
}

export interface ProductDetailsVariables {
  key: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveReceivedBulkReplenishments
// ====================================================

export interface PosRetrieveReceivedBulkReplenishments_posRetrieveReceivedBulkReplenishments {
  date: string | null;
  batchNumber: string | null;
  essilorStoreCode: string | null;
}

export interface PosRetrieveReceivedBulkReplenishments {
  /**
   * Retrieve Received Bulk store/non-store Replenishments
   * Start Date (example: 2020-01-01).
   * End Date (example: 2020-01-01).
   */
  posRetrieveReceivedBulkReplenishments: (PosRetrieveReceivedBulkReplenishments_posRetrieveReceivedBulkReplenishments | null)[] | null;
}

export interface PosRetrieveReceivedBulkReplenishmentsVariables {
  endDate: string;
  startDate: string;
  distributionChannelKey?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PosRetrieveBulkCustomerOrders
// ====================================================

export interface PosRetrieveBulkCustomerOrders_posRetrieveBulkCustomerOrders {
  distributionChannelKey: string | null;
  batchNumber: string | null;
  createdAt: string | null;
  status: string | null;
  dateReceived: string | null;
}

export interface PosRetrieveBulkCustomerOrders {
  /**
   * Retrieve Bulk customer orders received per store
   */
  posRetrieveBulkCustomerOrders: (PosRetrieveBulkCustomerOrders_posRetrieveBulkCustomerOrders | null)[] | null;
}

export interface PosRetrieveBulkCustomerOrdersVariables {
  distributionChannelKey: string;
  status?: string | null;
  endDate?: string | null;
  startDate?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SkuFrameSearchQuery
// ====================================================

export interface SkuFrameSearchQuery_search_products_variants_images {
  url: string | null;
  label: string | null;
}

export interface SkuFrameSearchQuery_search_products_variants_stocks {
  key: string | null;
  name: string | null;
  availableQuantity: number | null;
}

export interface SkuFrameSearchQuery_search_products_variants_currentPrice_cashPrice {
  currencyCode: string | null;
  amount: number | null;
}

export interface SkuFrameSearchQuery_search_products_variants_currentPrice_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface SkuFrameSearchQuery_search_products_variants_currentPrice_discounted {
  value: SkuFrameSearchQuery_search_products_variants_currentPrice_discounted_value | null;
}

export interface SkuFrameSearchQuery_search_products_variants_currentPrice {
  cashPrice: SkuFrameSearchQuery_search_products_variants_currentPrice_cashPrice | null;
  discounted: SkuFrameSearchQuery_search_products_variants_currentPrice_discounted | null;
}

export interface SkuFrameSearchQuery_search_products_variants {
  sku: string | null;
  name: string | null;
  images: (SkuFrameSearchQuery_search_products_variants_images | null)[] | null;
  sphere: string | null;
  cyl: (string | null)[] | null;
  axis: (string | null)[] | null;
  add: (string | null)[] | null;
  power: (string | null)[] | null;
  subscriptionFrequency: (string | null)[] | null;
  modality: string | null;
  daysUsage: string | null;
  stocks: (SkuFrameSearchQuery_search_products_variants_stocks | null)[] | null;
  bnTrackLib: boolean | null;
  eoltTrackLib: boolean | null;
  preferredCLSupplyChannelKey: string | null;
  currentPrice: SkuFrameSearchQuery_search_products_variants_currentPrice | null;
}

export interface SkuFrameSearchQuery_search_products {
  name: string | null;
  key: string | null;
  variants: (SkuFrameSearchQuery_search_products_variants | null)[] | null;
}

export interface SkuFrameSearchQuery_search {
  products: (SkuFrameSearchQuery_search_products | null)[] | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_images {
  url: string | null;
  label: string | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_stocks {
  key: string | null;
  name: string | null;
  availableQuantity: number | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_cashPrice {
  currencyCode: string | null;
  amount: number | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_discounted {
  value: SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_discounted_value | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants_currentPrice {
  cashPrice: SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_cashPrice | null;
  discounted: SkuFrameSearchQuery_sunSearch_products_variants_currentPrice_discounted | null;
}

export interface SkuFrameSearchQuery_sunSearch_products_variants {
  sku: string | null;
  name: string | null;
  images: (SkuFrameSearchQuery_sunSearch_products_variants_images | null)[] | null;
  sphere: string | null;
  cyl: (string | null)[] | null;
  axis: (string | null)[] | null;
  add: (string | null)[] | null;
  power: (string | null)[] | null;
  subscriptionFrequency: (string | null)[] | null;
  modality: string | null;
  daysUsage: string | null;
  stocks: (SkuFrameSearchQuery_sunSearch_products_variants_stocks | null)[] | null;
  bnTrackLib: boolean | null;
  eoltTrackLib: boolean | null;
  preferredCLSupplyChannelKey: string | null;
  currentPrice: SkuFrameSearchQuery_sunSearch_products_variants_currentPrice | null;
}

export interface SkuFrameSearchQuery_sunSearch_products {
  name: string | null;
  key: string | null;
  variants: (SkuFrameSearchQuery_sunSearch_products_variants | null)[] | null;
}

export interface SkuFrameSearchQuery_sunSearch {
  products: (SkuFrameSearchQuery_sunSearch_products | null)[] | null;
}

export interface SkuFrameSearchQuery {
  search: SkuFrameSearchQuery_search | null;
  sunSearch: SkuFrameSearchQuery_sunSearch | null;
}

export interface SkuFrameSearchQueryVariables {
  searchCategorySlug?: string | null;
  queryString?: string | null;
  sunQueryString?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchPatientsElastic
// ====================================================

export interface SearchPatientsElastic_searchPatientsElastic_patientSearchElasticResponse {
  id: string | null;
  firstName: string | null;
  externalId: string | null;
  preferredName: string | null;
  healthfundName: string | null;
  gender: string | null;
  dateOfBirth: string | null;
  age: string | null;
  email: string | null;
  mobilePhone: string | null;
  landline: string | null;
  lastName: string | null;
  medicare: string | null;
  isMedicareValidated: boolean | null;
  medicareReference: string | null;
  medicareExpiry: string | null;
  notes: string | null;
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface SearchPatientsElastic_searchPatientsElastic {
  count: number | null;
  patientSearchElasticResponse: (SearchPatientsElastic_searchPatientsElastic_patientSearchElasticResponse | null)[] | null;
}

export interface SearchPatientsElastic {
  searchPatientsElastic: SearchPatientsElastic_searchPatientsElastic | null;
}

export interface SearchPatientsElasticVariables {
  query: string;
  countryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchProductsQuery
// ====================================================

export interface SearchProductsQuery_search_products_variants_images {
  url: string | null;
  label: string | null;
}

export interface SearchProductsQuery_search_products_variants_stocks {
  key: string | null;
  name: string | null;
  availableQuantity: number | null;
}

export interface SearchProductsQuery_search_products_variants_currentPrice_cashPrice {
  currencyCode: string | null;
  amount: number | null;
}

export interface SearchProductsQuery_search_products_variants_currentPrice_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface SearchProductsQuery_search_products_variants_currentPrice_discounted {
  value: SearchProductsQuery_search_products_variants_currentPrice_discounted_value | null;
}

export interface SearchProductsQuery_search_products_variants_currentPrice {
  cashPrice: SearchProductsQuery_search_products_variants_currentPrice_cashPrice | null;
  discounted: SearchProductsQuery_search_products_variants_currentPrice_discounted | null;
}

export interface SearchProductsQuery_search_products_variants {
  sku: string | null;
  name: string | null;
  images: (SearchProductsQuery_search_products_variants_images | null)[] | null;
  sphere: string | null;
  cyl: (string | null)[] | null;
  axis: (string | null)[] | null;
  add: (string | null)[] | null;
  power: (string | null)[] | null;
  subscriptionFrequency: (string | null)[] | null;
  modality: string | null;
  daysUsage: string | null;
  stocks: (SearchProductsQuery_search_products_variants_stocks | null)[] | null;
  bnTrackLib: boolean | null;
  eoltTrackLib: boolean | null;
  preferredCLSupplyChannelKey: string | null;
  currentPrice: SearchProductsQuery_search_products_variants_currentPrice | null;
}

export interface SearchProductsQuery_search_products {
  name: string | null;
  key: string | null;
  variants: (SearchProductsQuery_search_products_variants | null)[] | null;
}

export interface SearchProductsQuery_search {
  products: (SearchProductsQuery_search_products | null)[] | null;
}

export interface SearchProductsQuery {
  search: SearchProductsQuery_search | null;
}

export interface SearchProductsQueryVariables {
  searchCategorySlug?: string | null;
  queryString?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectPatient
// ====================================================

export interface SelectPatient_posSelectPatient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface SelectPatient {
  /**
   * Select the patient after searching in POS
   */
  posSelectPatient: SelectPatient_posSelectPatient | null;
}

export interface SelectPatientVariables {
  posSelectPatientId: string;
  store: string;
  countryCode: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShippingMethodsForCart
// ====================================================

export interface ShippingMethodsForCart_shippingMethodsForCart_shippingMethods {
  name: string;
  description: string | null;
  isDefault: boolean;
  key: string | null;
  id: string;
}

export interface ShippingMethodsForCart_shippingMethodsForCart {
  shippingMethods: (ShippingMethodsForCart_shippingMethodsForCart_shippingMethods | null)[] | null;
}

export interface ShippingMethodsForCart {
  shippingMethodsForCart: ShippingMethodsForCart_shippingMethodsForCart | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StoreOrders
// ====================================================

export interface StoreOrders_storeOrders_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrders_storeOrders_shippingInfo {
  shippingMethodName: string;
}

export interface StoreOrders_storeOrders_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface StoreOrders_storeOrders_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface StoreOrders_storeOrders_lineItems_name {
  en: string | null;
}

export interface StoreOrders_storeOrders_lineItems_productType {
  key: string | null;
}

export interface StoreOrders_storeOrders_lineItems_supplyChannel {
  key: string | null;
}

export interface StoreOrders_storeOrders_lineItems_distributionChannel_name {
  en: string | null;
}

export interface StoreOrders_storeOrders_lineItems_distributionChannel {
  key: string | null;
  name: StoreOrders_storeOrders_lineItems_distributionChannel_name | null;
}

export interface StoreOrders_storeOrders_lineItems_variant_images {
  url: string;
}

export interface StoreOrders_storeOrders_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: StoreOrders_storeOrders_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface StoreOrders_storeOrders_lineItems_returnInfos {
  comment: string | null;
}

export interface StoreOrders_storeOrders_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface StoreOrders_storeOrders_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrders_storeOrders_lineItems_discounts {
  totalDiscount: StoreOrders_storeOrders_lineItems_discounts_totalDiscount | null;
}

export interface StoreOrders_storeOrders_lineItems_state_state_name {
  en: string | null;
}

export interface StoreOrders_storeOrders_lineItems_state_state {
  id: string;
  key: string | null;
  name: StoreOrders_storeOrders_lineItems_state_state_name | null;
}

export interface StoreOrders_storeOrders_lineItems_state {
  quantity: number;
  state: StoreOrders_storeOrders_lineItems_state_state | null;
}

export interface StoreOrders_storeOrders_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface StoreOrders_storeOrders_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: StoreOrders_storeOrders_lineItems_name | null;
  productType: StoreOrders_storeOrders_lineItems_productType | null;
  supplyChannel: StoreOrders_storeOrders_lineItems_supplyChannel | null;
  distributionChannel: StoreOrders_storeOrders_lineItems_distributionChannel | null;
  variant: StoreOrders_storeOrders_lineItems_variant | null;
  returnInfos: (StoreOrders_storeOrders_lineItems_returnInfos | null)[] | null;
  totalPrice: StoreOrders_storeOrders_lineItems_totalPrice | null;
  discounts: StoreOrders_storeOrders_lineItems_discounts | null;
  state: (StoreOrders_storeOrders_lineItems_state | null)[] | null;
  customFields: StoreOrders_storeOrders_lineItems_customFields | null;
}

export interface StoreOrders_storeOrders_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface StoreOrders_storeOrders_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface StoreOrders_storeOrders_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: StoreOrders_storeOrders_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface StoreOrders_storeOrders_fullPaymentInfo {
  amountPlanned: StoreOrders_storeOrders_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (StoreOrders_storeOrders_fullPaymentInfo_transactions | null)[] | null;
}

export interface StoreOrders_storeOrders {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: StoreOrders_storeOrders_totalPrice;
  patientId: string | null;
  shippingInfo: StoreOrders_storeOrders_shippingInfo | null;
  shippingAddress: StoreOrders_storeOrders_shippingAddress | null;
  customFields: StoreOrders_storeOrders_customFields | null;
  lineItems: StoreOrders_storeOrders_lineItems[];
  fullPaymentInfo: (StoreOrders_storeOrders_fullPaymentInfo | null)[] | null;
}

export interface StoreOrders {
  storeOrders: (StoreOrders_storeOrders | null)[] | null;
}

export interface StoreOrdersVariables {
  storeOrdersRequest: StoreOrdersRequest;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ListenUpdatedAppointments
// ====================================================

export interface ListenUpdatedAppointments_listenUpdatedAppointments_distributionChannel {
  /**
   * The distribution channel key
   */
  key: string | null;
}

export interface ListenUpdatedAppointments_listenUpdatedAppointments_patient {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

export interface ListenUpdatedAppointments_listenUpdatedAppointments_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface ListenUpdatedAppointments_listenUpdatedAppointments_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (ListenUpdatedAppointments_listenUpdatedAppointments_optometrist_appointmentTypes | null)[] | null;
}

export interface ListenUpdatedAppointments_listenUpdatedAppointments_appointmentType {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface ListenUpdatedAppointments_listenUpdatedAppointments {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The source of the appointment. Currently one of STORE or ONLINE.
   */
  source: AppointmentSource;
  /**
   * The status of the appointment. Currently one of BOOKED (default), CONFIRMED, CANCELLED, COMPLETED, WAITING. Additional status are expected to be required.
   */
  status: AppointmentStatus;
  /**
   * Generic notes field related to this appointment
   */
  notes: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * Use the storeId property from the DynamoDB record to find the Store associated with this appointment.
   */
  distributionChannel: ListenUpdatedAppointments_listenUpdatedAppointments_distributionChannel | null;
  /**
   * Use the patientId property from the DynamoDB record to find the Patient associated with this appointment or null if there is none.
   * TODO: How do we link up patients?
   */
  patient: ListenUpdatedAppointments_listenUpdatedAppointments_patient | null;
  /**
   * Use the optometristId property from the DynamoDB record to find the Optometrist associated with this appointment.
   */
  optometrist: ListenUpdatedAppointments_listenUpdatedAppointments_optometrist | null;
  /**
   * Use the appointmentTypeId property from the DynamoDB record to find the AppointmentType associated with this appointment.
   */
  appointmentType: ListenUpdatedAppointments_listenUpdatedAppointments_appointmentType | null;
}

export interface ListenUpdatedAppointments {
  listenUpdatedAppointments: ListenUpdatedAppointments_listenUpdatedAppointments | null;
}

export interface ListenUpdatedAppointmentsVariables {
  storeId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL subscription operation: ListenUpdatedAvailabilities
// ====================================================

export interface ListenUpdatedAvailabilities_listenUpdatedAvailabilities_distributionChannel {
  /**
   * The Store Name
   */
  name: string | null;
  /**
   * The distribution channel key
   */
  key: string | null;
  /**
   * The timezone the store operates in.
   */
  timezone: string | null;
  /**
   * peak hours for current distribution channel, e.g ["MONDAY, 9:00, 13:00"]
   */
  peakAppointmentTimes: (string | null)[] | null;
}

export interface ListenUpdatedAvailabilities_listenUpdatedAvailabilities_optometrist_appointmentTypes {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

export interface ListenUpdatedAvailabilities_listenUpdatedAvailabilities_optometrist {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
  /**
   * Array of appointment types supported by the optometrist
   */
  appointmentTypes: (ListenUpdatedAvailabilities_listenUpdatedAvailabilities_optometrist_appointmentTypes | null)[] | null;
}

export interface ListenUpdatedAvailabilities_listenUpdatedAvailabilities {
  /**
   * The automatically assigned ID for this availability. Use a UUID or KUID.
   */
  id: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  endAt: string;
  /**
   * The ISO 8601 date and time the appointment starts in UTC (example: 2020-01-01T00:00:00.000Z)
   */
  startAt: string;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no earlier than the startAt value.
   */
  peakStartAt: string | null;
  /**
   * ISO 8601 date and time in UTC (example: 2020-01-01T00:00:00.000Z). Must be in a multiple of 5 minutes and no later than the endAt value.
   */
  peakEndAt: string | null;
  /**
   * The distribution channel for this availability
   */
  distributionChannel: ListenUpdatedAvailabilities_listenUpdatedAvailabilities_distributionChannel | null;
  /**
   * Ths optometrist this availability is for
   */
  optometrist: ListenUpdatedAvailabilities_listenUpdatedAvailabilities_optometrist | null;
}

export interface ListenUpdatedAvailabilities {
  listenUpdatedAvailabilities: ListenUpdatedAvailabilities_listenUpdatedAvailabilities | null;
}

export interface ListenUpdatedAvailabilitiesVariables {
  storeId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AppointmentTypeFields
// ====================================================

export interface AppointmentTypeFields {
  /**
   * The automatically assigned ID for this appointment type. Use a UUID or KUID.
   */
  id: string;
  /**
   * The internal name for this appointment type. This should be displayed in the POS.
   */
  name: string;
  /**
   * The public name for this appointment type. This should be used in the online booking.
   */
  publicName: string | null;
  /**
   * A description of this appointment type to be displayed in the online booking.
   */
  publicDescription: string | null;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a peak time for the store. Should be in multiples of 5 minutes.
   */
  durationPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_A and the appointment starts during a off peak time.
   */
  durationOffPeakA: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a peak time.
   */
  durationPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_B and the appointment starts during a off peak time.
   */
  durationOffPeakB: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a peak time.
   */
  durationPeakC: number;
  /**
   * The duration for this appointment in minutes if the optometrist appointmentSet is DURATION_C and the appointment starts during a off peak time.
   */
  durationOffPeakC: number;
  /**
   * true if this appointment type can be booked online, otherwise false.
   */
  onlineBooking: boolean;
  /**
   * true if this appointment type requires patient, otherwise false.
   */
  requiresPatient: boolean;
  /**
   * color in hex code
   */
  color: string | null;
  /**
   * true if the appointment type is active and can be used for new booking, otherwise false.
   */
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PatientFields
// ====================================================

export interface PatientFields {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  preferredName: string | null;
  dateOfBirth: string | null;
  externalId: string | null;
  mobilePhone: string | null;
  landline: string | null;
  workNumber: string | null;
  email: string | null;
  access_token: string | null;
  refresh_token: string | null;
  expiresAt: any | null;
  /**
   * Medicare Card Number
   */
  medicare: string | null;
  /**
   * Medicare Reference Number
   */
  medicareReference: string | null;
  /**
   * Medicare Expiry in the format MM/yy
   */
  medicareExpiry: string | null;
  /**
   * Medicare Validated
   */
  isMedicareValidated: boolean | null;
  /**
   * Patients notes
   */
  notes: string | null;
  countryCode: string | null;
  /**
   * Patients Gender. Possible Values - F or M
   */
  gender: string | null;
  /**
   * Patients Private Health Fund Code
   */
  privateHealthFundCode: string | null;
  /**
   * Patients Private health Fund Member Number
   */
  privateHealthFundMemberNumber: string | null;
  /**
   * Patients Private health Fund Reference
   */
  privateHealthFundMemberReference: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CoreOptometristFields
// ====================================================

export interface CoreOptometristFields {
  /**
   * The automatically assigned ID for this appointment. Use a UUID or KUID.
   */
  id: string;
  /**
   * The first name of the optometrist
   */
  firstName: string;
  /**
   * The last name of the optometrist
   */
  lastName: string;
  /**
   * The email address of the optometrist
   */
  email: string | null;
  /**
   * One of DURATION_A, DURATION_B or DURATION_C. Default to DURATION_A.
   */
  appointmentSet: AppointmentSet;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AddressFields
// ====================================================

export interface AddressFields_country {
  code: string | null;
}

export interface AddressFields {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: AddressFields_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LineItemFields
// ====================================================

export interface LineItemFields_name {
  en: string | null;
}

export interface LineItemFields_productSlug {
  en: string | null;
}

export interface LineItemFields_productType {
  key: string | null;
  id: string;
}

export interface LineItemFields_variant_images {
  url: string;
  label: string | null;
}

export interface LineItemFields_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
  eoltTraceLib: boolean | null;
  bnTraceLib: boolean | null;
  images: LineItemFields_variant_images[];
}

export interface LineItemFields_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface LineItemFields_price_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface LineItemFields_price_discounted {
  value: LineItemFields_price_discounted_value;
}

export interface LineItemFields_price {
  value: LineItemFields_price_value;
  discounted: LineItemFields_price_discounted | null;
}

export interface LineItemFields_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface LineItemFields_discounts_includedDiscounts_amount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface LineItemFields_discounts_includedDiscounts {
  name: string | null;
  description: string | null;
  amount: LineItemFields_discounts_includedDiscounts_amount | null;
}

export interface LineItemFields_discounts {
  totalDiscount: LineItemFields_discounts_totalDiscount | null;
  includedDiscounts: (LineItemFields_discounts_includedDiscounts | null)[] | null;
}

export interface LineItemFields_supplyChannel {
  key: string | null;
}

export interface LineItemFields_distributionChannel_name {
  en: string | null;
}

export interface LineItemFields_distributionChannel {
  id: string;
  name: LineItemFields_distributionChannel_name | null;
}

export interface LineItemFields_shippingDetails_targets {
  addressKey: string;
  quantity: number;
}

export interface LineItemFields_shippingDetails {
  targets: LineItemFields_shippingDetails_targets[];
  valid: boolean;
}

export interface LineItemFields_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface LineItemFields_customFields {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  bvd: string | null;
  bundleNumber: number | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  lensType: LensType | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  frameSku: string | null;
  sunOpt: boolean | null;
  genericAdd: string | null;
  isQuote: boolean | null;
  fitToFrame: boolean | null;
}

export interface LineItemFields {
  id: string;
  productId: string;
  name: LineItemFields_name | null;
  productSlug: LineItemFields_productSlug | null;
  productType: LineItemFields_productType | null;
  variant: LineItemFields_variant | null;
  price: LineItemFields_price;
  quantity: number;
  discounts: LineItemFields_discounts | null;
  supplyChannel: LineItemFields_supplyChannel | null;
  distributionChannel: LineItemFields_distributionChannel | null;
  shippingDetails: LineItemFields_shippingDetails | null;
  totalPrice: LineItemFields_totalPrice | null;
  customFields: LineItemFields_customFields | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CartFields
// ====================================================

export interface CartFields_lineItems_name {
  en: string | null;
}

export interface CartFields_lineItems_productSlug {
  en: string | null;
}

export interface CartFields_lineItems_productType {
  key: string | null;
  id: string;
}

export interface CartFields_lineItems_variant_images {
  url: string;
  label: string | null;
}

export interface CartFields_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
  eoltTraceLib: boolean | null;
  bnTraceLib: boolean | null;
  images: CartFields_lineItems_variant_images[];
}

export interface CartFields_lineItems_price_value {
  currencyCode: string;
  centAmount: number;
}

export interface CartFields_lineItems_price_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface CartFields_lineItems_price_discounted {
  value: CartFields_lineItems_price_discounted_value;
}

export interface CartFields_lineItems_price {
  value: CartFields_lineItems_price_value;
  discounted: CartFields_lineItems_price_discounted | null;
}

export interface CartFields_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface CartFields_lineItems_discounts_includedDiscounts_amount {
  type: string | null;
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface CartFields_lineItems_discounts_includedDiscounts {
  name: string | null;
  description: string | null;
  amount: CartFields_lineItems_discounts_includedDiscounts_amount | null;
}

export interface CartFields_lineItems_discounts {
  totalDiscount: CartFields_lineItems_discounts_totalDiscount | null;
  includedDiscounts: (CartFields_lineItems_discounts_includedDiscounts | null)[] | null;
}

export interface CartFields_lineItems_supplyChannel {
  key: string | null;
}

export interface CartFields_lineItems_distributionChannel_name {
  en: string | null;
}

export interface CartFields_lineItems_distributionChannel {
  id: string;
  name: CartFields_lineItems_distributionChannel_name | null;
}

export interface CartFields_lineItems_shippingDetails_targets {
  addressKey: string;
  quantity: number;
}

export interface CartFields_lineItems_shippingDetails {
  targets: CartFields_lineItems_shippingDetails_targets[];
  valid: boolean;
}

export interface CartFields_lineItems_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface CartFields_lineItems_customFields {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  bvd: string | null;
  bundleNumber: number | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  lensType: LensType | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  frameSku: string | null;
  sunOpt: boolean | null;
  genericAdd: string | null;
  isQuote: boolean | null;
  fitToFrame: boolean | null;
}

export interface CartFields_lineItems {
  id: string;
  productId: string;
  name: CartFields_lineItems_name | null;
  productSlug: CartFields_lineItems_productSlug | null;
  productType: CartFields_lineItems_productType | null;
  variant: CartFields_lineItems_variant | null;
  price: CartFields_lineItems_price;
  quantity: number;
  discounts: CartFields_lineItems_discounts | null;
  supplyChannel: CartFields_lineItems_supplyChannel | null;
  distributionChannel: CartFields_lineItems_distributionChannel | null;
  shippingDetails: CartFields_lineItems_shippingDetails | null;
  totalPrice: CartFields_lineItems_totalPrice | null;
  customFields: CartFields_lineItems_customFields | null;
}

export interface CartFields_customFields {
  specialInstructions: string | null;
  contactMethod: string | null;
}

export interface CartFields_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface CartFields_shippingAddress_country {
  code: string | null;
}

export interface CartFields_shippingAddress {
  additionalAddressInfo: string | null;
  apartment: string | null;
  building: string | null;
  city: string | null;
  company: string | null;
  country: CartFields_shippingAddress_country;
  department: string | null;
  email: string | null;
  externalId: string | null;
  firstName: string | null;
  id: string | null;
  key: string | null;
  lastName: string | null;
  mobile: string | null;
  pOBox: string | null;
  phone: string | null;
  postalCode: string | null;
  region: string | null;
  salutation: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  title: string | null;
}

export interface CartFields_shippingInfo_shippingMethod {
  key: string | null;
}

export interface CartFields_shippingInfo {
  shippingMethodName: string;
  shippingMethod: CartFields_shippingInfo_shippingMethod | null;
}

export interface CartFields_paymentInfo_payments_amountPlanned {
  centAmount: number;
  fractionDigits: number | null;
  currencyCode: string;
  type: string | null;
}

export interface CartFields_paymentInfo_payments {
  key: string | null;
  amountPlanned: CartFields_paymentInfo_payments_amountPlanned | null;
  creditCardNumber: string | null;
  paymentMethod: string | null;
}

export interface CartFields_paymentInfo {
  payments: CartFields_paymentInfo_payments[];
}

export interface CartFields_discountCodes_discountCodeRef {
  typeId: string;
  id: string;
}

export interface CartFields_discountCodes_discountCode_name {
  en: string | null;
}

export interface CartFields_discountCodes_discountCode_description {
  en: string | null;
}

export interface CartFields_discountCodes_discountCode {
  code: string;
  validFrom: any | null;
  validUntil: any | null;
  name: CartFields_discountCodes_discountCode_name | null;
  description: CartFields_discountCodes_discountCode_description | null;
  id: string | null;
}

export interface CartFields_discountCodes {
  discountCodeRef: CartFields_discountCodes_discountCodeRef | null;
  state: DiscountCodeState | null;
  discountCode: CartFields_discountCodes_discountCode | null;
}

export interface CartFields {
  id: string;
  createdAt: any | null;
  isQuote: boolean | null;
  lineItems: CartFields_lineItems[];
  customFields: CartFields_customFields | null;
  totalPrice: CartFields_totalPrice;
  shippingAddress: CartFields_shippingAddress | null;
  shippingInfo: CartFields_shippingInfo | null;
  paymentInfo: CartFields_paymentInfo | null;
  discountCodes: (CartFields_discountCodes | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: OrderField
// ====================================================

export interface OrderField_totalPrice {
  currencyCode: string;
  centAmount: number;
}

export interface OrderField_shippingInfo {
  shippingMethodName: string;
}

export interface OrderField_shippingAddress {
  firstName: string | null;
  lastName: string | null;
  streetName: string | null;
  streetNumber: string | null;
  postalCode: string | null;
  city: string | null;
  region: string | null;
  state: string | null;
  mobile: string | null;
  email: string | null;
}

export interface OrderField_customFields {
  specialInstructions: string | null;
  customerNotified: boolean | null;
  contactMethod: string | null;
}

export interface OrderField_lineItems_name {
  en: string | null;
}

export interface OrderField_lineItems_productType {
  key: string | null;
}

export interface OrderField_lineItems_supplyChannel {
  key: string | null;
}

export interface OrderField_lineItems_distributionChannel_name {
  en: string | null;
}

export interface OrderField_lineItems_distributionChannel {
  key: string | null;
  name: OrderField_lineItems_distributionChannel_name | null;
}

export interface OrderField_lineItems_variant_images {
  url: string;
}

export interface OrderField_lineItems_variant {
  name: string | null;
  key: string | null;
  sku: string | null;
  images: OrderField_lineItems_variant_images[];
  singleHealthFundCode: string | null;
  multiHealthFundCode: string | null;
  singleHealthFundCodeAddOns: (string | null)[] | null;
  multiHealthFundCodeAddOns: (string | null)[] | null;
  customerGroupHealthFundCode: string | null;
}

export interface OrderField_lineItems_returnInfos {
  comment: string | null;
}

export interface OrderField_lineItems_totalPrice {
  centAmount: number;
  currencyCode: string;
}

export interface OrderField_lineItems_discounts_totalDiscount {
  currencyCode: string;
  centAmount: number;
}

export interface OrderField_lineItems_discounts {
  totalDiscount: OrderField_lineItems_discounts_totalDiscount | null;
}

export interface OrderField_lineItems_state_state_name {
  en: string | null;
}

export interface OrderField_lineItems_state_state {
  id: string;
  key: string | null;
  name: OrderField_lineItems_state_state_name | null;
}

export interface OrderField_lineItems_state {
  quantity: number;
  state: OrderField_lineItems_state_state | null;
}

export interface OrderField_lineItems_customFields {
  eye: EyeType | null;
  bundleNumber: number | null;
  sphere: string | null;
  lensType: LensType | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  height: string | null;
  nearpd: string | null;
  power: string | null;
  pd: string | null;
  bvd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  physicalTraceRequired: boolean | null;
  sunOpt: boolean | null;
  frameSku: string | null;
  collectedDate: string | null;
  isTrace: boolean | null;
}

export interface OrderField_lineItems {
  id: string;
  quantity: number;
  productId: string;
  name: OrderField_lineItems_name | null;
  productType: OrderField_lineItems_productType | null;
  supplyChannel: OrderField_lineItems_supplyChannel | null;
  distributionChannel: OrderField_lineItems_distributionChannel | null;
  variant: OrderField_lineItems_variant | null;
  returnInfos: (OrderField_lineItems_returnInfos | null)[] | null;
  totalPrice: OrderField_lineItems_totalPrice | null;
  discounts: OrderField_lineItems_discounts | null;
  state: (OrderField_lineItems_state | null)[] | null;
  customFields: OrderField_lineItems_customFields | null;
}

export interface OrderField_fullPaymentInfo_amountPlanned {
  currencyCode: string;
  centAmount: number;
  fractionDigits: number | null;
}

export interface OrderField_fullPaymentInfo_transactions_amount {
  currencyCode: string;
  centAmount: number;
}

export interface OrderField_fullPaymentInfo_transactions {
  type: TransactionType | null;
  amount: OrderField_fullPaymentInfo_transactions_amount;
  state: TransactionState;
  id: string;
}

export interface OrderField_fullPaymentInfo {
  amountPlanned: OrderField_fullPaymentInfo_amountPlanned | null;
  paymentGateway: string | null;
  paymentMethod: string | null;
  transactions: (OrderField_fullPaymentInfo_transactions | null)[] | null;
}

export interface OrderField {
  id: string;
  status: string | null;
  orderNumber: string | null;
  createdAt: any | null;
  totalPrice: OrderField_totalPrice;
  patientId: string | null;
  shippingInfo: OrderField_shippingInfo | null;
  shippingAddress: OrderField_shippingAddress | null;
  customFields: OrderField_customFields | null;
  lineItems: OrderField_lineItems[];
  fullPaymentInfo: (OrderField_fullPaymentInfo | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContactsPrescriptionFields
// ====================================================

export interface ContactsPrescriptionFields_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface ContactsPrescriptionFields {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odSku: string | null;
  odSupplyChannelsKey: string | null;
  osSupplyChannelsKey: string | null;
  odPower: number | null;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  odGenericAdd: string | null;
  osSku: string | null;
  osPower: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  osGenericAdd: string | null;
  optometrist: ContactsPrescriptionFields_optometrist | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GlassesPrescriptionFields
// ====================================================

export interface GlassesPrescriptionFields_optometrist {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
}

export interface GlassesPrescriptionFields {
  id: string;
  optometristName: string | null;
  practiceName: string | null;
  issueDate: string | null;
  expiresDate: string;
  odCyl: number | null;
  odAxis: number | null;
  odAdd: number | null;
  osCyl: number | null;
  osAxis: number | null;
  osAdd: number | null;
  optometrist: GlassesPrescriptionFields_optometrist | null;
  odSph: number;
  odIntAdd: number | null;
  odPd: number | null;
  odNearPd: number | null;
  odHPrismType: HPrismType | null;
  odHPrism: number | null;
  odVPrismType: VPrismType | null;
  odVPrism: number | null;
  osSph: number;
  osIntAdd: number | null;
  osPd: number | null;
  osNearPd: number | null;
  osHPrismType: HPrismType | null;
  osHPrism: number | null;
  osVPrismType: VPrismType | null;
  osVPrism: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SearchProductFields
// ====================================================

export interface SearchProductFields_variants_images {
  url: string | null;
  label: string | null;
}

export interface SearchProductFields_variants_stocks {
  key: string | null;
  name: string | null;
  availableQuantity: number | null;
}

export interface SearchProductFields_variants_currentPrice_cashPrice {
  currencyCode: string | null;
  amount: number | null;
}

export interface SearchProductFields_variants_currentPrice_discounted_value {
  currencyCode: string;
  centAmount: number;
}

export interface SearchProductFields_variants_currentPrice_discounted {
  value: SearchProductFields_variants_currentPrice_discounted_value | null;
}

export interface SearchProductFields_variants_currentPrice {
  cashPrice: SearchProductFields_variants_currentPrice_cashPrice | null;
  discounted: SearchProductFields_variants_currentPrice_discounted | null;
}

export interface SearchProductFields_variants {
  sku: string | null;
  name: string | null;
  images: (SearchProductFields_variants_images | null)[] | null;
  sphere: string | null;
  cyl: (string | null)[] | null;
  axis: (string | null)[] | null;
  add: (string | null)[] | null;
  power: (string | null)[] | null;
  subscriptionFrequency: (string | null)[] | null;
  modality: string | null;
  daysUsage: string | null;
  stocks: (SearchProductFields_variants_stocks | null)[] | null;
  bnTrackLib: boolean | null;
  eoltTrackLib: boolean | null;
  preferredCLSupplyChannelKey: string | null;
  currentPrice: SearchProductFields_variants_currentPrice | null;
}

export interface SearchProductFields {
  name: string | null;
  key: string | null;
  variants: (SearchProductFields_variants | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The status for the appointment
 */
export enum AppointmentSet {
  DURATION_A = "DURATION_A",
  DURATION_B = "DURATION_B",
  DURATION_C = "DURATION_C",
}

/**
 * The source for an appointment
 */
export enum AppointmentSource {
  ONLINE = "ONLINE",
  STORE = "STORE",
}

/**
 * The status for the appointment
 */
export enum AppointmentStatus {
  BOOKED = "BOOKED",
  CANCELLED_BY_CUSTOMER = "CANCELLED_BY_CUSTOMER",
  CANCELLED_BY_TEAM = "CANCELLED_BY_TEAM",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  CONSULTING = "CONSULTING",
  DELETED = "DELETED",
  DILATING = "DILATING",
  NO_SHOW = "NO_SHOW",
  PRE_TEST = "PRE_TEST",
  READY_FOR_OPTOM = "READY_FOR_OPTOM",
  WAITING = "WAITING",
}

export enum ConcessionVerified {
  cev = "cev",
}

export enum ContactMethodType {
  Call = "Call",
  Email = "Email",
  None = "None",
  SMS = "SMS",
}

export enum DiscountCodeState {
  ApplicationStoppedByPreviousDiscount = "ApplicationStoppedByPreviousDiscount",
  DoesNotMatchCart = "DoesNotMatchCart",
  MatchesCart = "MatchesCart",
  MaxApplicationReached = "MaxApplicationReached",
  NotActive = "NotActive",
  NotValid = "NotValid",
}

export enum EyeType {
  LEFT_OS = "LEFT_OS",
  RIGHT_OD = "RIGHT_OD",
}

export enum HPrismType {
  IN = "IN",
  OUT = "OUT",
}

export enum HealthFund {
  Bupa = "Bupa",
  Medicare = "Medicare",
}

export enum HealthFundRefundStatus {
  Completed = "Completed",
  Pending = "Pending",
  PendingFinanceTransfer = "PendingFinanceTransfer",
  PendingHealthFundConfirmation = "PendingHealthFundConfirmation",
}

export enum LensType {
  B = "B",
  D = "D",
  I = "I",
  M = "M",
  N = "N",
  P = "P",
}

export enum PaymentMethod {
  ACTConcessionScheme = "ACTConcessionScheme",
  Afterpay = "Afterpay",
  Amex = "Amex",
  BNGiftVoucher = "BNGiftVoucher",
  Cash = "Cash",
  DefenceHealth = "DefenceHealth",
  EFTPOS = "EFTPOS",
  HealthFund = "HealthFund",
  Laybuy = "Laybuy",
  Mastercard = "Mastercard",
  NTConcessionScheme = "NTConcessionScheme",
  RoyalPay = "RoyalPay",
  Visa = "Visa",
  ZipPay = "ZipPay",
}

export enum PosListType {
  INSTITUTION_PAYMENT_METHOD = "INSTITUTION_PAYMENT_METHOD",
  INVENTORY_CUSTOMER_RETURNS = "INVENTORY_CUSTOMER_RETURNS",
  INVENTORY_TRANSFER_TYPE = "INVENTORY_TRANSFER_TYPE",
  INVENTORY_WRITEOFF_REASON = "INVENTORY_WRITEOFF_REASON",
  REFUND_REASON = "REFUND_REASON",
}

export enum RefundType {
  ClaimAdjustment = "ClaimAdjustment",
  FullRefund = "FullRefund",
  PartialRefund = "PartialRefund",
}

export enum TransactionState {
  Failure = "Failure",
  Initial = "Initial",
  Pending = "Pending",
  Success = "Success",
}

export enum TransactionType {
  Authorization = "Authorization",
  CancelAuthorization = "CancelAuthorization",
  Charge = "Charge",
  Chargeback = "Chargeback",
  Refund = "Refund",
}

export enum TransferStatus {
  COMPLETED = "COMPLETED",
  INITIATED = "INITIATED",
}

export enum VPrismType {
  DOWN = "DOWN",
  UP = "UP",
}

export enum addressType {
  clickAndCollectAddress = "clickAndCollectAddress",
  deliveryAddress = "deliveryAddress",
}

export interface AppointmentRequest {
  patientId?: string | null;
  optometristId: string;
  appointmentTypeId: string;
  distributionChannelKey: string;
  notes?: string | null;
  startAt: string;
  endAt: string;
  status?: AppointmentStatus | null;
  gClickId?: string | null;
}

export interface AppointmentRetrieveRequest {
  distributionChannelKey: string;
  startDate: string;
  endDate: string;
}

export interface AppointmentTypeRequest {
  name: string;
  publicName: string;
  publicDescription: string;
  publicSubHeading?: string | null;
  active: boolean;
  onlineBooking: boolean;
  requiresPatient: boolean;
  bookingConfirmationSMS: string;
  dayBeforeReminderSMS: string;
  dayOfReminderSMS: string;
  distributionChannelKeys: (string | null)[];
  countryCode: string;
  externalId?: string | null;
  durationPeakA: number;
  durationOffPeakA: number;
  durationPeakB: number;
  durationOffPeakB: number;
  durationPeakC: number;
  durationOffPeakC: number;
  color?: string | null;
  onlinePosition?: number | null;
}

/**
 * Update POS Appointment Type
 */
export interface AppointmentTypeUpdateRequest {
  id: string;
  name: string;
  publicName: string;
  publicSubHeading?: string | null;
  publicDescription: string;
  active: boolean;
  onlineBooking: boolean;
  bookingConfirmationSMS: string;
  dayBeforeReminderSMS: string;
  dayOfReminderSMS: string;
  requiresPatient: boolean;
  distributionChannelKeys?: (string | null)[] | null;
  externalId?: string | null;
  durationPeakA: number;
  durationOffPeakA: number;
  durationPeakB: number;
  durationOffPeakB: number;
  durationPeakC: number;
  durationOffPeakC: number;
  color?: string | null;
  onlinePosition?: number | null;
}

export interface AppointmentUpdateRequest {
  id: string;
  optometristId: string;
  appointmentTypeId: string;
  notes?: string | null;
  startAt: string;
  endAt: string;
  status?: AppointmentStatus | null;
  patientId?: string | null;
}

export interface AvailabilityRequest {
  distributionChannelKey: string;
  optometristId: string;
  startAt: string;
  endAt: string;
  countryCode: string;
  peakStartAt?: string | null;
  peakEndAt?: string | null;
}

export interface AvailabilityRetrieveFilter {
  appointmentTypeId?: string | null;
  optometristId?: string | null;
}

export interface AvailabilityRetrieveRequest {
  distributionChannelKey: string;
  startDate: string;
  endDate: string;
  filters?: AvailabilityRetrieveFilter | null;
}

/**
 * Update an availability of an Appointment
 */
export interface AvailabilityUpdateRequest {
  id: string;
  startAt: string;
  endAt: string;
  distributionChannelKey?: string | null;
  peakStartAt?: string | null;
  peakEndAt?: string | null;
}

/**
 * Contacts Prescription Request
 */
export interface ContactsPrescriptionRequest {
  patientId: string;
  optometristId?: string | null;
  optometristName?: string | null;
  issueDate?: string | null;
  expiresDate: string;
  odSku?: string | null;
  odSupplyChannelsKey?: string | null;
  osSupplyChannelsKey?: string | null;
  odPower?: number | null;
  odCyl?: number | null;
  odAxis?: number | null;
  odAdd?: number | null;
  osSku?: string | null;
  osPower?: number | null;
  osCyl?: number | null;
  osAxis?: number | null;
  osAdd?: number | null;
  practiceName?: string | null;
  odGenericAdd?: string | null;
  osGenericAdd?: string | null;
}

export interface FacetRequest {
  facetName: string;
  facetValue: string;
}

/**
 * Glasses Prescription Request
 */
export interface GlassesPrescriptionRequest {
  patientId: string;
  optometristId?: string | null;
  optometristName?: string | null;
  issueDate?: string | null;
  expiresDate: string;
  odSph: number;
  odCyl?: number | null;
  odAxis?: number | null;
  odAdd?: number | null;
  odIntAdd?: number | null;
  odPd: number;
  odNearPd?: number | null;
  odHPrismType?: HPrismType | null;
  odHPrism?: number | null;
  odVPrismType?: VPrismType | null;
  odVPrism?: number | null;
  osSph: number;
  osCyl?: number | null;
  osAxis?: number | null;
  osAdd?: number | null;
  osIntAdd?: number | null;
  osPd: number;
  osNearPd?: number | null;
  osHPrismType?: HPrismType | null;
  osHPrism?: number | null;
  osVPrismType?: VPrismType | null;
  osVPrism?: number | null;
  practiceName?: string | null;
}

/**
 * Health fund Refund Request
 */
export interface HFRefundRequest {
  staffName?: string | null;
  refundType: RefundType;
  customerName: string;
  healthFund: string;
  healthFundNumber: string;
  dateOfService: string;
  healthFundReference: string;
  distributionChannelKey: string;
  providerNumber: string;
  providersName?: string | null;
  totalPrice?: number | null;
  healthFundPayment?: number | null;
  reason?: string | null;
  orderNumber: string;
  storeNotes?: string | null;
}

/**
 * Health Fund Refund Comment Update
 */
export interface HealthFundRefundComment {
  id: string;
  comment: string;
}

/**
 * Health Fund Refund Update
 */
export interface HealthFundRefundUpdate {
  id: string;
  status: HealthFundRefundStatus;
  hfRefundAmount?: number | null;
}

/**
 * Increment inventory from SKU in store
 */
export interface IncrementInventoryDraft {
  sku: string;
  distributionChannelKey: string;
}

export interface InventoryEntriesInput {
  sku: string;
  description?: string | null;
  quantity: number;
  transferType: string;
  comment?: string | null;
}

/**
 * Inventory Movements Request
 */
export interface InventoryMovementsRequest {
  channelKey?: string | null;
  fromDate: any;
  toDate: any;
  transferStatus?: TransferStatus | null;
}

/**
 * Update lineItem state
 */
export interface LineItemStateUpdates {
  lineItemId: string;
  quantity: number;
  fromStateKey: string;
  toStateKey: string;
}

/**
 * Update lineItem to return
 */
export interface LineItemToReturn {
  lineItemId: string;
  comment?: string | null;
  quantity: number;
  faultyReason?: string | null;
  faultyComment?: string | null;
}

export interface MedicareValidationInput {
  firstName: string;
  lastName: string;
  sex?: string | null;
  dobString: string;
  cardNumber: string;
  reference?: string | null;
  verificationType?: ConcessionVerified | null;
  isDva?: boolean | null;
}

export interface OptometristRequest {
  firstName: string;
  lastName: string;
  email?: string | null;
  active: boolean;
  appointmentTypeIds: string[];
  countryCode: string;
  appointmentSet: AppointmentSet;
}

export interface OptometristUpdateRequest {
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  active: boolean;
  appointmentTypeIds: string[];
  appointmentSet: AppointmentSet;
}

/**
 * Update lineItem state Request
 */
export interface OrderLineItemStateUpdateRequest {
  orderId: string;
  distributionChannelKey?: string | null;
  lineItems: (LineItemStateUpdates | null)[];
}

/**
 * order refund calculation Request
 */
export interface OrderRefundCalculationRequest {
  orderId: string;
  bundleNumber?: number | null;
  lineItems?: (LineItemToReturn | null)[] | null;
}

/**
 * Partial order return Request
 */
export interface PartialOrderRefundRequest {
  orderId: string;
  comment?: string | null;
  bundleNumber?: number | null;
  distributionChannelKey?: string | null;
  lineItems?: (LineItemToReturn | null)[] | null;
}

export interface PatientUpdateRequest {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobilePhone?: string | null;
  marketingEmailsOptin?: boolean | null;
  countryCode: string;
  dateOfBirth?: string | null;
  preferredName?: string | null;
  landline?: string | null;
  workNumber?: string | null;
  medicare?: string | null;
  medicareReference?: string | null;
  medicareExpiry?: string | null;
  isMedicareValidated?: boolean | null;
  notes?: string | null;
  privateHealthFundCode?: string | null;
  privateHealthFundMemberNumber?: string | null;
  privateHealthFundMemberReference?: string | null;
}

export interface PaymentInput {
  centAmount: number;
  paymentMethod: PaymentMethod;
  paymentMethodName?: string | null;
  healthFund?: HealthFund | null;
}

export interface RefundPaymentInput {
  orderId: string;
  distributionChannelKey?: string | null;
  paymentDetails: (PaymentInput | null)[];
}

export interface ResendInvoiceRequest {
  orderNumber: string;
  email?: string | null;
}

/**
 * Retrieve Health Fund Refund Request
 */
export interface RetrieveHFRefundRequest {
  fromDate: string;
  toDate: string;
}

/**
 * Send inventory from one channel to another
 */
export interface SendInventoryDraft {
  inventoryEntries: (InventoryEntriesInput | null)[];
  sourceChannel: string;
  destinationChannel: string;
}

/**
 * Get pending carts for store Request
 */
export interface StoreCartsRequest {
  distributionChannelKey: string;
  page?: number | null;
  date: string;
}

export interface StoreOrdersRequest {
  distributionChannelKey: string;
  fromDate: string;
  toDate: string;
}

/**
 * Write Off Store inventory from one channel to Write Off Channel
 */
export interface WriteOffInventoryDraft {
  sku: string;
  sourceChannel: string;
  description?: string | null;
  quantity: number;
  transferType: string;
  countryCode: string;
  comment?: string | null;
}

export interface addBundleToCartDraft {
  itemsToAdd: (addToCartDraft | null)[];
}

export interface addToCartDraft {
  sku: string;
  quantity: number;
  supplyChannelKey?: string | null;
  distributionChannelKey?: string | null;
  subscription?: boolean | null;
  frequency?: number | null;
  eye?: EyeType | null;
  sphere?: string | null;
  cyl?: string | null;
  axis?: string | null;
  add?: string | null;
  power?: string | null;
  nearpd?: string | null;
  height?: string | null;
  pd?: string | null;
  bvd?: string | null;
  pantoscopic_tilt?: string | null;
  frame_wrap?: string | null;
  intAdd?: string | null;
  hPrism?: string | null;
  vPrism?: string | null;
  hPrismType?: HPrismType | null;
  vPrismType?: VPrismType | null;
  lensType?: LensType | null;
  physicalTraceRequired?: boolean | null;
  frameSku?: string | null;
  sunOpt?: boolean | null;
  genericAdd?: string | null;
  fitToFrame?: boolean | null;
  lensSKUs?: (string | null)[] | null;
}

export interface addressDraft {
  id?: string | null;
  title?: string | null;
  firstName: string;
  lastName: string;
  streetName?: string | null;
  streetNumber?: string | null;
  postalCode: string;
  city: string;
  region: string;
  state?: string | null;
  country: string;
  company?: string | null;
  department?: string | null;
  building?: string | null;
  apartment?: string | null;
  pOBox?: string | null;
  phone?: string | null;
  mobile?: string | null;
  email: string;
  saveToProfile?: boolean | null;
  additionalAddressInfo?: addressType | null;
  externalId?: string | null;
}

export interface lineItemPrescriptionDraft {
  lineItemId: string;
  eye?: EyeType | null;
  sphere?: string | null;
  cyl?: string | null;
  axis?: string | null;
  add?: string | null;
  power?: string | null;
  nearpd?: string | null;
  height?: string | null;
  pd?: string | null;
  bvd?: string | null;
  intAdd?: string | null;
  hPrism?: string | null;
  vPrism?: string | null;
  hPrismType?: HPrismType | null;
  vPrismType?: VPrismType | null;
  lensType?: LensType | null;
  genericAdd?: string | null;
}

export interface removeItemDraft {
  lineItemId?: string | null;
  bundleNumber?: number | null;
}

export interface sendSMSDraft {
  cartId: string;
  phoneNumber: string;
  name: string;
}

export interface updateLineItemPrescriptionDraft {
  lineItemPrescriptions: (lineItemPrescriptionDraft | null)[];
  pantoscopic_tilt?: string | null;
  frame_wrap?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
