import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { DateTime } from "luxon";

import { addressDraft, EditablePatientData, Patient } from "../types";
import { differenceInYears, getCountryCode, getMedicareExpiryDateFromString, isValid, validateEmailAddress } from "../utils";
import { isBefore } from "./dateTime";

export const getAgeFromBirthday = (patient?: Patient): string => {
  if (patient?.dateOfBirth) {
    const years = DateTime.fromFormat(patient.dateOfBirth, "yyyy-MM-dd").diffNow("years").years;
    return Math.abs(Math.trunc(years)).toString();
  }

  return "";
};

export const formatPatientDetails = (patient?: Patient, showFullDetail?: boolean): string => {
  if (!patient || isEmpty(patient)) {
    return "";
  }

  return `${
    showFullDetail
      ? `${patient?.preferredName || patient?.firstName} ${patient?.lastName}`
      : `${patient?.preferredName || patient?.firstName} ${patient?.lastName?.[0] ?? ""}`
  } (${(patient?.gender?.[0] ?? "-").toUpperCase()} / ${patient?.age ?? getAgeFromBirthday(patient) ?? "-"})`;
};

export const formatPatientFullName = (patient?: Partial<Patient>): string =>
  (patient ? `${patient?.preferredName || patient?.firstName || ""} ${patient?.lastName || ""}` : "").trim();

export const getGenderAgeString = (patient?: Patient): string => {
  if (!patient) {
    return "- / -";
  }
  const { gender, dateOfBirth } = patient;
  const DOB = DateTime.fromISO(patient?.dateOfBirth as string);

  const genderString = gender?.[0] ?? "-";
  const ageString = !dateOfBirth || !DOB.isValid ? "-" : `${differenceInYears(new Date(), DOB.toJSDate())}`;

  return `${genderString.toUpperCase()} / ${ageString}`;
};

export const getExternalIdString = (externalId?: string): string => {
  const prefix = process.env.REACT_APP_EXTERNAL_ID_PREFIX ?? "";
  return externalId ? `External ID: ${prefix + externalId}` : "-";
};

export const validateMedicareNumberFormat = (medicare: string | undefined): boolean => {
  let isValid = false;

  if (medicare && medicare.length === 10) {
    const matches = medicare.match(/^(\d{8})(\d)/);

    if (!matches) {
      return false;
    }
    const base = matches[1];
    const checkDigit = matches[2];
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(base[i], 10) * weights[i];
    }

    isValid = sum % 10 === parseInt(checkDigit, 10);
  }

  return isValid;
};

export const validateAddress = (address?: Partial<addressDraft>): addressDraft | null =>
  [
    !address?.firstName,
    !address?.lastName,
    !address?.streetName,
    !address?.streetNumber,
    !address?.postalCode,
    !address?.city,
    !address?.region,
    !address?.country,
    !address?.mobile,
    !address?.email,
  ].some(Boolean)
    ? null
    : (Object.fromEntries(Object.entries(address ?? {}).filter((entry) => !isNil(entry[1]))) as unknown as addressDraft);

//make sure patient has all the data it needs
export const validatePatientDetail = (patient: Patient | EditablePatientData | undefined): boolean => {
  if (!patient) {
    return false;
  }
  const { mobilePhone, landline, firstName, lastName, email, id, dateOfBirth } = patient ?? {};
  const existingPatient = !!id;

  const validPhone = !mobilePhone ? true : isValidPhoneNumber(mobilePhone ?? "", getCountryCode().toUpperCase() as CountryCode);
  const validLandLine = !landline ? true : isValidPhoneNumber(landline ?? "", getCountryCode().toUpperCase() as CountryCode);

  const validMedicareExpiryDate = patient?.medicareExpiry ? validateMedicareExpiryDate(patient.medicareExpiry) : true;

  const patientFieldsByType = [
    firstName,
    lastName,
    validMedicareExpiryDate,
    hasContactNumber(patient),
    validPhone,
    validLandLine,
    isValid(dateOfBirth),
    ...(existingPatient ? [] : [validateEmailAddress(email)]),
  ];
  return [...patientFieldsByType].every(Boolean);
};

export const validatePhoneNumber = (number: string | null | undefined): boolean => {
  if (number && number.length > 0) {
    return isValidPhoneNumber(number, getCountryCode().toUpperCase() as CountryCode);
  }
  return true;
};

export const validateEmail = (email?: string | null): boolean => {
  if (email && email.length > 0) {
    return validateEmailAddress(email);
  }
  return true;
};

export const validateMedicareDetail = (number: string | null | undefined): boolean => {
  if (number && number.trim().length > 0) {
    return validateMedicareNumberFormat(number);
  }
  return true;
};

export const validateMedicareExpiryDate = (expiryDate: string | null | undefined): boolean => {
  if (expiryDate && expiryDate.length > 0) {
    const regex = "^(0[1-9]|1[0-2])/?([0-9]{2})$";
    const re = new RegExp(regex);
    if (re.test(expiryDate)) {
      const medicareExpiryDate = getMedicareExpiryDateFromString(expiryDate); // e.g 03/20 to convert to 01/03/2020

      const today = new Date();
      if (medicareExpiryDate) {
        return (
          isBefore(today, medicareExpiryDate) ||
          (today.getMonth() === medicareExpiryDate.getMonth() && today.getFullYear() === medicareExpiryDate.getFullYear())
        );
      }
    }
    return false;
  }
  return true;
};

//make sure both landline and mobile number are not empty
export const hasContactNumber = (patient: EditablePatientData | Patient | undefined): boolean => !!patient?.landline || !!patient?.mobilePhone;

export const isDisableValidateMedicareButton = (
  medicare: string | null | undefined,
  reference: string | null | undefined,
  validTo: string | null | undefined,
): boolean => {
  if (medicare && medicare.length === 10 && reference && validTo && validateMedicareExpiryDate(validTo)) {
    return false;
  }
  return true;
};
