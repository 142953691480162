import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AvailabilityConfigurationData, AvailabilityData, DistributionChannels_distributionChannels, StoredAvailabilityData } from "../../types";
import { getMonthlyAvailsEndDate, getMonthlyAvailsStartDate, localISOString } from "../../utils";

export interface AvailabilityState {
  activeDistributionChannel: DistributionChannels_distributionChannels | null;
  allDistributionChannels: DistributionChannels_distributionChannels[];
  availabilityEndDate: string;
  availabilityStartDate: string;
  selectedDate: string;
  selectedOptomId: string;
  storedAvailabilityData?: StoredAvailabilityData;
}

export const genInitAvailabilityConfigurations = (index: number, availabilityData?: AvailabilityData[]): AvailabilityConfigurationData => {
  const configuration: AvailabilityConfigurationData = {
    index,
    data: availabilityData ?? [],
  };
  return configuration;
};

export const initialAvailabilityState: AvailabilityState = {
  activeDistributionChannel: null,
  allDistributionChannels: [],
  availabilityStartDate: getMonthlyAvailsStartDate(new Date()),
  availabilityEndDate: getMonthlyAvailsEndDate(new Date()),
  selectedDate: localISOString(new Date()),
  selectedOptomId: "",
  storedAvailabilityData: undefined,
};

export const availabilitySlice = createSlice({
  name: "availability",
  initialState: initialAvailabilityState,
  reducers: {
    resetAvailabilityState: () => initialAvailabilityState,
    setOptomId: (state, action: PayloadAction<string>) => {
      state.selectedOptomId = action.payload;
    },
    setActiveDistributionChannel: (state, action: PayloadAction<DistributionChannels_distributionChannels>) => {
      state.activeDistributionChannel = action.payload;
    },
    setAvailabilityStartDate: (state, action: PayloadAction<Date>) => {
      // fetching availabilities for the whole month because that is the minimum resolution of the calendar
      state.availabilityStartDate = getMonthlyAvailsStartDate(action.payload);
    },
    setAvailabilityEndDate: (state, action: PayloadAction<Date>) => {
      state.availabilityEndDate = getMonthlyAvailsEndDate(action.payload);
    },
    setAllDistributionChannels: (state, action: PayloadAction<DistributionChannels_distributionChannels[]>) => {
      state.allDistributionChannels = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
    setStoredAvailabilityData: (state, action: PayloadAction<AvailabilityData[]>) => {
      const activeDCKey = state.activeDistributionChannel?.key;
      const selectedOptomId = state.selectedOptomId;

      if (activeDCKey && selectedOptomId) {
        state.storedAvailabilityData = {
          ...state.storedAvailabilityData,
          [selectedOptomId]: {
            ...(state.storedAvailabilityData?.[selectedOptomId] ?? {}),
            [activeDCKey]: [
              ...(new Map(
                [...(state.storedAvailabilityData?.[selectedOptomId]?.[activeDCKey] ?? []), ...action.payload].map((a) => [a.id, a]),
              ).values() as unknown as []),
            ],
          },
        };
      }
    },
    removeFromAvailabilityData: (state, action: PayloadAction<string[]>) => {
      const availabilityIdsToRemove = action.payload;
      const selectedOptomId = state.selectedOptomId;
      const dcKey = state.activeDistributionChannel?.key;

      if (selectedOptomId && dcKey && availabilityIdsToRemove?.length > 0 && state.storedAvailabilityData) {
        availabilityIdsToRemove.forEach((id) => {
          const indexToRemove = (state.storedAvailabilityData?.[selectedOptomId]?.[dcKey] ?? []).findIndex((a) => a.id === id);

          if (indexToRemove !== -1) {
            state.storedAvailabilityData?.[selectedOptomId][dcKey].splice(indexToRemove as number, 1);
          }
        });
      }
    },
    updateStoredAvailabilityData: (state, action: PayloadAction<AvailabilityData[]>) => {
      const selectedOptomId = state.selectedOptomId;
      const dcKey = state.activeDistributionChannel?.key;
      const availabilityUpdates = action.payload;

      if (selectedOptomId && dcKey && availabilityUpdates?.length > 0 && state.storedAvailabilityData) {
        availabilityUpdates.forEach((availability) => {
          const existingAvailabilityIndex = (state.storedAvailabilityData?.[selectedOptomId]?.[dcKey] ?? []).findIndex(
            (a) => a.id === availability.id,
          );

          if (existingAvailabilityIndex !== -1 && state.storedAvailabilityData) {
            state.storedAvailabilityData[selectedOptomId][dcKey][existingAvailabilityIndex] = availability;
          }
        });
      }
    },
    removeOptomId: (state) => {
      state.selectedOptomId = "";
    },
    removeActiveDistributionChannel: (state) => {
      state.activeDistributionChannel = null;
    },
  },
});

export const {
  removeFromAvailabilityData,
  resetAvailabilityState,
  setActiveDistributionChannel,
  setAllDistributionChannels,
  setAvailabilityEndDate,
  setAvailabilityStartDate,
  setOptomId,
  setSelectedDate,
  setStoredAvailabilityData,
  updateStoredAvailabilityData,
  removeOptomId,
  removeActiveDistributionChannel,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
