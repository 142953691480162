import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { SearchPatientsElastic, SearchPatientsElasticVariables } from "../../types";

export const SEARCH_PATIENTS = gql`
  query SearchPatientsElastic($query: String!, $countryCode: String!) {
    searchPatientsElastic(query: $query, countryCode: $countryCode) {
      count
      patientSearchElasticResponse {
        id
        firstName
        externalId
        preferredName
        healthfundName
        gender
        dateOfBirth
        age
        email
        mobilePhone
        landline
        lastName
        medicare
        isMedicareValidated
        medicareReference
        medicareExpiry
        notes
        privateHealthFundCode
        privateHealthFundMemberNumber
        privateHealthFundMemberReference
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<SearchPatientsElastic>, "data" | "loading" | "error"> {
  searchPatients: ({ variables }: { variables: SearchPatientsElasticVariables }) => void;
  called: boolean;
}

export const useSearchPatientsQuery = (handleOnComplete?: (data: SearchPatientsElastic) => void): ExtendedQueryResult => {
  const [searchPatients, { data, error, loading, called }] = useLazyQuery<SearchPatientsElastic, SearchPatientsElasticVariables>(SEARCH_PATIENTS, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  return {
    searchPatients,
    data,
    error,
    loading,
    called,
  };
};
