import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";
import AWSAmplify, { Auth } from "aws-amplify";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, useState } from "react";

import { selectStaffAuthenticated, staffLogin } from "../../state/authentication";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import awsconfig from "./aws-exports";

const PinAuthContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const PinFormContainer = styled.div`
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  width: 25rem;
  padding: 1.5rem;
`;

const ElementContainer = styled.div`
  padding-bottom: 1.25rem;
`;

AWSAmplify.configure(awsconfig);

type Props = {
  children?: React.ReactNode;
};

const PinAuth: FC<Props> = ({ children }) => {
  const { staffPINAuth } = useFlags();
  const staffAuthenticated = useAppSelector(selectStaffAuthenticated);
  const dispatch = useAppDispatch();

  const [disableLogin, setDisableLogin] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pin, setPin] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const handleSubmit = async (): Promise<void> => {
    try {
      setError(null);
      setDisableLogin(true);
      if (username && pin) {
        const user = await Auth.signIn(username, pin);
        if (user) {
          dispatch(staffLogin());
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      switch (e?.code) {
        case "NotAuthorizedException":
          setError("Invalid PIN");
          break;
        case "UserNotFoundException":
          setError("User Not Found");
          break;
        default:
          setError("Unexpected Error");
      }
    } finally {
      setDisableLogin(false);
    }
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setError(null);
    setUsername(e.target.value);
  };
  const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setError(null);
    setPin(e.target.value);
  };

  if (staffAuthenticated || !staffPINAuth) {
    return <>{children}</>;
  }

  return (
    <PinAuthContainer>
      <PinFormContainer>
        <h1>PIN Login</h1>
        <ElementContainer>
          <TextField type="text" id="username" label="Username" variant="outlined" value={username} onChange={handleUsernameChange} />
        </ElementContainer>
        <ElementContainer>
          <TextField type="password" id="pin" label="PIN" variant="outlined" value={pin} onChange={handlePinChange} />
        </ElementContainer>
        {error && <ElementContainer>{error}</ElementContainer>}
        <ElementContainer>
          <Button variant="contained" color="primary" disabled={disableLogin} onClick={handleSubmit}>
            Login
          </Button>
        </ElementContainer>
      </PinFormContainer>
    </PinAuthContainer>
  );
};

export default PinAuth;
