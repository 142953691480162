import { gql } from "@apollo/client";

export const APPOINTMENT_TYPE_FIELDS = gql`
  fragment AppointmentTypeFields on AppointmentType {
    id
    name
    publicName
    publicDescription
    durationPeakA
    durationOffPeakA
    durationPeakB
    durationOffPeakB
    durationPeakC
    durationOffPeakC
    onlineBooking
    requiresPatient
    color
    active
  }
`;

export const PATIENT_FIELDS = gql`
  fragment PatientFields on Patient {
    id
    firstName
    lastName
    preferredName
    dateOfBirth
    externalId
    mobilePhone
    landline
    workNumber
    email
    access_token
    refresh_token
    expiresAt
    medicare
    medicareReference
    medicareExpiry
    isMedicareValidated
    notes
    externalId
    countryCode
    gender
    privateHealthFundCode
    privateHealthFundMemberNumber
    privateHealthFundMemberReference
  }
`;

export const CORE_OPTOMETRIST_FIELDS = gql`
  fragment CoreOptometristFields on Optometrist {
    id
    firstName
    lastName
    email
    appointmentSet
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    additionalAddressInfo
    apartment
    building
    city
    company
    country {
      code
    }
    department
    email
    externalId
    firstName
    id
    key
    lastName
    mobile
    pOBox
    phone
    postalCode
    region
    salutation
    state
    streetName
    streetNumber
    title
  }
`;

export const LINE_ITEM_FIELDS = gql`
  fragment LineItemFields on LineItem {
    id
    productId
    name {
      en
    }
    productSlug {
      en
    }
    productType {
      key
      id
    }
    variant {
      name
      key
      sku
      singleHealthFundCode
      multiHealthFundCode
      singleHealthFundCodeAddOns
      multiHealthFundCodeAddOns
      customerGroupHealthFundCode
      eoltTraceLib
      bnTraceLib
      images {
        url
        label
      }
    }
    price {
      value {
        currencyCode
        centAmount
      }
      discounted {
        value {
          currencyCode
          centAmount
        }
      }
    }
    quantity
    discounts {
      totalDiscount {
        currencyCode
        centAmount
      }
      includedDiscounts {
        name
        description
        amount {
          type
          currencyCode
          centAmount
          fractionDigits
        }
      }
    }
    supplyChannel {
      key
    }
    distributionChannel {
      id
      name {
        en
      }
    }
    shippingDetails {
      targets {
        addressKey
        quantity
      }
      valid
    }
    totalPrice {
      currencyCode
      centAmount
    }
    customFields {
      eye
      sphere
      cyl
      axis
      add
      power
      nearpd
      height
      pd
      bvd
      bundleNumber
      intAdd
      hPrism
      vPrism
      lensType
      hPrismType
      vPrismType
      physicalTraceRequired
      frameSku
      sunOpt
      genericAdd
      isQuote
      fitToFrame
    }
  }
`;

export const CART_FIELDS = gql`
  ${LINE_ITEM_FIELDS}
  ${ADDRESS_FIELDS}
  fragment CartFields on Cart {
    id
    createdAt
    isQuote
    lineItems {
      ...LineItemFields
    }
    customFields {
      specialInstructions
      contactMethod
    }
    totalPrice {
      currencyCode
      centAmount
    }
    shippingAddress {
      ...AddressFields
    }
    shippingInfo {
      shippingMethodName
      shippingMethod {
        key
      }
    }
    paymentInfo {
      payments {
        key
        amountPlanned {
          centAmount
          fractionDigits
          currencyCode
          type
        }
        creditCardNumber
        paymentMethod
      }
    }
    discountCodes {
      discountCodeRef {
        typeId
        id
      }
      state
      discountCode {
        code
        validFrom
        validUntil
        name {
          en
        }
        description {
          en
        }
        id
      }
    }
  }
`;

export const ORDER_FIELD = gql`
  fragment OrderField on Order {
    id
    status
    orderNumber
    createdAt
    totalPrice {
      currencyCode
      centAmount
    }
    patientId
    shippingInfo {
      shippingMethodName
    }
    shippingAddress {
      firstName
      lastName
      streetName
      streetNumber
      postalCode
      city
      region
      state
      mobile
      email
    }
    customFields {
      specialInstructions
      customerNotified
      contactMethod
    }
    lineItems {
      id
      quantity
      productId
      name {
        en
      }
      productType {
        key
      }
      supplyChannel {
        key
      }
      distributionChannel {
        key
        name {
          en
        }
      }
      variant {
        name
        key
        sku
        images {
          url
        }
        singleHealthFundCode
        multiHealthFundCode
        singleHealthFundCodeAddOns
        multiHealthFundCodeAddOns
        customerGroupHealthFundCode
      }
      returnInfos {
        comment
      }
      totalPrice {
        centAmount
        currencyCode
      }
      discounts {
        totalDiscount {
          currencyCode
          centAmount
        }
      }
      state {
        quantity
        state {
          id
          key
          name {
            en
          }
        }
      }
      customFields {
        eye
        bundleNumber
        sphere
        lensType
        cyl
        axis
        add
        height
        nearpd
        power
        pd
        bvd
        intAdd
        hPrism
        vPrism
        hPrismType
        vPrismType
        physicalTraceRequired
        sunOpt
        frameSku
        collectedDate
        isTrace
      }
    }
    fullPaymentInfo {
      amountPlanned {
        currencyCode
        centAmount
        fractionDigits
      }
      paymentGateway
      paymentMethod
      transactions {
        type
        amount {
          currencyCode
          centAmount
        }
        state
        id
      }
    }
  }
`;

export const CONTACTS_PRESCRIPTION_FIELDS = gql`
  fragment ContactsPrescriptionFields on ContactsPrescription {
    id
    optometristName
    practiceName
    issueDate
    expiresDate
    odSku
    odSupplyChannelsKey
    osSupplyChannelsKey
    odPower
    odCyl
    odAxis
    odAdd
    odGenericAdd
    osSku
    osPower
    osCyl
    osAxis
    osAdd
    osGenericAdd
    optometrist {
      id
      firstName
      lastName
    }
  }
`;

export const GLASSES_PRESCRIPTION_FIELDS = gql`
  fragment GlassesPrescriptionFields on GlassesPrescription {
    id
    optometristName
    practiceName
    issueDate
    expiresDate
    odCyl
    odAxis
    odAdd
    osCyl
    osAxis
    osAdd
    optometrist {
      id
      firstName
      lastName
    }
    odSph
    odIntAdd
    odPd
    odNearPd
    odHPrismType
    odHPrism
    odVPrismType
    odVPrism
    osSph
    osIntAdd
    osPd
    osNearPd
    osHPrismType
    osHPrism
    osVPrismType
    osVPrism
  }
`;

export const SEARCH_PRODUCT_FIELDS = gql`
  fragment SearchProductFields on ProductSearchResponse {
    name
    key
    variants {
      sku
      name
      images {
        url
        label
      }
      sphere
      cyl
      axis
      add
      power
      subscriptionFrequency
      modality
      daysUsage
      stocks {
        key
        name
        availableQuantity
      }
      bnTrackLib
      eoltTrackLib
      preferredCLSupplyChannelKey
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;
