export const ZINDEX = {
  // Calendar
  CALENDAR_TIME_INDICATOR: 999999, // see RBCCustomStyle.scss
  CALENDAR_ADD_EDIT_CONTAINER: 99,
  CALENDAR_TOOLBAR_CONTAINER: 99,
  CALENDAR_MOBILE_CONTROLS_CONTAINER: 99999999,
  CALENDAR_MOBILE_CONTROLS_CLICKOUT: 999999999,
  CALENDAR_MOBILE_PATIENT_SEARCH: 12,
  CALENDAR_MOBILE_ANIMATED_SLIDER: 99,
  CALENDAR_MOBILE_MENU: 99999,

  // App layout components
  LAYOUT_SIDEBAR: 99999,
};
