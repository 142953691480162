import uniqBy from "lodash/uniqBy";

import { Appointment, APPOINTMENT_VIEWS, Availability, CalendarNote, Optom, Patient, SetActiveAppointmentPayload } from "../../types";
import { addDays, getHourFromISO, localShortDate, validatePatientDetail } from "../../utils";
import { RootState } from "../store";

export const selectActiveAppointment = (state: RootState): SetActiveAppointmentPayload => state.appointments.activeAppointment;
export const selectActiveAppointmentHasPatient = (state: RootState): boolean =>
  !!state.appointments.activeAppointment?.appointment?.resource?.type?.requiresPatient;
export const selectActiveAppointmentPatient = (state: RootState): Patient | undefined =>
  state.appointments.activeAppointment?.appointment?.resource?.patient;
export const selectActiveNote = (state: RootState): CalendarNote => state.appointments.activeNote;
export const selectCalendarDate = (state: RootState): Date => state.appointments.calendarDate;
export const selectCalendarDateString = (state: RootState): string => localShortDate(state.appointments.calendarDate);
export const selectCalendarView = (state: RootState): APPOINTMENT_VIEWS => state.appointments.calendarView;
// return true if there are more than one unique optom id in avails of calendar date + 2 days (3day view)
export const selectDisableThreeDayView = (state: RootState): boolean => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;

  if (!activeDCKey) {
    return true;
  }
  const calendarDateAvailabilities = [
    ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[state.appointments.calendarDateString] ?? []),
    ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 1))] ?? []),
    ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 2))] ?? []),
  ];
  if (state.appointments.displayedOptomId) {
    return false;
  }
  const optomCount = uniqBy(calendarDateAvailabilities, "optometrist.id").length;

  return optomCount > 1;
};
export const selectDisplayedOptomId = (state: RootState): string | undefined => state.appointments.displayedOptomId;
export const selectPatientSearches = (state: RootState): string[] => state.appointments.patientSearches;
export const selectShowAddAppointment = (state: RootState): boolean => state.appointments.showAddAppointment;
export const selectShowCancelled = (state: RootState): boolean => state.appointments.showCancelled;
export const selectShowEditAppointment = (state: RootState): boolean => state.appointments.showEditAppointment;
export const selectShowMobileMenu = (state: RootState): boolean => state.appointments.showMobileMenu;
export const selectShowPatientCard = (state: RootState): boolean => state.appointments.showPatientCard;
export const selectShowFullDetail = (state: RootState): boolean => state.appointments.showFullDetail;
export const selectShowStoreNote = (state: RootState): boolean => state.appointments.showCalendarNote;
export const selectCalendarNotes = (state: RootState): CalendarNote[] => state.appointments.calendarNotes;
export const selectTempAppointmentComplete = (state: RootState): boolean => {
  const { patient, type } = state.appointments.activeAppointment?.appointment?.resource ?? {};
  const appointmentOptom = state.appointments.activeAppointment?.appointment?.resource?.optom;
  const availableOptoms = selectDCCalendarDateOptoms(state);

  const hasValidOptom = availableOptoms.find((optom) => {
    return optom.id === appointmentOptom?.id;
  }); //for rescheduling to a different date, check if current appointment optom still available on the new date,if not ask staff to select a new optom.

  const isValidPatient = validatePatientDetail(patient);
  const patientFieldsByType = type?.requiresPatient ? [isValidPatient, hasValidOptom] : [hasValidOptom];

  return [...patientFieldsByType, type].every(Boolean);
};
export const selectValidNewPatientPayload = (state: RootState): boolean => {
  const appointmentPatient = state.appointments.activeAppointment?.appointment?.resource?.patient;
  const hasId = !!appointmentPatient?.id;
  const hasFirstName = !!appointmentPatient?.firstName && appointmentPatient.firstName.length > 0;
  return !hasId && hasFirstName;
};

export const selectDCCalendarDateOptoms = (state: RootState): Optom[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  const avails = state.appointments.storedAvailabilities?.[activeDCKey]?.[state.appointments.calendarDateString] ?? [];
  // duplicate optoms cause issues with the calendar time grid header
  return [...(new Map(avails.map((avail) => [avail.optometrist.id, avail.optometrist])).values() as unknown as Optom[])];
};

export const selectDCAvailabilities = (state: RootState): Availability[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  return Object.values(state.appointments.storedAvailabilities?.[activeDCKey] ?? {}).flatMap((a: Availability[]) => a);
};

export const selectDCDateAvailabilities = (state: RootState): Availability[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;

  if (!activeDCKey) {
    return [];
  }
  return state.appointments.storedAvailabilities?.[activeDCKey]?.[state.appointments.calendarDateString] ?? [];
};

export const selectDCDateRangeAvailabilities = (state: RootState): Availability[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;

  if (!activeDCKey) {
    return [];
  }

  if (state.appointments.calendarView === APPOINTMENT_VIEWS.THREE_DAY) {
    return [
      ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[state.appointments.calendarDateString] ?? []),
      ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 1))] ?? []),
      ...(state.appointments.storedAvailabilities?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 2))] ?? []),
    ];
  }

  return state.appointments.storedAvailabilities?.[activeDCKey]?.[state.appointments.calendarDateString] ?? [];
};

export type MinMaxDate = { min: Date | undefined; max: Date | undefined };

export const selectDCDateAvailabilitiesMinMax = (state: RootState): { start: MinMaxDate; end: MinMaxDate } => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  const availabilities = selectDCDateRangeAvailabilities(state) ?? [];

  if (!activeDCKey || availabilities.length === 0) {
    return { start: { min: undefined, max: undefined }, end: { min: undefined, max: undefined } };
  }

  const minStart = availabilities.reduce((pre, cur) => (getHourFromISO(pre.startAt) > getHourFromISO(cur.startAt) ? cur : pre));

  const maxStart = availabilities.reduce((pre, cur) => (getHourFromISO(pre.startAt) < getHourFromISO(cur.startAt) ? cur : pre));
  const minEnd = availabilities.reduce((pre, cur) => (getHourFromISO(pre.endAt) > getHourFromISO(cur.endAt) ? cur : pre));
  const maxEnd = availabilities.reduce((pre, cur) => (getHourFromISO(pre.endAt) < getHourFromISO(cur.endAt) ? cur : pre));

  return {
    start: {
      min: new Date(minStart.startAt),
      max: new Date(maxStart.startAt),
    },
    end: {
      min: new Date(minEnd.endAt),
      max: new Date(maxEnd.endAt),
    },
  };
};

export const selectDCDateAppointments = (state: RootState): Appointment[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  if (state.appointments.calendarView === APPOINTMENT_VIEWS.THREE_DAY) {
    const threeDayRangeAppointments = [
      ...(state.appointments.storedAppointments?.[activeDCKey]?.[state.appointments.calendarDateString] ?? []),
      ...(state.appointments.storedAppointments?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 1))] ?? []),
      ...(state.appointments.storedAppointments?.[activeDCKey]?.[localShortDate(addDays(state.appointments.calendarDate, 2))] ?? []),
    ];
    return threeDayRangeAppointments.filter((appointment: Appointment) => !appointment?.edit);
  }
  return (state.appointments.storedAppointments?.[activeDCKey]?.[state.appointments.calendarDateString] ?? []).filter(
    (appointment: Appointment) => !appointment?.edit,
  );
};

export const selectDCAppointmentRequestDates = (state: RootState): Date[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  return Object.keys(state.appointments.storedAppointments?.[activeDCKey] ?? {}).map((date) => new Date(date));
};

export const selectDCAppointmentDates = (state: RootState): Date[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  const allDates = Object.values(state.appointments.storedAppointments?.[activeDCKey] ?? {})
    .flatMap((appointment: Appointment[]) => appointment)
    .map((appointment: Appointment) => new Date(appointment.start));
  return allDates;
};

export const selectDCAvailabilityDates = (state: RootState): Date[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  const allDates = Object.values(state.appointments.storedAvailabilities?.[activeDCKey] ?? {})
    .flatMap((availability: Availability[]) => availability)
    .map((availability: Availability) => new Date(availability.startAt));
  return allDates;
};

export const selectDCAvailabilityRequestDates = (state: RootState): Date[] => {
  const activeDCKey = state.authentication.activeDistributionChannel?.key;
  if (!activeDCKey) {
    return [];
  }
  return Object.keys(state.appointments.storedAvailabilities?.[activeDCKey] ?? {}).map((date) => new Date(date));
};
