import { DateTime } from "luxon";

import { AvailabilityData, DistributionChannels_distributionChannels } from "../../types";
import { isLocalSameDay, localShortDate } from "../../utils";
import { RootState } from "../store";

export const selectOptomId = (state: RootState): string => state.availability.selectedOptomId;
export const selectActiveDistributionChannel = (state: RootState): DistributionChannels_distributionChannels | null =>
  state.availability.activeDistributionChannel;
export const selectStartDate = (state: RootState): string => state.availability.availabilityStartDate;
export const selectAllDistributionChannels = (state: RootState): DistributionChannels_distributionChannels[] =>
  state.availability.allDistributionChannels;
export const selectEndDate = (state: RootState): string => state.availability.availabilityEndDate;
export const selectSelectedDate = (state: RootState): string => state.availability.selectedDate;
export const selectStoredAvailabilityData = (state: RootState): AvailabilityData[] => {
  const optomId = selectOptomId(state);
  const activeDCKey = state.availability.activeDistributionChannel?.key;
  if (!activeDCKey || !optomId) {
    return [];
  }
  return state.availability.storedAvailabilityData?.[optomId]?.[activeDCKey] ?? [];
};
export const selectDailyStoredAvailabilityData =
  (date: Date) =>
  (state: RootState): AvailabilityData[] => {
    const availabilities = selectStoredAvailabilityData(state);
    return availabilities.filter((availability) => isLocalSameDay(new Date(availability.startAt as string), date));
  };
export const selectStoredAvailabilityDays = (state: RootState): string[] => {
  return selectStoredAvailabilityData(state).map((availability) => localShortDate(DateTime.fromISO(availability.startAt).toJSDate()));
};

export const selectOptomAvailabilityData = (state: RootState): AvailabilityData[] => {
  const optomId = selectOptomId(state);
  return Object.values(state.availability.storedAvailabilityData?.[optomId] ?? {}).flatMap((availability: AvailabilityData[]) => availability);
};
