import { gql, QueryResult, useQuery } from "@apollo/client";

import { LineItemStateTransitions } from "../../types";

export const LINE_ITEM_STATE_TRANSITIONS = gql`
  query LineItemStateTransitions($stateId: ID!) {
    lineItemStateTransitions(stateId: $stateId) {
      transitions {
        id
        key
        name {
          en
        }
      }
    }
  }
`;

export const useLineItemStateTransitions = (stateId: string): QueryResult<LineItemStateTransitions> => {
  return useQuery<LineItemStateTransitions>(LINE_ITEM_STATE_TRANSITIONS, {
    variables: {
      stateId,
    },
  });
};
