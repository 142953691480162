import isNil from "lodash/isNil";

import { serviceOptions } from "../constants";
import { initialLensBuilderState, LensBuilderState } from "../state/lensBuilder";
import {
  Address,
  addressDraft,
  addressType,
  AddToCartDraftWithLineItemId,
  Cart_cart_lineItems_discounts,
  CATEGORY_SLUG,
  DistributionChannels_distributionChannels_address,
  EYE_OPTION_TYPE,
  EyeType,
  LENS_BUILDER_STEP,
  LineItem,
  lineItemPrescriptionDraft,
  Patient,
  Product,
  ServiceOption,
  Stock,
  Variant,
} from "../types";
import { currencyFormat, getCountryCode } from "./helpers";

// list supplied by YS 21/02/2022
const CONTACT_LENS_SUPPLIERS = [
  "Alcon-au",
  "Alcon-nz",
  "BauschLomb-au",
  "CooperVision-au",
  "CooperVision-nz",
  "JohnsonJohnsonVisionCare-au",
  "JohnsonJohnsonVisionCare-nz",
  "Menicon",
  "RadiantHealth-nz",
];

export const shouldRemoveSunglassSKUs = (sku: string, remove?: boolean): boolean => !!remove && sku.toLowerCase().includes("sun");

export const matchingSunVariantStock = (optFrame: Variant, sunVariants: Variant[], storeKey: string): Stock | undefined => {
  const sunVariant = sunVariants.find((variant) => variant.sku.replace("SUN", "OPT") === optFrame.sku);
  return sunVariant?.stocks.find((stock) => stock.key === storeKey && (stock?.availableQuantity ?? 0) > 0);
};

export const filterAvailableProducts = (products?: Product[], dcKey?: string | null, service?: ServiceOption): Product[] => {
  const isCustomisedFrame = service?.displayName === serviceOptions.framesCustomised.displayName;
  const isLensOnly = service?.displayName === serviceOptions[CATEGORY_SLUG.LENSES].displayName;
  const isDemoLens = service?.displayName === serviceOptions.demoLens.displayName;
  const contactLens = service?.slug === CATEGORY_SLUG.CONTACT_LENSES;

  const customOrDemoLens = isCustomisedFrame || isDemoLens;

  const sunVariants = products?.flatMap((product) => product.variants.filter((variant) => variant.sku.slice(-3) === "SUN")) as Variant[];
  return (products ?? []).reduce((accum: Product[], product: Product) => {
    const productVariants = product.variants.reduce((accV: Variant[], variant: Variant) => {
      if (!variant || !variant.sku || !variant.name || !variant.stocks || shouldRemoveSunglassSKUs(variant.sku, customOrDemoLens)) {
        return accV;
      }

      const contrycode = getCountryCode().toLocaleLowerCase();

      // filter variant by stock availability for supply channels appropriate for order type
      const stocks = variant.stocks
        .filter(
          (stock) =>
            [
              ...(dcKey && !customOrDemoLens && !contactLens ? [dcKey] : []), // show variants from store except for customised glasses & contact lenses
              ...(customOrDemoLens ? (dcKey ? ["EOLT", dcKey] : ["EOLT"]) : []), // show EOLT and local inventory variants for customised glasses
              ...(contactLens ? CONTACT_LENS_SUPPLIERS.filter((supplier) => supplier.includes(contrycode)) : []), // show variants from contact lens suppliers that are for current country
            ].includes(stock?.key ?? "") && (stock?.availableQuantity ?? 0) > 0,
        )
        .sort((stock) => (stock.key === "EOLT" ? -1 : 0));

      // apply variant filtering by available stock or
      // ignore stock filtering for lens only and demo lens or
      // include if there is matching sun variant with stock for opt frame
      if (stocks.length > 0 || isLensOnly || service?.quote) {
        accV.push({ ...variant, stocks });
      } else if (variant.sku.slice(-3) === "OPT" && dcKey) {
        const sunVariantStock = matchingSunVariantStock(variant, sunVariants, dcKey);
        if (sunVariantStock) {
          // sunOpt flag is added to the frame and is required when the item adding to cart
          accV.push({ ...variant, sunOpt: true, stocks: [sunVariantStock] });
        }
      }
      return accV;
    }, []);

    if (productVariants.length > 0) {
      accum.push({ ...product, variants: productVariants });
    }
    return accum;
  }, []);
};

type ProductTypeName = string;

type BundleGroup = Record<ProductTypeName, LineItem[]>;

type ProductGroup = Record<number, BundleGroup>;

/**
 * @param lineItems
 * @returns ProductGroup -> { [number]: { [ProductTypeName (eg frameSku/lensesSku)]: LineItem[] } }>
 * @description
 * Group line items by 1) bundle number, then 2) product type eg frame, lenses, addons etc
 */
export const groupLineItems = (lineItems: LineItem[]): ProductGroup =>
  lineItems.reduce((acc: ProductGroup, item: LineItem) => {
    const bundleNumber = item.customFields?.bundleNumber ?? -1;
    if (!acc[bundleNumber]) {
      acc[bundleNumber] = {};
    }
    const addOn = item.productSlug?.en === "addons";
    const productType = addOn ? "addons" : item.productType?.key;
    const variantSku = item.variant?.sku ?? "";

    // needed to add sku to display contact lenses with different skus in different groups
    const objProductKey = `${productType}${variantSku}`;
    if (productType && !acc[bundleNumber][objProductKey]) {
      acc[bundleNumber][objProductKey] = [];
    }

    acc[bundleNumber][objProductKey] = [...acc[bundleNumber][objProductKey], item];
    return acc;
  }, {});

export const cartLineItemsDisplaySubtotal = (lineItems: LineItem[]): string => {
  const productSubtotal = lineItems.reduce((acc, lineItem) => {
    acc += lineItem.totalPrice?.centAmount ?? 0;
    return acc;
  }, 0);
  return currencyFormat(productSubtotal / 100, lineItems?.[0]?.totalPrice?.currencyCode);
};

type ValidateAddressDraft = {
  address?: Address | DistributionChannels_distributionChannels_address | null;
  externalId?: string | null;
  patient?: Patient;
  methodName?: addressType;
};
/**
 * @param array of cart lineItems
 * @returns cart lineItems with summed total price and discount total discount
 * @description used for calculating total price when displaying same sku line
 * items in a single line similar to Optimate
 */
export const combinedPriceLineItem = (lineItems: LineItem[]): LineItem => {
  const priceValueCentAmount = lineItems.reduce((acc, li) => acc + li.price?.value?.centAmount ?? 0, 0);
  const totalPriceCentAmount = lineItems.reduce((acc, li) => acc + (li.totalPrice?.centAmount ?? 0), 0);
  const quantity = lineItems.reduce((acc, li) => acc + (li.quantity ?? 0), 0);

  const totalDiscountCentAmount = lineItems.reduce((acc, li) => acc + (li.discounts?.totalDiscount?.centAmount ?? 0), 0);
  return {
    ...lineItems[0],
    quantity,
    totalPrice: {
      currencyCode: lineItems[0].totalPrice?.currencyCode ?? "",
      centAmount: totalPriceCentAmount,
    },
    price: {
      ...lineItems[0].price,
      value: {
        ...lineItems[0].price?.value,
        centAmount: priceValueCentAmount,
      },
    },
    discounts: {
      ...lineItems[0].discounts,
      totalDiscount: {
        ...lineItems[0].discounts?.totalDiscount,
        currencyCode: lineItems[0].discounts?.totalDiscount?.currencyCode ?? "",
        centAmount: totalDiscountCentAmount,
      },
    } as Cart_cart_lineItems_discounts,
  };
};

export const validateAddressDraft = ({ address, patient, methodName, externalId }: ValidateAddressDraft): addressDraft | null => {
  if (!address || !patient || !methodName) {
    return null;
  }

  const { state, region, country, city, postalCode, streetName, streetNumber } = address;

  if (postalCode && city && country && (state || region) && patient.firstName && patient.lastName && patient.email) {
    return {
      city,
      postalCode,
      streetName,
      streetNumber,
      region: (region || state) as string,
      ...(state ? { state } : {}),
      country,
      email: patient.email,
      phone: patient.mobilePhone,
      mobile: patient.mobilePhone,
      firstName: patient.firstName,
      lastName: patient.lastName,
      additionalAddressInfo: methodName,
      ...(methodName === addressType.clickAndCollectAddress && !!externalId ? { externalId } : {}),
    };
  }

  return null;
};

export const getHealthFundCodes = (productType: string, lineItems: LineItem[]): string[] => {
  //customerGroupHealthFundCode should only on frame and base on customer group. While it exist, all other codes should be null
  if (lineItems[0].variant?.customerGroupHealthFundCode) {
    return [lineItems[0].variant?.customerGroupHealthFundCode];
  }
  switch (productType) {
    case "frame":
      return [...(lineItems[0].variant?.singleHealthFundCode ? [lineItems[0].variant?.singleHealthFundCode] : [])];
    case "lenses": {
      if (lineItems.length === 1) {
        const lensCode = lineItems[0].variant?.singleHealthFundCode;
        const lensAddonCodes = (lineItems[0]?.variant?.singleHealthFundCodeAddOns ?? []).filter((code) => !isNil(code)) as string[];
        return [...(lensCode ? [lensCode] : []), ...lensAddonCodes];
      } else {
        const lensCode = lineItems[0].variant?.multiHealthFundCode;
        const lensAddonCodes = (lineItems[0]?.variant?.multiHealthFundCodeAddOns ?? []).filter((code) => !isNil(code)) as string[];
        return [...(lensCode ? [lensCode] : []), ...lensAddonCodes];
      }
    }
    case "contactLenses": {
      if (lineItems.length === 1) {
        const lensCode = lineItems[0].variant?.singleHealthFundCode;
        return [...(lensCode ? [lensCode] : [])];
      } else {
        const lensCode = lineItems[0].variant?.multiHealthFundCode;
        return [...(lensCode ? [lensCode] : [])];
      }
    }
    default:
      return [];
  }
};

enum LAB_KEYS {
  EOLT = "EOLT",
}

export const isStore = (supplyChannelKey?: string): boolean => !Object.values(LAB_KEYS).includes(supplyChannelKey as LAB_KEYS);

const productNameMap: Record<string, string> = {
  frame: "Frame",
  lenses: "Lenses",
  addons: "Add ons",
  contactLenses: "Contact lenses",
};

export const mapProductToDisplayName = (productType: string): string => {
  const [_key, displayName] = Object.entries(productNameMap).find(([key]) => !!productType.match(key)) ?? [];
  return displayName ?? "Other";
};

export const showCompleteQuote = (bundleLineItems: LineItem[]): boolean => bundleLineItems.some((li) => li?.customFields?.isQuote);

export const getBundleService = (lineItems: LineItem[]): ServiceOption | undefined => {
  const includesLens = lineItems.some((li: LineItem) => li.productType?.key === "lenses");
  const bundleFrame = lineItems.find((li: LineItem) => li.productType?.key === "frame");

  if (includesLens && !!bundleFrame) {
    return serviceOptions.framesCustomised;
  }

  if (includesLens) {
    return serviceOptions.lenses;
  }
};

export const convertLineItemsToDispensingState = (lineItems: LineItem[]): LensBuilderState => {
  const bundleServiceType = getBundleService(lineItems);

  const dispensingState = lineItems.reduce(
    (acc: LensBuilderState, li: LineItem) => {
      if (bundleServiceType === serviceOptions.framesCustomised || bundleServiceType === serviceOptions.lenses) {
        const orderEyeConfig =
          lineItems.filter((li: LineItem) => li.productType?.key === "lenses").length === 2
            ? EYE_OPTION_TYPE.BOTH
            : lineItems.find((li: LineItem) => li.productType?.key === "lenses")?.customFields?.eye;

        if (orderEyeConfig) {
          acc.orderEyeConfig = orderEyeConfig as EYE_OPTION_TYPE;
        }
        const { productType, variant } = li;

        if (productType?.key === "frame" && variant) {
          acc.frameVariant = {
            ...(variant as unknown as Variant),
            // the only time a state line item will have an id is when it is a quote (populated by cart response)
            // so we will use ids to determine if the current lensbuilding state is a quote
            lineItemId: li.id,
            currentPrice: {
              discounted: {
                value: {
                  centAmount: li.totalPrice?.centAmount ?? 0,
                  currencyCode: li.totalPrice?.currencyCode ?? "",
                },
              },
              cashPrice: {
                amount: li.price.value.centAmount,
                currencyCode: li.price.value.currencyCode,
              },
            },
          };
        }
        if (productType?.key === "lenses") {
          const { customFields } = li;
          if (customFields) {
            const lenseEye = customFields.eye === EyeType.LEFT_OS ? EyeType.LEFT_OS : EyeType.RIGHT_OD;

            const lenses = {
              ...acc.lenses,
              [lenseEye]: {
                detail: {
                  ...initialLensBuilderState.lenses[lenseEye].detail,
                  variantName: variant?.name,
                  variantPrice: (li?.totalPrice?.centAmount ?? 0) / 100,
                  prescriptionLensExtra: variant?.name, // used to display lens description in review step
                },
                itemToAdd: {
                  ...initialLensBuilderState.lenses[lenseEye].itemToAdd,
                  ...customFields,
                  lineItemId: li.id,
                  sku: variant?.sku,
                  quantity: li.quantity,
                  supplyChannelKey: li.supplyChannel?.key,
                  distributionChannelKey: li.distributionChannel?.name?.en?.split(" ")[0] ?? "",
                },
              },
            };
            acc.lenses = lenses;
          }
        }

        if (productType?.key === "addons") {
          acc.addOns = [
            ...(acc?.addOns ?? []),
            {
              lineItemId: li.id,
              name: li?.variant?.name ?? "",
              sku: li?.variant?.sku ?? "",
              supplyChannelKey: li?.supplyChannel?.key ?? "",
              price: { amount: (li?.totalPrice?.centAmount ?? 0) / 100, currencyCode: li?.totalPrice?.currencyCode ?? "" },
            },
          ];
        }

        // no requirement to handle contact lenses yet

        acc.service = {
          ...bundleServiceType,
          steps: [LENS_BUILDER_STEP.ENTER_PRESCRIPTION, LENS_BUILDER_STEP.MEASUREMENT, LENS_BUILDER_STEP.REVIEW],
        };
        acc.step = LENS_BUILDER_STEP.ENTER_PRESCRIPTION;
      }

      return acc;
    },
    { ...initialLensBuilderState },
  );

  return dispensingState;
};

export const convertAddToCartDraftToLineItemPrescription = (itemsToAdd: AddToCartDraftWithLineItemId): lineItemPrescriptionDraft => ({
  lineItemId: itemsToAdd.lineItemId as string,
  eye: itemsToAdd.eye,
  sphere: itemsToAdd.sphere,
  cyl: itemsToAdd.cyl,
  axis: itemsToAdd.axis,
  add: itemsToAdd.add,
  power: itemsToAdd.power,
  nearpd: itemsToAdd.nearpd,
  height: itemsToAdd.height,
  pd: itemsToAdd.pd,
  bvd: itemsToAdd.bvd,
  intAdd: itemsToAdd.intAdd,
  hPrism: itemsToAdd.hPrism,
  vPrism: itemsToAdd.vPrism,
  hPrismType: itemsToAdd.hPrismType,
  vPrismType: itemsToAdd.vPrismType,
  lensType: itemsToAdd.lensType,
  genericAdd: itemsToAdd.genericAdd,
});

export const mergeBaseMaterialToBaseLenses = (items: LineItem[]): LineItem[] => {
  const baseMaterial = items.find((item) => item?.variant?.sku === "BNLMATERIAL");

  const newPricing = baseMaterial
    ? items
        .filter((item) => item?.variant?.sku !== "BNLMATERIAL")
        .map((item) => {
          if (item?.variant?.sku?.includes("BNLBASE")) {
            return {
              ...item,
              price: {
                value: {
                  centAmount: (baseMaterial?.price?.value?.centAmount ?? 0) + (item?.price?.value?.centAmount ?? 0),
                  currencyCode: item?.price?.value?.currencyCode ?? "",
                },
                discounted: null,
              },
              discounts: {
                totalDiscount: {
                  currencyCode: item.discounts?.totalDiscount?.currencyCode ?? "",
                  centAmount: (item.discounts?.totalDiscount?.centAmount ?? 0) + (baseMaterial?.discounts?.totalDiscount?.centAmount ?? 0),
                },
                includedDiscounts: [],
              },
              totalPrice: {
                currencyCode: item?.totalPrice?.currencyCode ?? "",
                centAmount: (item?.totalPrice?.centAmount ?? 0) + (baseMaterial?.totalPrice?.centAmount ?? 0),
              },
            };
          }
          return item;
        })
    : items;

  return newPricing;
};
