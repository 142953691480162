import { combineReducers } from "redux";

import appointmentsReducer from "./appointments/slice";
import authenticationReducer from "./authentication/slice";
import availabilityReducer from "./availability/slice";
import contactLensReducer from "./contactLens/slice";
import examReducer from "./exam/slice";
import lensBuilderReducer from "./lensBuilder/slice";
import ordersReducer from "./orders/slice";
import patientReducer from "./patient/slice";

const rootReducer = combineReducers({
  appointments: appointmentsReducer,
  authentication: authenticationReducer,
  availability: availabilityReducer,
  contactLens: contactLensReducer,
  exam: examReducer,
  lensBuilder: lensBuilderReducer,
  orders: ordersReducer,
  patient: patientReducer,
});

export default rootReducer;
