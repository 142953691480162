import { sendSMSDraft } from "./generated";

export enum SALES_TYPE {
  SUBSCRIPTION = "subscription",
  ON_DEMAND = "on-demand",
}

export type CartPrice = {
  subtotal: number;
  discountPercent: number;
  discountAmount: number;
  discountedTotal: number;
};

export enum MODALITY_TYPE {
  DAILY = "daily",
  MONTHLY = "monthly",
  FORTNIGHTLY = "fortnightly",
}

export type StateSmsDetails = Omit<sendSMSDraft, "cartId">;
