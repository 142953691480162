import { DistributionChannels_distributionChannels } from "./generated";

export enum AVAILABILITY_CONFIGURATION_ACTION {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  NONE = "none",
}

export interface AvailabilityConfigurationData {
  index: number;
  data: AvailabilityData[];
}

export interface AvailabilityData {
  id: string;
  startAt: string; // ISO 8601
  endAt: string; // ISO 8601
  peakStartAt?: string;
  peakEndAt?: string;
  optomId?: string;
  distributionChannel: DistributionChannels_distributionChannels;
}

type DCResource<T> = {
  [dcKey: string]: T;
};

type StoredResource<T> = {
  [optomId: string]: DCResource<T>;
};

export type StoredAvailabilityData = StoredResource<AvailabilityData[]>;

export interface OptomAutoCompleteOption {
  id: string;
  label: string;
}
