import { gql, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { AppointmentTypesByCountQueryVariables, AppointmentTypeUpdateRequest } from "../../types";

export const COUNTRY_APPOINTMENT_TYPES = gql`
  query AppointmentTypesByCountQuery($posRetrieveAppointmentTypesByCountryCountryCode: String!) {
    posRetrieveAppointmentTypesByCountry(countryCode: $posRetrieveAppointmentTypesByCountryCountryCode) {
      id
      countryCode
      name
      publicName
      publicSubHeading
      color
      active
      onlineBooking
      bookingConfirmationSMS
      dayBeforeReminderSMS
      dayOfReminderSMS
      requiresPatient
      publicDescription
      durationPeakA
      durationOffPeakA
      durationPeakB
      durationOffPeakB
      durationPeakC
      durationOffPeakC
      distributionChannels {
        key
      }
      onlinePosition
    }
  }
`;

export interface AppointmentTypeData extends AppointmentTypeUpdateRequest {
  countryCode: string;
  distributionChannels?: { key: string }[];
}

export interface AppointmentTypesByCountQueryResponse {
  posRetrieveAppointmentTypesByCountry: (AppointmentTypeData | null)[] | null;
}

interface ExtendedQueryResult extends Pick<QueryResult<AppointmentTypesByCountQueryResponse>, "data" | "loading" | "error" | "variables"> {
  getAppointmentTypes: () => void;
}

export const useStoreAppointmentTypesQuery = (
  posRetrieveAppointmentTypesByCountryCountryCode: string,
): QueryResult<AppointmentTypesByCountQueryResponse, AppointmentTypesByCountQueryVariables> =>
  useQuery<AppointmentTypesByCountQueryResponse, AppointmentTypesByCountQueryVariables>(COUNTRY_APPOINTMENT_TYPES, {
    variables: { posRetrieveAppointmentTypesByCountryCountryCode },
    onError: () => void 0,
    skip: !posRetrieveAppointmentTypesByCountryCountryCode,
  });

export const useStoreAppointmentTypesLazyQuery = (posRetrieveAppointmentTypesByCountryCountryCode: string): ExtendedQueryResult => {
  const [getAppointmentTypes, { data, error, loading, variables }] = useLazyQuery<
    AppointmentTypesByCountQueryResponse,
    AppointmentTypesByCountQueryVariables
  >(COUNTRY_APPOINTMENT_TYPES, {
    variables: { posRetrieveAppointmentTypesByCountryCountryCode },
    onError: () => void 0,
    fetchPolicy: "network-only",
  });
  return {
    getAppointmentTypes,
    data,
    error,
    loading,
    variables,
  };
};
