import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { ListGlassesPrescriptions, ListGlassesPrescriptionsVariables } from "../../types";
import { GLASSES_PRESCRIPTION_FIELDS } from "../fragments";

export const LIST_GLASSES_PRESCRIPTIONS = gql`
  ${GLASSES_PRESCRIPTION_FIELDS}
  query ListGlassesPrescriptions($patientId: ID!) {
    listGlassesPrescriptions(patientId: $patientId) {
      ...GlassesPrescriptionFields
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<ListGlassesPrescriptions>, "data" | "loading" | "error"> {
  listGlassesPrescriptions: ({ variables }: { variables: ListGlassesPrescriptionsVariables }) => void;
  called: boolean;
}

export const useGlassesPrescriptionsQuery = (): ExtendedQueryResult => {
  const [listGlassesPrescriptions, { data, error, loading, called }] = useLazyQuery<ListGlassesPrescriptions, ListGlassesPrescriptionsVariables>(
    LIST_GLASSES_PRESCRIPTIONS,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  return {
    listGlassesPrescriptions,
    data,
    error,
    loading,
    called,
  };
};
