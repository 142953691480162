const appointmentTypes: Record<string, string> = {
  "Appointment extension": "#90A4AE",
  "Appointment placeholder": "#E6E6E6",
  Break: "#90A4AE",
  "Brief / Follow-up eye exam": "#6DD400",
  "Contact Lens (Aftercare/Final RX)": "#0091FF",
  "Contact Lens (Long)": "#32C5FF",
  "Contact Lens (Short)": "#32C5FF",
  "Cancelled by patient": "#E02020",
  "Cancelled by store": "#E02020",
  "Comprehensive eye exam + OPTOS": "#14AC8A",
  "Comprehensive eye exam + VFE": "#14AC8A",
  "Comprehensive eye exam + Contact lens fit": "#6236FF",
  "Comprehensive eye exam": "#14AC8A",
  "Contact Lens demo": "#6236FF",
  "Contact Lens (Online)": "#6236FF",
  "Contact lens demo": "#6236FF",
  "Dilatation exam (DFE)": "#0091FF",
  Lunch: "#90A4AE",
  "OPTOS only": "#B620E0",
  "Prescription check": "#6DD400",
  "Private comprehensive eye exam ($66)": "#B620E0",
  "Private prescription check ($33)": "#B620E0",
  "Problem resolution": "#F7B500",
  "Red eye": "#0091FF",
  "Sales rep": "#F7B500",
  "Visual field exam (VFE)": "#0091FF",
};

const orderStatus: Record<string, string> = {
  "Sent to Supplier": "#33BBFF",
  Default: "#90A4AE",
};

export const COLOURS = {
  ACTIVE_BLUE: "#3F51B5",
  APPOINTMENT_STATUS: "#1976d2",
  APPOINTMENT_TYPES: appointmentTypes,
  ORDER_STATUS: orderStatus,
  BLACK: "#000000",
  BLACK_OPAQUE_04: "rgba(0,0,0,0.04)",
  BLACK_OPAQUE_12: "rgba(0,0,0,0.12)",
  BLACK_OPAQUE_22: "rgba(0,0,0,0.22)",
  BLACK_OPAQUE_38: "rgba(0,0,0,0.38)",
  BLACK_OPAQUE_54: "rgba(0,0,0,0.54)",
  DEFAULT_APPOINTMENT_COLOUR: "#14AC8A",
  DISABLED: "rgba(0,0,0,0.26)",
  EXTRA_LIGHT_BG: "#FAFAFA",
  GREEN: "#4CAF50",
  GREY: "#808080",
  LAPIS_LAZULI: "#2D70B2",
  LIGHTER_GREY: "rgba(232, 232, 232, 0.3)",
  LIGHT_ACTIVE_BLUE: "rgba(63,81,181, 0.08)",
  LIGHT_BLUE: "#E7F3FF",
  LIGHT_GREEN: "rgba(129,199,132,0.5)",
  LIGHT_GREY: "#E8E8E8",
  LIGHT_GREY_HEADER: "#F3F3F3",
  MID_GREY: "#d7d7d7",
  MUI_EMPHASISED_BG: "rgba(63, 81, 181, 0.08)",
  MUI_HOVER_BG: "rgba(63, 81, 181, 0.04)",
  NAVY: "#0D3C61",
  SECONDARY_RED: "#f50057",
  SKY_BLUE: "#ECF7FF",
  TEXTFIELD_BG: "rgba(0,0,0,0.04)",
  TEXT_PRIMARY: "rgba(0,0,0,0.87)",
  TEXT_SECONDARY: "rgba(0,0,0,0.60)",
  VIOLET_PRIMARY_LIGHT: "#7986cb",
  WHITE: "#FFFFFF",
};
