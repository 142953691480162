import { gql, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { CATEGORY_SLUG, SearchProductsQuery, SearchProductsQueryVariables } from "../../types";
import { SEARCH_PRODUCT_FIELDS } from "../fragments";

export const SEARCH_PRODUCTS = gql`
  ${SEARCH_PRODUCT_FIELDS}
  query SearchProductsQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFields
      }
    }
  }
`;

export const useSearchProductsQuery = (
  searchCategorySlug: CATEGORY_SLUG,
  queryString: string,
): QueryResult<SearchProductsQuery, SearchProductsQueryVariables> =>
  useQuery<SearchProductsQuery, SearchProductsQueryVariables>(SEARCH_PRODUCTS, {
    variables: { searchCategorySlug: searchCategorySlug, queryString: queryString },
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

interface ExtendedQueryResult extends Pick<QueryResult<SearchProductsQuery>, "data" | "loading" | "error" | "variables"> {
  searchProducts: ({ variables }: { variables: SearchProductsQueryVariables }) => void;
  called: boolean;
}

export const useLazySearchProductsQuery = (handleOnComplete?: (data: SearchProductsQuery) => void): ExtendedQueryResult => {
  const [searchProducts, { data, error, loading, called, variables }] = useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SEARCH_PRODUCTS,
    {
      onError: () => void 0,
      fetchPolicy: "no-cache",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    searchProducts,
    data,
    error,
    loading,
    called,
    variables,
  };
};
