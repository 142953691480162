import invert from "invert-color";

export const getInvertTextColor = (hex: string): string => {
  try {
    return invert(hex, { black: "#000000", white: "#ffffff", threshold: 0.4 });
  } catch {
    return "#ffffff";
  }
};

export const getInvertStatusTextColor = (hex: string): string => {
  try {
    return invert(hex);
  } catch {
    return "#ffffff";
  }
};
