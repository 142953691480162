import styled from "@emotion/styled";
import React, { FC } from "react";

import { TEST_DATA_IDS } from "../constants";

const Container = styled.div`
  height: 100%;
`;

type BNBRandProps = {
  color?: string;
};

const BNBrand: FC<BNBRandProps> = ({ color = "#fff" }) => (
  <Container data-testid={TEST_DATA_IDS.SIDEBAR_BRAND_LOGO}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500.3 702.58" fill={color}>
      <path d="M420.53,346.31c15.22-12.25,24.49-29.69,24.49-52,0-43.43-35.26-73.12-79-73.12H263.53V481H373.76c44.91,0,80.92-30.81,80.92-75.35C454.68,378.6,441.31,358.19,420.53,346.31Zm-105.78-77.2H366c16.33,0,27.84,12.25,27.84,28.58S381.93,326.27,366,326.27H314.75Zm59,164.05h-59V371.92h59c17.45,0,30.07,13,30.07,30.43C403.83,420.17,391.21,433.16,373.76,433.16Zm825.55-52.88L1086.38,219.53h-39.53V483.05h52V321.93l112.93,161.12h39.53V219.53h-52Zm198.1-277.39A351.3,351.3,0,0,0,798.65,325.62h-97a351.27,351.27,0,1,0,0,51.34h97a351.29,351.29,0,1,0,598.76-274.07ZM351.29,651.24c-165.4,0-300-134.55-300-299.95s134.55-300,300-300,300,134.56,300,300S516.69,651.24,351.29,651.24Zm797.72,0c-165.39,0-300-134.55-300-299.95s134.56-300,300-300,300,134.56,300,300S1314.41,651.24,1149,651.24Z" />
    </svg>
  </Container>
);

export default BNBrand;
