import styled from "@emotion/styled";
import isNil from "lodash/isNil";
import { isMobileOnly } from "mobile-device-detect";
import React, { FC, useEffect } from "react";

import { SIZES } from "../constants";
import { useAppSelector } from "../state/hooks";
import { selectActivePatient } from "../state/patient";
import { AUTH_KEYS } from "../types";
import Body from "./Body";
import Header from "./Header";
import SideBar from "./SideBar";

const AppContainer = styled.div`
  height: 100%;
  position: relative;
  min-height: 100vh;
  background-color: white;
  display: flex;
  min-width: calc(100vw - ${SIZES.SIDEBAR_WIDTH}rem);
  ${() =>
    isMobileOnly &&
    `
    width: 100vw;
  * > ::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    height: 0px;
    width: 0px;
  }`}
`;

const HeaderPosition = styled.div`
  position: absolute;
  top: 0;
  height: ${SIZES.HEADER_HEIGHT}rem;
  width: calc(100vw - ${SIZES.SIDEBAR_WIDTH}rem);
`;

const BodyPosition = styled.div`
  position: absolute;
  top: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  ${() =>
    !isMobileOnly &&
    `
    top: ${SIZES.HEADER_HEIGHT}rem;
    height: calc(100vh - ${SIZES.HEADER_HEIGHT}rem);
    width: calc(100vw - ${SIZES.SIDEBAR_WIDTH}rem);
  `}
  overflow-y: scroll;
`;

const ContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  ${() =>
    !isMobileOnly &&
    `
    left: ${SIZES.SIDEBAR_WIDTH}rem;
    width: calc(100vw - ${SIZES.SIDEBAR_WIDTH}rem);
  `}
`;

const App: FC = () => {
  const activePatient = useAppSelector(selectActivePatient);
  useEffect(() => {
    // purge auth tokens on app refresh
    if (activePatient?.id !== sessionStorage.getItem(AUTH_KEYS.PATIENT_ID)) {
      if (isNil(activePatient)) {
        sessionStorage.removeItem(AUTH_KEYS.PATIENT_AUTH_TOKEN);
        sessionStorage.removeItem(AUTH_KEYS.PATIENT_REFRESH_TOKEN);
        sessionStorage.removeItem(AUTH_KEYS.PATIENT_AUTH_EXPIRES);
        sessionStorage.removeItem(AUTH_KEYS.PATIENT_ID);
      } else {
        sessionStorage.setItem(AUTH_KEYS.PATIENT_ID, activePatient.id);
      }
    }
  }, [activePatient]);

  return (
    <AppContainer>
      {!isMobileOnly && <SideBar />}
      <ContentPanel>
        {!isMobileOnly && (
          <HeaderPosition>
            <Header />
          </HeaderPosition>
        )}
        <BodyPosition>
          <Body />
        </BodyPosition>
      </ContentPanel>
    </AppContainer>
  );
};

export default App;
