import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import combinedReducers from "./reducers";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
export const store = configureStore({
  reducer: combinedReducers,
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
