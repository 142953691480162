import {
  addressDraft,
  addToCartDraft,
  EyeType,
  LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice,
  LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted,
  SearchProductsQuery_search_products,
  SearchProductsQuery_search_products_variants,
  SearchProductsQuery_search_products_variants_currentPrice_cashPrice,
  SearchProductsQuery_search_products_variants_currentPrice_discounted,
  SearchProductsQuery_search_products_variants_stocks,
} from "./generated";

export enum SPHERE_TYPE {
  SPHERIC = "spheric",
  TORIC = "toric",
  MULTI_FOCAL = "multifocal",
  MULTI_FOCAL_TORIC = "multifocal-toric",
}

export enum CATEGORY_SLUG {
  CONTACT_LENSES = "contact-lenses",
  LENSES = "lenses",
  SERVICES = "services",
  FRAME = "frame",
  ADD_ON = "addons",
  ACCESSORIES = "accessories",
}

export type CurrentPrice = {
  cashPrice:
    | SearchProductsQuery_search_products_variants_currentPrice_cashPrice
    | LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice
    | null;
  discounted:
    | SearchProductsQuery_search_products_variants_currentPrice_discounted
    | LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted
    | null;
};

export type Stock = SearchProductsQuery_search_products_variants_stocks;

export interface Variant extends Omit<SearchProductsQuery_search_products_variants, "currentPrice"> {
  sku: string;
  name: string;
  sphere: SPHERE_TYPE;
  cyl: string[];
  axis: string[];
  add: string[];
  power: string[];
  modality: string;
  daysUsage: string;
  currentPrice: CurrentPrice;
  supplyChannelKey?: string;
  stocks: Stock[];
  sunOpt?: boolean;
  lineItemId?: string;
  lensSKUs?: [];
}

export interface Product extends Omit<SearchProductsQuery_search_products, "variants"> {
  variants: Variant[];
}

export type DataProduct = {
  name: string;
  key: string;
  variants: Variant[];
};

export interface AddToCartDraftWithLineItemId extends addToCartDraft {
  lineItemId?: string;
}

export interface SelectedProductItemToAdd {
  itemToAdd: AddToCartDraftWithLineItemId;
}

export interface ContactLensSelectedProduct extends SelectedProductItemToAdd {
  productFamily: string;
  productName?: string;
  variant?: Variant;
}

export type ContactLensSelectedProducts = Record<EyeType, ContactLensSelectedProduct>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type TransformKVPair = {
  key: string;
  value: string;
};

export type Address = Omit<addressDraft, "saveToProfile" | "title" | "firstName" | "lastName" | "phone" | "mobile" | "email">;

export interface PatientBase {
  id: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  access_token: string;
  refresh_token: string;
  expiresAt: string;
}

export interface Patient extends PatientBase {
  age?: string;
  consent?: boolean;
  dateOfBirth: string;
  externalId?: string;
  gender?: string;
  healthfundName?: string;
  landline?: string;
  marketingEmailsOptin?: boolean;
  middleName?: string;
  notes?: string | null;
  postalAddress?: Address | string;
  preferredName?: string;
  workNumber?: string;
  medicareExpiry?: string; //mm-yyyy
  medicare?: string; // 10 digits
  medicareReference?: string; //1 digit
  isMedicareValidated?: boolean;
  privateHealthFundCode?: string | null;
  privateHealthFundMemberNumber?: string | null;
  privateHealthFundMemberReference?: string | null;
}

export interface PatientPayload {
  firstName: string;
  lastName?: string;
  preferredName?: string;
  mobilePhone?: string;
  email?: string;
  dateOfBirth?: string;
  marketingEmailsOptin?: boolean;
  landline?: string;
  workNumber?: string;
  medicareExpiry?: string; //mm-yyyy
  medicare?: string; // 10 digits
  medicareReference?: string; //1 digit
  isMedicareValidated?: boolean;
}

export interface CustomerOrder {
  distributionChannelKey: string | null;
  batchNumber: string | null;
  createdAt: string | null;
}

export interface ReplenishmentOrder {
  essilorStoreCode: string | null;
  batchNumber: string | null;
  date: string | null;
}
