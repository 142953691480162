import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { RetrieveBulkCustomerOrder, RetrieveBulkCustomerOrderVariables } from "../../types";

export const BULK_CUSTOMER_ORDERS = gql`
  query RetrieveBulkCustomerOrder($distributionChannelKey: String!) {
    posRetrieveBulkCustomerOrders(distributionChannelKey: $distributionChannelKey) {
      distributionChannelKey
      batchNumber
      createdAt
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<RetrieveBulkCustomerOrder>, "data" | "loading" | "error" | "variables"> {
  getBulkCustomerOrders: ({ variables }: { variables: RetrieveBulkCustomerOrderVariables }) => void;
  called: boolean;
}

export const useRetrieveBulkCustomerOrderLazyQuery = (): ExtendedQueryResult => {
  const [getBulkCustomerOrders, { data, error, loading, called, variables }] = useLazyQuery<
    RetrieveBulkCustomerOrder,
    RetrieveBulkCustomerOrderVariables
  >(BULK_CUSTOMER_ORDERS, {
    onError: () => void 0,
  });

  return {
    getBulkCustomerOrders,
    data,
    error,
    loading,
    called,
    variables,
  };
};
