import { CalendarProps, Event } from "react-big-calendar";

import {
  AppointmentSet,
  AppointmentStatus,
  DistributionChannels_distributionChannels,
  PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments,
  PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_appointmentType,
} from "./generated";
import { Patient } from "./shared";

export type DnDEvent = {
  end: Date | string;
  event: Event;
  start: Date | string;
  resourceId?: number | string | null;
};

export enum APPOINTMENT_VIEWS {
  DAY = "day",
  TWENTY_MINUTE = "20minute",
  THREE_DAY = "3day",
}

export enum VIEWS {
  AGENDA = "agenda",
  DAY = "day",
  MONTH = "month",
  WEEK = "week",
  WORK_WEEK = "work_week",
}

export type SelectAction = "select" | "click" | "doubleClick";

export enum APPOINTMENT_SOURCE_EXTENDED {
  ONLINE = "ONLINE",
  OTHER = "OTHER",
  TEMP = "TEMP",
}

export enum TYPE_INTERNAL_NAME {
  BREAK = "BREAK",
  BRIEF_EXAM = "BRIEF_EXAM",
  CANCELLED_BY_PATIENT = "CANCELLED_BY_PATIENT",
  CANCELLED_BY_STORE = "CANCELLED_BY_STORE",
  CL_FINAL_RX_CREATION = "CL_FINAL_RX_CREATION",
  CL_LONG = "CL_LONG",
  CL_ONLINE_BOOKING = "Contact Lens online booking",
  CL_SHORT = "CL_SHORT",
  COMPREHENSIVE_EXAM = "COMPREHENSIVE_EXAM",
  COMPREHENSIVE_EYE_EXAM_VFE = "COMPREHENSIVE_EYE_EXAM_VFE",
  COMPREHENSIVE_EYE_EXAM_WITH_CL_FIT = "COMPREHENSIVE_EYE_EXAM_WITH_CL_FIT",
  COMPREHENSIVE_EYE_EXAM_WITH_OPTOS = "COMPREHENSIVE_EYE_EXAM_WITH_OPTOS",
  CL_DEMO = "CL_DEMO",
  DILATION_EYE_EXAM = "DILATION_EYE_EXAM",
  NO_SHOW = "NO_SHOW",
  OPTOS_ONLY = "OPTOS_ONLY",
  PLACEHOLDER = "PLACEHOLDER",
  PRESCRIPTION_CHECK_ONLY = "PRESCRIPTION_CHECK_ONLY",
  PRIVATE_EYE_EXAM = "PRIVATE_EYE_EXAM",
  PRIVATE_EYE_EXAM_WITH_OPTOS = "PRIVATE_EYE_EXAM_WITH_OPTOS",
  PRIVATE_PRESCRIPTION_CHECK = "PRIVATE_PRESCRIPTION_CHECK",
  PROBLEM_RESOLUTION = "PROBLEM_RESOLUTION",
  RED_EYE = "RED_EYE",
  SALES_REP = "SALES_REP",
  TURNED_AWAY = "TURNED_AWAY",
  VISUAL_FIELD_EXAMINATION = "VISUAL_FIELD_EXAMINATION",
}

export enum TYPE_EXTERNAL_NAME {
  BREAK = "Break",
  BRIEF_EXAM = "Brief eye exam / follow up eye exam",
  CANCELLED_BY_PATIENT = "Cancelled by patient",
  CANCELLED_BY_STORE = "Cancelled by store",
  CL_DEMO = "Contact lens demo",
  CL_FINAL_RX_CREATION = "CL final Rx creation",
  CL_LONG = "CL long app",
  CL_ONLINE_BOOKING = "Contact Lens online booking",
  CL_SHORT = "CL short app",
  COMPREHENSIVE_EXAM = "Comprehensive eye exam",
  COMPREHENSIVE_EYE_EXAM_VFE = "Comprehensive eye exam with VFE",
  COMPREHENSIVE_EYE_EXAM_WITH_CL_FIT = "Comprehensive eye exam with contact lens fit",
  COMPREHENSIVE_EYE_EXAM_WITH_OPTOS = "Comprehensive eye exam with Optos",
  DILATION_EYE_EXAM = "Dilation eye exam",
  NO_SHOW = "No show appointment",
  OPTOS_ONLY = "Optos only",
  PLACEHOLDER = "Appointment place holder",
  PRESCRIPTION_CHECK_ONLY = "Prescription check only",
  PRIVATE_EYE_EXAM = "Private eye exam($66)",
  PRIVATE_EYE_EXAM_WITH_OPTOS = "Private eye exam with Optos($116)",
  PRIVATE_PRESCRIPTION_CHECK = "Private prescription check only ($33)",
  PROBLEM_RESOLUTION = "Problem resolution",
  RED_EYE = "Red eye",
  SALES_REP = "Sales Rep",
  TURNED_AWAY = "Patient turned away (must leave notes in file)",
  VISUAL_FIELD_EXAMINATION = "Visual field examination",
}

export interface AppointmentType
  extends Omit<PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments_appointmentType, "publicDescription" | "publicName"> {
  publicName?: string;
  publicDescription?: string;
}

export type Optom = {
  id: string;
  lastName: string;
  firstName?: string;
  email?: string;
  appointmentSet?: AppointmentSet;
  appointmentTypes?: AppointmentType[];
};

// TODO: change the name of this type to Optometrist whereever Optom is used
// also change all optom --> optometrist
export type Optometrist = Optom;

export type Resource = {
  id: string;
  distributionChannelKey: string;
  optom: Optometrist;
  source: APPOINTMENT_SOURCE_EXTENDED;
  status: AppointmentStatus;
  type: AppointmentType;
  patient?: Patient;
  notes?: string;
};

// TODO: Change Resource type above, to be the same as this which
// is consistent with the type in the graphql query
export type ResponseAppointment = {
  id: string;
  source: APPOINTMENT_SOURCE_EXTENDED;
  status: AppointmentStatus;
  startAt: string;
  endAt: string;
  distributionChannel: DistributionChannels_distributionChannels;
  optometrist: Optometrist;
  appointmentType: AppointmentType;
  patient?: Patient;
  notes?: string;
};

// DnDEvent and Calendar event dates are not the same
export interface Appointment extends Omit<Event, "start" | "end"> {
  end: Date | string;
  resource: Resource; // TODO: I named this before I knew resource in RBC meant optom, whereas we mean resource is an Appointment
  resourceId: string;
  start: Date | string;
  edit?: boolean;
}

type TimeslotBounds = {
  bottom: number;
  left: number;
  right: number;
  top: number;
  x: number;
  y: number;
};

export type Timeslot = {
  action: SelectAction;
  end: string | Date;
  start: string | Date;
  bounds?: TimeslotBounds;
  resourceId?: string | number;
  slots?: (string | Date)[];
};

export type SetActiveAppointmentPayload = {
  appointment?: Partial<Appointment> | Appointment;
  timeslot?: Timeslot;
};

export interface SetStoredAppointmentsPayload extends PosRetrieveStoreAppointmentsQuery_posRetrieveStoreAppointments {
  edit?: boolean;
}

// consistent with rbc lib types
// eslint-disable-next-line @typescript-eslint/ban-types
export type DnDCalendarComponent = React.ComponentType<CalendarProps<object, object>>;

export enum AUTH_KEYS {
  ANON_ACCESS_TOKEN = "AnonAccessToken",
  ANON_ACCESS_TOKEN_EXPIRES = "AnonAccessTokenExpires",
  ANON_REFRESH_TOKEN = "AnonRefreshToken",
  LD_USER_KEY = "LDUserKey",
  STAFF_AUTH_EXPIRES = "StaffAuthExpires",
  STAFF_AUTH_TOKEN = "StaffAuthToken",
  STORE_AUTH_EXPIRES = "StoreAuthExpires",
  STORE_AUTH_TOKEN = "StoreAuthToken",
  PATIENT_AUTH_TOKEN = "PatientAuthToken",
  PATIENT_REFRESH_TOKEN = "PatientRefreshToken",
  PATIENT_AUTH_EXPIRES = "PatientAuthExpires",
  PATIENT_ID = "PatientId",
}

export type Availability = {
  id: string;
  endAt: string;
  startAt: string;
  peakStartAt?: string;
  peakEndAt?: string;
  optometrist: Optom;
  distributionChannel: DistributionChannels_distributionChannels;
};

type DateResource<T> = {
  [date: string]: T;
};

type StoredResource<T> = {
  [dcKey: string]: DateResource<T>;
};

export type StoredAvailabilities = StoredResource<Availability[]>;
export type StoredAppointments = StoredResource<Appointment[]>;
