import { DateTime, Interval } from "luxon";
import { Event } from "react-big-calendar";

import { Timeslot } from "../types";

export enum DateTimeFormat {
  CALENDAR_TIME = "h:mm a",
  CALENDAR_TIME_SHORT = "h:mm",
  DAY_NUMBER = "d",
  EXAM_DATE = "ccc, dd MMMM",
  DOB = "dd-MM-yyyy",
  LOCAL_DAY = "yyyy-MM-dd",
  LONG_DATE = "EEEE d MMM yyyy",
  LONG_DATE_TIME = "d MMM yyyy h:mm a",
  LONG_MONTH = "LLLL",
  LONG_DAY = "EEEE",
  MINUTE = "m",
  PRESCRIPTION_DATE = "dd/MM/yyyy",
  SHORT_DAY = "ccc",
  SHORTER_DAY = "ccccc",
  STANDARD_DATE = "MMM dd",
  AVAILABLITY_DAY = "dd MMM yyyy",
  MEDICARE_DATE = "MM/yy",
  ORDER_PLACED_DATE = "d MMM yyyy",
}

/**
 * Perform date time operations on native JS Dates using luxon functions.
 * The timezone of the DateTime object is set globally in App.tsx and updated by changes
 * to authentication.activeDistributionChannel using luxons's Settings.defaultZone.
 * https://moment.github.io/luxon/#/zones?id=changing-the-default-zone
 */

/**
 * FORMATTING
 */

export const formattedlocalDateTime = (date: Date, format: DateTimeFormat): string => {
  if (!date) {
    return "";
  }
  return DateTime.fromJSDate(new Date(date)).toFormat(format);
};

export const localDate = (date: Date): Date => {
  if (!date) {
    return DateTime.local().toJSDate();
  }
  return DateTime.fromJSDate(new Date(date)).toJSDate();
};

export const localLuxDateTime = (date: Date): DateTime => {
  if (!date) {
    return DateTime.local();
  }
  return DateTime.fromJSDate(date);
};

/**
 * Returns a localised string of the date in the format yyyy-MM-dd
 * Eg "2021-10-28T01:00:00.000Z"
 */
export const localISOString = (date: Date | null | undefined): string => {
  if (!date) {
    return "";
  }
  return DateTime.fromJSDate(new Date(date)).toUTC().toISO();
};

/**
 * Returns a localised string of the date in the format EEEE, d MMMM
 * Eg "Monday, 1 January"
 */
export const localLongDate = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.LONG_DATE);

/**
 * Returns a localised string of the date in the format EEEE
 * Eg "Monday"
 */
export const localLongDay = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.LONG_DAY);

/**
 * Returns a localised string of the date in the format LLLL
 * Eg "January"
 */
export const localLongMonth = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.LONG_MONTH);

/**
 * Returns a localised string of the date in the format h:mm a
 * Eg "3:45 PM"
 */
export const localCalendarTime = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.CALENDAR_TIME);
/**
 * Returns a localised string of the date in the format h:mm a
 * Eg "3:45 "
 */
export const localShortCalendarTime = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.CALENDAR_TIME_SHORT);

/**
 * Returns a localised string of the date in the format ccc
 * Eg "Sat"
 */
export const localShortDay = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.SHORT_DAY);

/**
 * Returns a localised string of the date in the format ccccc
 * Eg "S"
 */
export const localShorterDay = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.SHORTER_DAY);

/**
 * Returns a localised string of the date in the format d
 * Eg "8"
 */
export const localDayNumber = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.DAY_NUMBER);

/**
 * Returns a localised string of the date in the format dd LLL yyyy
 * Eg "15 Nov 2021"
 */
export const localAvailabilityDay = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.AVAILABLITY_DAY);

/**
 * Returns a localised string of start and end dates of an appointment
 * Eg  "3:45 PM - 4:30 PM"
 */
export const localTimeRange = (event?: Event): string =>
  event?.start && event?.end ? `${localShortCalendarTime(event.start)} - ${localCalendarTime(event.end)}` : "";

/**
 * Returns a localised string with the long date format of the start and end dates of an appointment
 * Eg "Saturday, 1 May 3:45 PM - 4:30 PM"
 */
export const localLongTimeRange = (timeslot?: Timeslot): string =>
  timeslot?.start && timeslot?.end
    ? `${localLongDate(new Date(timeslot.start))} ${localCalendarTime(new Date(timeslot.start))} - ${localCalendarTime(new Date(timeslot.end))}`
    : "";

/**
 * Returns a localised string of the date in the format dd/MM/yyyy
 * Eg "25/12/1985"
 */
export const localPrescriptionDate = (date: Date | null | undefined): string => {
  if (!date) {
    return "";
  }
  return formattedlocalDateTime(date, DateTimeFormat.PRESCRIPTION_DATE);
};

/**
 * Returns a localised string of the date in the format dd-MM-yyyy
 * Eg "25-12-1985"
 */
export const dobFormat = (date: Date | null | undefined): string => {
  if (!date) {
    return "";
  }
  return formattedlocalDateTime(date, DateTimeFormat.DOB);
};

/**
 * Returns a localised string of the date in the format ccc, dd MMMM
 * Eg "Wed, 08 September"
 */
export const localExamDate = (date: Date | null | undefined): string => {
  if (!date) {
    return "";
  }
  return formattedlocalDateTime(date, DateTimeFormat.EXAM_DATE);
};

export const localMedicareDate = (date: Date): string => formattedlocalDateTime(date, DateTimeFormat.MEDICARE_DATE);

/**
 * Returns a localised string of the date in the format yyyy-MM-dd
 * Format required by the API for startDate and endDate
 * Used to key appointments and availablities by date in state
 * Eg "2021-12-25"
 */
export const localShortDate = (date: Date | null | undefined): string => {
  if (!date) {
    return "";
  }
  return formattedlocalDateTime(date, DateTimeFormat.LOCAL_DAY);
};

/**
 * MATHS
 * Helpers that are timezone agnostic like min and max, don't use luxon DateTime
 */

export const addYears = (date: Date, years: number): Date => localLuxDateTime(new Date(date)).plus({ years }).toJSDate();
export const addDays = (date: Date, days: number): Date => localLuxDateTime(new Date(date)).plus({ days }).toJSDate();
export const addHours = (date: Date, hours: number): Date => localLuxDateTime(new Date(date)).plus({ hours }).toJSDate();
export const addMinutes = (date: Date, minutes: number): Date => localLuxDateTime(new Date(date)).plus({ minutes }).toJSDate();
export const addSeconds = (date: Date, seconds: number): Date => localLuxDateTime(new Date(date)).plus({ seconds }).toJSDate();

/**
 * Returns an array of JS Dates between two dates.
 *
 * @param startDate The start date
 * @param endDate The end date
 */

type DateRange = {
  start: Date;
  end: Date;
};

// adding a day to the end date to include the end date in the range
// consistent with date-fns intervals
export const eachLocalDayOfInterval = ({ start, end }: DateRange): Date[] =>
  Interval.fromDateTimes(localLuxDateTime(start), localLuxDateTime(addDays(end, 1)))
    .splitBy({ days: 1 })
    .map((d: Interval) => d.start.startOf("day").toJSDate());

export const isLocalSameDay = (date1: Date, date2: Date): boolean => localLuxDateTime(date1).hasSame(localLuxDateTime(date2), "day");
export const isLocalSameWeek = (date1: Date, date2: Date): boolean => localLuxDateTime(date1).hasSame(localLuxDateTime(date2), "week");

export const localStartOfDay = (date: Date): Date => localLuxDateTime(date).startOf("day").toJSDate();

/**
 * Is the first date after the second date
 * Is not timezone dependant, but providing a wrapper with an interface similar to other date functions.
 */
export const isAfter = (date1: Date, date2: Date): boolean => localLuxDateTime(date1) > localLuxDateTime(date2);
export const isBefore = (date1: Date, date2: Date): boolean => localLuxDateTime(date1) < localLuxDateTime(date2);
export const isWithinLocalDaysInterval = (date: Date, { start, end }: DateRange): boolean =>
  Interval.fromDateTimes(localLuxDateTime(start), localLuxDateTime(addSeconds(end, 1))).contains(localLuxDateTime(date));

export const gte = (date1: Date, date2: Date): boolean => localLuxDateTime(date1).toMillis() >= localLuxDateTime(date2).toMillis();

export const lte = (date1: Date, date2: Date): boolean => localLuxDateTime(date1).toMillis() <= localLuxDateTime(date2).toMillis();

export const isWithinLocalDateTimeRange = (date: Date, { start, end }: DateRange): boolean => gte(date, start) && lte(date, end);
export const isWithinLocalTimeOfDayRange = (date: Date, { start, end }: DateRange): boolean =>
  (localLuxDateTime(date).hour - localLuxDateTime(start).hour) % 24 >= 0 && (localLuxDateTime(date).hour - localLuxDateTime(end).hour) % 24 <= 0;

export const max = (dates: Date[]): Date => {
  if (!dates || dates.length === 0) {
    return new Date();
  }
  return dates.reduce((max, date) => (max > date ? max : date));
};

export const min = (dates: Date[]): Date => {
  if (!dates || dates.length === 0) {
    return new Date();
  }
  return dates.reduce((min, date) => (min < date ? min : date));
};

export const differenceInCalendarDays = (date1: Date, date2: Date): number => {
  const d1 = localLuxDateTime(date1);
  const d2 = localLuxDateTime(date2);
  return Math.ceil(d1.diff(d2, "days").as("days"));
};

/**
 * Returns the number of complete years between two dates
 * used for calculating the age of a patient
 * where we do not want to include partial years by convention
 */
export const differenceInYears = (date1: Date, date2: Date): number => {
  const d1 = localLuxDateTime(date1);
  const d2 = localLuxDateTime(date2);
  return Math.floor(d1.diff(d2, "years").as("years"));
};

export const differenceInMinutes = (date1: Date, date2: Date): number => {
  const d1 = localLuxDateTime(date1);
  const d2 = localLuxDateTime(date2);
  return Math.floor(d1.diff(d2, "minutes").as("minutes"));
};

/**
 * UTILS
 */

export const isValid = (date?: Date | string | unknown): boolean => {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).isValid;
  }
  if (typeof date === "string") {
    return DateTime.fromISO(date).isValid;
  }
  return false;
};

export const getStartDateOfTheWeek = (date: Date | null | undefined): string => {
  if (date) {
    if (localLuxDateTime(date).weekday === 7) {
      return localLuxDateTime(date).toISODate();
    } else {
      return localLuxDateTime(date).startOf("week").minus({ day: 1 }).toISODate();
    }
  }
  return "";
};

//in order to make sure the last day is Saturday, get startDate first and plus 6 days if it's sunday, on the other hand
export const getEndDateOfTheWeek = (date: Date | null | undefined): string => {
  if (date) {
    if (localLuxDateTime(date).weekday === 7) {
      return localLuxDateTime(date).plus({ day: 6 }).toISODate();
    } else {
      return localLuxDateTime(date).startOf("week").plus({ day: 5 }).toISODate(); //luxon.startOf("week") will aways return monday of that week, therefore add 5
    }
  }

  return "";
};

export const getStartDateOfTheMonth = (date: Date | null | undefined): string => {
  if (date) {
    return localLuxDateTime(date).startOf("month").toISODate();
  }
  return "";
};

export const getEndDateOfTheMonth = (date: Date | null | undefined): string => {
  if (date) {
    return localLuxDateTime(date).endOf("month").toISODate();
  }

  return "";
};

export const getMonthlyAvailsStartDate = (date: Date | null | undefined): string => {
  if (date) {
    return localLuxDateTime(date).startOf("month").plus({ days: -7 }).toISODate();
  }

  return "";
};

export const getMonthlyAvailsEndDate = (date: Date | null | undefined): string => {
  if (date) {
    return localLuxDateTime(date).endOf("month").plus({ days: 7 }).toISODate();
  }

  return "";
};

export const getMedicareExpiryDateFromString = (date: string | null | undefined): Date | undefined => {
  if (date) {
    return DateTime.fromFormat(date, DateTimeFormat.MEDICARE_DATE).toJSDate();
  }

  return undefined;
};

/**
 * Returns a tuple of booleans representing whether all dates within date +/- range
 * exist within the dateCollection array.
 */
export const dateInProximalRange = (date: Date, dateCollection: Date[], range: number): [boolean, boolean] => {
  const afterDateRange = eachLocalDayOfInterval({ start: date, end: addDays(date, range) });
  const beforeDateRange = eachLocalDayOfInterval({ start: addDays(date, -range), end: date });

  const before = beforeDateRange.every((d: Date) => dateCollection.some((d2: Date) => isLocalSameDay(d2, d)));
  const after = afterDateRange.every((d: Date) => dateCollection.some((d2: Date) => isLocalSameDay(d2, d)));

  return [before, after];
};

export const getHourFromISO = (isoDate: string): number => DateTime.fromISO(isoDate).hour + DateTime.fromISO(isoDate).minute / 60;
