export const ERROR_MESSAGE = {
  CALCULATE_REFUND_AMOUNT: "Unable to find the refund amount for selected line items, please try again later",
  CREATE_PATIENT: "Unable to create new patient, please try again later",
  GO_TO_CART: "Unable to load the cart at this time, please continue this dispense via the patient’s profile",
  INCREMENT_INVENTORY: "Stock update failed, please try again later or contact the merch team",
  ORDER_FULL_REFUND: "Unable to refund all items in the order, please try again later",
  ORDER_FULL_RETURN: "Unable to return all items in the order, please try again later",
  REFUND_PAYMENT: "Unable to process refund payment, please contact IT support",
  RETURN_LINE_ITEM: "Unable to return selected line items, please try again later",
  SEND_INVOCE: "Something went wrong, please try again later or contact IT support",
  SUBMIT_HF: "Sorry, we cannot submit your health fund refund request, please try again later",
  UPDATE_APPOINTMENT: "An error occurred while updating the appointment, please try again later",
  UPDATE_APPOINTMENT_STATUS: "An error occurred while updating the appointment status, please try again later",
  UPDATE_HF_REFUND: "Something went wrong, please try again later",
  UPDATE_REFUND_COMMENT: "Something went wrong, please try again later",

  DEFAULT: "Please try again later",
};

export const SUCCESS_MESSAGE = {
  INCREMENT_INVENTORY: "Stock updated successfully",
  SUBMIT_HF: "Your health fund refund request has been submitted",
};
