import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { ShippingMethodsForCart } from "../../types";

export const SHIPPING_METHODS = gql`
  query ShippingMethodsForCart {
    shippingMethodsForCart {
      shippingMethods {
        name
        description
        isDefault
        key
        id
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<ShippingMethodsForCart>, "data" | "loading" | "error"> {
  getShippingMethods: () => void;
  called: boolean;
}

export const useShippingMethodsQuery = (): ExtendedQueryResult => {
  const [getShippingMethods, { data, error, loading, called }] = useLazyQuery<ShippingMethodsForCart>(SHIPPING_METHODS, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getShippingMethods,
    data,
    error,
    loading,
    called,
  };
};
