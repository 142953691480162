import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React, { FC, useEffect } from "react";

const ENVIRONMENT = process.env.NODE_ENV;

type Props = {
  children?: React.ReactNode;
};

const SentryTracking: FC<Props> = ({ children }) => {
  const COUNTRY = process.env.REACT_APP_STORE_COUNTRY_CODE;
  const APP_ENV = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (ENVIRONMENT === "production") {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: APP_ENV,
        normalizeDepth: 10,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.2,
      });
      Sentry.configureScope((scope) => {
        scope.setExtra("country", COUNTRY);
      });
    }
  }, []);

  return <Sentry.ErrorBoundary showDialog={false}>{children}</Sentry.ErrorBoundary>;
};

export default SentryTracking;
