import styled from "@emotion/styled";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { COLOURS, SIZES, TEST_DATA_IDS } from "../constants";

const NavItemContainer = styled.div<{ active: boolean | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SIZES.SIDEBAR_WIDTH}rem;
  background-color: ${({ active }) => (active ? COLOURS.BLACK_OPAQUE_54 : "inherit")};

  svg {
    font-size: ${SIZES.ICON_FONT_SIZE_M}rem;
    color: white;
    cursor: pointer;
  }
`;

type NavItemProps = {
  path: string;
  disabled?: boolean;
  children?: React.ReactNode;
};

const SideNavItem: FC<NavItemProps> = ({ path, children, disabled }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathMatch = pathname.split("/")[1] === path.split("/")[1];

  const handleClick = (): void => {
    if (!disabled) {
      navigate(path);
    }
  };
  return (
    <NavItemContainer data-testid={TEST_DATA_IDS.SIDEBAR_NAV_ITEM} onClick={handleClick} active={pathMatch}>
      {children}
    </NavItemContainer>
  );
};

export default SideNavItem;
