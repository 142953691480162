import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { Cart, CartVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const CART = gql`
  ${CART_FIELDS}
  query Cart($distributionChannelKey: String) {
    cart(distributionChannelKey: $distributionChannelKey) {
      ...CartFields
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Cart>, "data" | "loading" | "error" | "refetch"> {
  getCart: (cartVariables?: { variables?: CartVariables } | undefined) => void;
  called: boolean;
}

export const useCartQuery = (handleOnComplete?: (data: Cart) => void, networkChange?: boolean): ExtendedQueryResult => {
  const [getCart, { data, error, loading, called, refetch }] = useLazyQuery<Cart, CartVariables | undefined>(CART, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: networkChange ?? true,
    fetchPolicy: "network-only",
  });

  return {
    getCart,
    data,
    error,
    loading,
    called,
    refetch,
  };
};
