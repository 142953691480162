// UOM is rem
export const SIZES = {
  APPOINTMENTS_TOOLBAR_HEIGHT: 4,
  CALENDAR_HEADER_HEIGHT: 4,
  CL_COLUMN_WIDTH: 25,
  HEADER_HEIGHT: 4,
  ICON_FONT_SIZE_L: 1.75,
  ICON_FONT_SIZE_M: 1.5,
  LENS_BUILDER_DESKTOP_COL_WIDTH: 29,
  LENS_BUILDER_OPTION_GRID_GAP: 0.375,
  LENS_BUILDER_OPTION_HEIGHT: 7.5,
  LENS_BUILDER_SM_OPTION_HEIGHT: 5,
  LENS_BUILDER_PATIENT_COL_WIDTH: 20,
  LENS_BUILDER_PROGRESS_HEADER_HEIGHT: 7,
  MEASUREMENT_TD_WIDTH: 5,
  MOBILE_PATIENT_SEARCH_INPUT: 5,
  SIDEBAR_WIDTH: 4,
  SPACING_M: 1,
  SUBSCRIPTION_ITEM_WIDTH: 25,
  TIME_SLOT_HEIGHT: 8,
  REFUND_CONTAINER_WIDTH: 50,
};

export const BREAKPOINTS = {
  IPAD_PRO_LENGTH: 85.375,
  IPAD_PRO_BREADTH: 64,
  IPAD_PRO_HEIGHT: 64,
  SUBSCRIPTION_MOBILE_MAX_WIDTH: SIZES.SIDEBAR_WIDTH + SIZES.SUBSCRIPTION_ITEM_WIDTH * 2 + SIZES.SPACING_M * 4,
};

export const THREE_COL_CAL_MIN_WIDTH = (BREAKPOINTS.IPAD_PRO_LENGTH - SIZES.SIDEBAR_WIDTH) / 3;

export const CALENDAR_COMBINED_HEADER_HEIGHT = SIZES.APPOINTMENTS_TOOLBAR_HEIGHT + SIZES.HEADER_HEIGHT;

export const MIN_DURATION_SIZE = 5;
