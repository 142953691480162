import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addToCartDraft,
  ContactLensSelectedProduct,
  ContactLensSelectedProducts,
  DataProduct,
  EyeType,
  MODALITY_TYPE,
  SALES_TYPE,
  StateSmsDetails,
} from "../../types";

export interface ContactLensState {
  productSearch: DataProduct[];
  salesType: SALES_TYPE | string;
  selected: ContactLensSelectedProducts;
  smsDetails: StateSmsDetails;
  symmetricProduct: boolean;
}

export const initialContactLensState: ContactLensState = {
  salesType: "",
  symmetricProduct: true,
  productSearch: [],
  selected: {
    [EyeType.LEFT_OS]: {
      productFamily: "",
      productName: undefined,
      variant: undefined,
      itemToAdd: {
        add: "",
        axis: "",
        bvd: null,
        cyl: "",
        distributionChannelKey: null,
        eye: EyeType.LEFT_OS,
        frame_wrap: null,
        frequency: null,
        height: null,
        nearpd: null,
        pantoscopic_tilt: null,
        pd: null,
        power: "",
        quantity: 0,
        sku: "",
        sphere: null,
        subscription: false,
        supplyChannelKey: null,
      },
    },
    [EyeType.RIGHT_OD]: {
      productFamily: "",
      productName: undefined,
      variant: undefined,
      itemToAdd: {
        add: "",
        axis: "",
        bvd: null,
        cyl: "",
        distributionChannelKey: null,
        eye: EyeType.RIGHT_OD,
        frame_wrap: null,
        frequency: null,
        height: null,
        nearpd: null,
        pantoscopic_tilt: null,
        pd: null,
        power: "",
        quantity: 0,
        sku: "",
        sphere: null,
        subscription: false,
        supplyChannelKey: null,
      },
    },
  },
  smsDetails: {
    phoneNumber: "",
    name: "",
  },
};

interface PayloadProductDetail extends Omit<ContactLensSelectedProduct, "itemToAdd"> {
  itemToAdd: Partial<addToCartDraft>;
}

type ProductDetailsPayload = {
  eye: EyeType;
  detail: Partial<PayloadProductDetail>;
};

export const calcSubscriptionDays = (selected: ContactLensSelectedProduct, frequency?: number): number | undefined | null => {
  // intentional override
  if (frequency === null) {
    return undefined;
  }
  // unintentional override, so don't
  if (frequency === undefined) {
    return selected.itemToAdd.frequency;
  }

  switch (selected.variant?.modality) {
    case MODALITY_TYPE.DAILY:
      return 1 * frequency;
    case MODALITY_TYPE.MONTHLY:
      return 30 * frequency;
    case MODALITY_TYPE.FORTNIGHTLY:
      return 30 * frequency;
    default:
      return 1 * frequency;
  }
};

export const contactLensSlice = createSlice({
  name: "contactLens",
  initialState: initialContactLensState,
  reducers: {
    setSalesType: (state, action: PayloadAction<SALES_TYPE>) => {
      state.salesType = action.payload;
      state.selected[EyeType.LEFT_OS].itemToAdd.subscription = action.payload === SALES_TYPE.SUBSCRIPTION;
      state.selected[EyeType.RIGHT_OD].itemToAdd.subscription = action.payload === SALES_TYPE.SUBSCRIPTION;
    },
    resetContactLensOrder: () => initialContactLensState,
    updateOrderCustomer: (state, action: PayloadAction<Partial<StateSmsDetails>>) => {
      state.smsDetails = { ...state.smsDetails, ...action.payload };
    },
    setProducts: (state, action: PayloadAction<DataProduct[]>) => {
      const sortedByProductName = [...(action.payload ?? [])].sort((a: DataProduct, b: DataProduct) => (a.name < b.name ? -1 : 1));
      state.productSearch = sortedByProductName;
    },
    updateProductDetail: (state, action: PayloadAction<ProductDetailsPayload>) => {
      const { itemToAdd, ...rest } = action.payload.detail;
      const rawFrequency = itemToAdd?.frequency;

      const frequency = calcSubscriptionDays(state.selected[action.payload.eye], rawFrequency as number | undefined);

      state.selected[action.payload.eye] = {
        ...state.selected[action.payload.eye],
        ...rest,
        itemToAdd: {
          ...state.selected[action.payload.eye].itemToAdd,
          ...itemToAdd, // copy all updates from payload
          frequency,
        },
      };

      const otherEye = action.payload.eye === EyeType.LEFT_OS ? EyeType.RIGHT_OD : EyeType.LEFT_OS;

      if (state.symmetricProduct) {
        const { cyl: _c, axis: _ax, add: _ad, power: _p, quantity: _q, ...copiedProperties } = itemToAdd ?? {};
        state.selected[otherEye] = {
          ...state.selected[otherEye],
          ...rest,
          itemToAdd: {
            ...state.selected[otherEye].itemToAdd,
            ...copiedProperties, // copy all updates except prescription attributes
            frequency,
          },
        };
      }
    },
    toggleSymmetricProduct: (state) => {
      // copy or clear left selected when toggling to symmetric product
      if (state.symmetricProduct) {
        state.selected[EyeType.LEFT_OS] = {
          ...initialContactLensState.selected[EyeType.LEFT_OS],
          itemToAdd: {
            ...initialContactLensState.selected[EyeType.LEFT_OS].itemToAdd,
            // we should always maintain the sales type of the order
            subscription: state.selected[EyeType.LEFT_OS].itemToAdd.subscription,
          },
        };
      } else {
        state.selected[EyeType.LEFT_OS] = {
          ...state.selected[EyeType.RIGHT_OD],
          itemToAdd: {
            ...state.selected[EyeType.RIGHT_OD].itemToAdd,
            eye: EyeType.LEFT_OS,
          },
        };
      }
      state.symmetricProduct = !state.symmetricProduct;
    },
  },
});

export const { resetContactLensOrder, setProducts, setSalesType, toggleSymmetricProduct, updateOrderCustomer, updateProductDetail } =
  contactLensSlice.actions;

export default contactLensSlice.reducer;
