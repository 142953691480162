import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";

export const BaseButton = styled(Button)`
  width: 100%;
  padding: 10px;
  margin-top: 0.625rem;
  max-height: 3rem;
`;

export const SmallButton = styled(Button)`
  padding: 0.625rem 1rem 0.625rem 1rem;
  height: 2rem;
`;

export const SearchTextField = styled(TextField)`
  background-color: white;
`;
