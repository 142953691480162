import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { StoreCarts, StoreCartsVariables } from "../../types";

export const STORE_PENDING_CART = gql`
  query StoreCarts($storeCartsRequest: StoreCartsRequest!) {
    storeCarts(storeCartsRequest: $storeCartsRequest) {
      limit
      carts {
        id
        shippingAddress {
          firstName
          lastName
        }
        patientId
        updatedAt
        totalPrice {
          currencyCode
          centAmount
        }
        customFields {
          specialInstructions
        }
        shippingInfo {
          shippingMethodName
        }
        lineItems {
          id
          name {
            en
          }
          quantity
          variant {
            name
            sku
            price {
              value {
                currencyCode
                centAmount
              }
            }
          }
          totalPrice {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<StoreCarts>, "data" | "loading" | "error"> {
  getStoreCarts: ({ variables }: { variables: StoreCartsVariables }) => void;
  called: boolean;
}

export const usePendingCartsQuery = (networkChange?: boolean): ExtendedQueryResult => {
  const [getStoreCarts, { data, error, loading, called }] = useLazyQuery<StoreCarts>(STORE_PENDING_CART, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: networkChange ?? true,
    fetchPolicy: "network-only",
  });

  return {
    getStoreCarts,
    data,
    error,
    loading,
    called,
  };
};
