import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { InventoriesSent, InventoriesSentVariables, InventoryMovementsRequest } from "../../types";

export const INVENTORIES_SENT = gql`
  query InventoriesSent($inventoryMovementsRequest: InventoryMovementsRequest!) {
    inventoriesSent(inventoryMovementsRequest: $inventoryMovementsRequest) {
      id
      createdAt
      sourceChannel
      destinationChannel
      transferDate
      transferStatus
      inventoryEntries {
        sku
        description
        quantity
        transferType
        comment
      }
      batchNumber
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<InventoriesSent>, "data" | "loading" | "error" | "variables"> {
  getInventoriesSent: (inventoryMovementsRequest: InventoryMovementsRequest) => void;
  called: boolean;
}

export const useInventoriesSentQuery = (handleOnComplete?: (data: InventoriesSent) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called, variables }] = useLazyQuery<InventoriesSent, InventoriesSentVariables>(INVENTORIES_SENT, {
    onError: () => void 0,
    fetchPolicy: "network-only",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const getInventoriesSent = (inventoryMovementsRequest: InventoryMovementsRequest): void => {
    fetch({ variables: { inventoryMovementsRequest } });
  };

  return {
    getInventoriesSent,
    data,
    error,
    loading,
    called,
    variables,
  };
};
