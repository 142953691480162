import { gql, QueryResult, useQuery } from "@apollo/client";

import { PosRetrieveDiscountCodes } from "../../types";

export const DISCOUNT_CODES = gql`
  query PosRetrieveDiscountCodes {
    posRetrieveDiscountCodes {
      code
      id
    }
  }
`;

export const useDiscountCodesQuery = (): QueryResult<PosRetrieveDiscountCodes> =>
  useQuery<PosRetrieveDiscountCodes>(DISCOUNT_CODES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
