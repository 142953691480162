import { DistributionChannels_distributionChannels } from "../../types";
import { RootState } from "../store";

export const selectStoreAuthenticated = (state: RootState): boolean => state.authentication.storeAuthenticated;
export const selectStaffAuthenticated = (state: RootState): boolean => state.authentication.staffAuthenticated;
export const selectActiveDistributionChannel = (state: RootState): DistributionChannels_distributionChannels | undefined =>
  state.authentication.activeDistributionChannel;
export const selectActiveDistributionChannelKey = (state: RootState): string | undefined | null =>
  state.authentication.activeDistributionChannel?.key;
export const selectCognitoAuthEmail = (state: RootState): string | undefined => state.authentication.cognitoAuthEmail;
