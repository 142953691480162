import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React, { FC } from "react";

import { AUTH_KEYS, StoreTokenInfo } from "../../types";

const LAUNCHDARKLY_CLIENT_ID = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID;

type Props = {
  children?: React.ReactNode;
};

const FeatureFlags: FC<Props> = ({ children }) => <>{children}</>;
let ldUser = undefined;

const storeAuthToken = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN);

if (storeAuthToken) {
  try {
    const decoded: StoreTokenInfo = jwt_decode(storeAuthToken);
    if (decoded.email) {
      localStorage.setItem(AUTH_KEYS.LD_USER_KEY, decoded.email);
      ldUser = {
        key: decoded.email,
        name: decoded.name,
        email: decoded.email,
        custom: {
          storeCode: process.env.REACT_APP_STORE_COUNTRY_CODE ?? "no-store-code-provided",
        },
      };
    }
  } catch {
    Sentry.captureMessage("Error decoding store token from browser storage, launch darkly init");
  }
}

if (!LAUNCHDARKLY_CLIENT_ID) {
  Sentry.captureMessage("REACT_APP_LAUNCHDARKLY_CLIENT_ID env var not set, launch darkly init");
}

const returnLDProvider = !!LAUNCHDARKLY_CLIENT_ID && !!ldUser?.key;

const FeatureFlagWrapper = returnLDProvider
  ? withLDProvider({
      clientSideID: LAUNCHDARKLY_CLIENT_ID,
      reactOptions: {
        useCamelCaseFlagKeys: false,
      },
      user: ldUser,
    })(FeatureFlags)
  : FeatureFlags;

export default FeatureFlagWrapper;
