import emotionStyled from "@emotion/styled";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import isNil from "lodash/isNil";
import React, { FC, useEffect, useState } from "react";

import { TEST_DATA_IDS } from "../constants";
import { useDistributionChannelsLazyQuery } from "../data/queries";
import { selectCognitoAuthEmail, setActiveDistributionChannel } from "../state/authentication";
import { setAllDistributionChannels } from "../state/availability";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { DistributionChannels_distributionChannels } from "../types";

const SelectContainer = emotionStyled.div`
  display: flex;
  background-color: white;
`;

type Props = {
  onSelect: (dc: DistributionChannels_distributionChannels) => void;
  selected?: DistributionChannels_distributionChannels | undefined;
  displayPOSMigrated?: boolean;
  authStoreFilter?: boolean; // used to show only logged in store, global Header component
  label?: string;
  reset?: boolean; // pass true to clear selected value
  excludeKeys?: string[]; // pass array of dc keys to remove from displayed results
  size?: "small" | "medium";
};

const StoreSelect: FC<Props> = ({ onSelect, selected, displayPOSMigrated, authStoreFilter, label, reset, excludeKeys, size }) => {
  const [localSelected, setLocalSelected] = useState<DistributionChannels_distributionChannels | undefined>();
  const [distributionChannels, setDistributionChannels] = useState<DistributionChannels_distributionChannels[]>([]);
  const cognitoAuthEmail = useAppSelector(selectCognitoAuthEmail);
  const dispatch = useAppDispatch();

  const { getDistributionChannels, data } = useDistributionChannelsLazyQuery();

  useEffect(() => {
    getDistributionChannels();
  }, []);

  useEffect(() => {
    if (!localSelected) {
      setLocalSelected(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (reset && localSelected) {
      setLocalSelected(undefined);
    }
  }, [reset, localSelected]);

  useEffect(() => {
    const dcData = (data?.distributionChannels ?? []) as unknown as DistributionChannels_distributionChannels[];
    const displayDistributionChannels = dcData
      .filter(
        (dc) => dc?.storeMigrated30 && (!isNil(displayPOSMigrated) ? dc.storeMigratedPOS : true) && (excludeKeys ?? []).indexOf(dc?.key ?? "") === -1,
      )
      .sort(({ name: aName }: DistributionChannels_distributionChannels, { name: bName }: DistributionChannels_distributionChannels) =>
        (aName ?? "") < (bName ?? "") ? -1 : 1,
      );

    dispatch(setAllDistributionChannels(displayDistributionChannels as DistributionChannels_distributionChannels[]));
    const storesMatchingAuthEmail = displayDistributionChannels.filter(({ email }) => email === cognitoAuthEmail);

    // Store logins should match a single dc.
    // Other cases will be superusers so we default to showing everything

    if (storesMatchingAuthEmail.length === 1 && authStoreFilter) {
      setDistributionChannels(storesMatchingAuthEmail);
      dispatch(setActiveDistributionChannel(storesMatchingAuthEmail[0]));
    } else {
      setDistributionChannels(displayDistributionChannels);
    }
  }, [data, excludeKeys]);

  const handleChange = (e: SelectChangeEvent): void => {
    const storeKey = e.target.value as string;
    const store = distributionChannels.find(
      (s: DistributionChannels_distributionChannels) => s.key === storeKey,
    ) as DistributionChannels_distributionChannels;
    onSelect(store);
    setLocalSelected(store);
  };

  return (
    <SelectContainer>
      <FormControl fullWidth disabled={distributionChannels.length === 0}>
        <InputLabel sx={!size ? { lineHeight: "0.75rem" } : {}}>{label ?? "Select a store"}</InputLabel>
        <Select
          size={size ?? "small"}
          value={localSelected?.key ?? ""}
          onChange={handleChange}
          label={label ?? "Select a store"}
          data-testid={TEST_DATA_IDS.STORE_SEARCH_INPUT}
        >
          {distributionChannels.map((dc, index) => (
            <MenuItem key={index} value={dc.key as string}>
              {dc.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </SelectContainer>
  );
};

export default StoreSelect;
