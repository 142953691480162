import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import { Bundle, OrderField, RefundedItem } from "../../types";
import { updateRefundItem } from "../../utils";

export interface OrdersState {
  activeOrderNumber: string;
  activeOrder: OrderField | null;
  orderDetails: Bundle[]; //formatted order data
  refundItems: RefundedItem[];
  returnAllItems: RefundedItem[]; //similar to refundItems, but for return all dialog only
  refundReasons: string[];
  returnReasons: string[];
}

export const initialOrdersState: OrdersState = {
  activeOrderNumber: "",
  activeOrder: null,
  refundItems: [],
  returnAllItems: [],
  refundReasons: [],
  returnReasons: [],
  orderDetails: [],
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState: initialOrdersState,
  reducers: {
    setActiveOrderNumber: (state, action: PayloadAction<string>) => {
      state.activeOrderNumber = action.payload;
    },
    setActiveOrder: (state, action: PayloadAction<OrderField | null>) => {
      state.activeOrder = action.payload;
    },
    setRefundReason: (state, action: PayloadAction<string[]>) => {
      state.refundReasons = action.payload;
    },
    setReturnReason: (state, action: PayloadAction<string[]>) => {
      state.returnReasons = action.payload;
    },
    setOrderDetails: (state, action: PayloadAction<Bundle[]>) => {
      state.orderDetails = action.payload;
    },
    setRefundItem: (state, action: PayloadAction<RefundedItem>) => {
      state.refundItems = updateRefundItem(state.refundItems, action.payload);
    },
    setRefundItems: (state, action: PayloadAction<RefundedItem[]>) => {
      state.refundItems = action.payload;
    },
    setReturnAllItems: (state, action: PayloadAction<RefundedItem[]>) => {
      state.returnAllItems = action.payload;
    },
    setReturnAllItem: (state, action: PayloadAction<RefundedItem>) => {
      state.returnAllItems = updateRefundItem(state.returnAllItems, action.payload);
    },
    removeRefundItem: (state, action: PayloadAction<string[]>) => {
      state.refundItems = state.refundItems.filter((item) => !_.isEqual(item.id, action.payload));
    },
    resetRefundItems: (state) => {
      state.refundItems = [];
    },
    resetOrdersState: () => initialOrdersState,
  },
});

export const {
  setActiveOrderNumber,
  resetOrdersState,
  setActiveOrder,
  setRefundItem,
  setRefundItems,
  removeRefundItem,
  setOrderDetails,
  setReturnAllItem,
  setReturnAllItems,
  setRefundReason,
  setReturnReason,
  resetRefundItems,
} = ordersSlice.actions;

export default ordersSlice.reducer;
