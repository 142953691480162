import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ContactsPrescription, GlassesPrescription, OverlappingPrescription, Patient, RecursivePartial } from "../../types";
import { lensBuilderSlice } from "../lensBuilder/slice";

export interface PatientState {
  activePatient?: Patient;
  contactsPrescriptions: ContactsPrescription[];
  formPrescription?: RecursivePartial<OverlappingPrescription>;
  glassesPrescriptions: GlassesPrescription[];
  showDetail: boolean;
}

export const initialPatientState: PatientState = {
  activePatient: undefined,
  contactsPrescriptions: [],
  formPrescription: undefined,
  glassesPrescriptions: [],
  showDetail: false,
};

interface PatientResponse extends Patient {
  __typename?: string;
}

export const patientSlice = createSlice({
  name: "patient",
  initialState: initialPatientState,
  reducers: {
    setActivePatient: (state, action: PayloadAction<PatientResponse | undefined>) => {
      if (action?.payload) {
        // we do an object value equality check when editing patient data, ensure patient never includes __typename from graphql response
        const { __typename, ...rest } = action.payload;
        state.activePatient = rest;
      } else {
        state.activePatient = undefined;
      }
    },
    setFormPrescription: (state, action: PayloadAction<RecursivePartial<OverlappingPrescription> | undefined>) => {
      state.formPrescription = action.payload;
    },
    setShowDetail: (state, action: PayloadAction<boolean>) => {
      state.showDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    // patient formPrescription is displayed in the lensbuilder flow, we want to clear that out when resetting
    builder.addCase(lensBuilderSlice.actions.resetOnProductChange, (state) => {
      state.formPrescription = undefined;
    });
  },
});

export const { setActivePatient, setFormPrescription, setShowDetail } = patientSlice.actions;

export default patientSlice.reducer;
