import { gql, QueryResult, useQuery } from "@apollo/client";

import { CartContactMethod } from "../../types";

export const CART_CONTACT_METHOD = gql`
  query CartContactMethod {
    cart {
      customFields {
        contactMethod
      }
    }
  }
`;

export const useCartContactMethodQuery = (): QueryResult<CartContactMethod> =>
  useQuery<CartContactMethod>(CART_CONTACT_METHOD, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
