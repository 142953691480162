import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { PATHS } from "../constants";
import Home from "./Home";
import LoadingFallback from "./LoadingFallback";

const Appointments = lazy(() => import("../components/Appointments"));
const ContactLens = lazy(() => import("../components/ContactLens"));
const Dispensing = lazy(() => import("../components/Dispensing"));
const DispensingNew = lazy(() => import("../components/DispensingNew"));
const Report = lazy(() => import("../components/Report"));
const Exam = lazy(() => import("../components/Exam"));
const OptomAvailability = lazy(() => import("../components/OptomAvailability"));
const PatientProfile = lazy(() => import("../components/PatientProfile"));
const OrderDetail = lazy(() => import("../components/Orders/OrderDetail"));
const Refund = lazy(() => import("../components/Refund"));
const Inventory = lazy(() => import("../components/Inventory"));
const NewPatient = lazy(() => import("../components/PatientProfile/NewPatient"));
const Setting = lazy(() => import("../components/Setting/Setting"));
const Orders = lazy(() => import("../components/Orders/Orders"));
const PendingCarts = lazy(() => import("../components/Orders/PendingCarts"));

const Body: FC = () => {
  const { dispensingv2, enableOrder, exam, storeOrder, setting, refund, orders, pendingCarts, contactsLensesSubscription } = useFlags();

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path={PATHS.APPOINTMENTS} element={<Appointments />} />
        <Route path={PATHS.AVAILABILITY} element={<OptomAvailability />} />
        {contactsLensesSubscription && <Route path={`${PATHS.CONTACT_LENS}/*`} element={<ContactLens />} />}
        <Route path={PATHS.HOME} element={<Home />} />
        <Route path={PATHS.NEW_PATIENT} element={<NewPatient />} />
        <Route path={PATHS.PATIENT} element={<PatientProfile />} />
        <Route path={`${PATHS.REPORT}/*`} element={<Report />} />
        {dispensingv2 ? (
          <Route path={`${PATHS.DISPENSING}/*`} element={<DispensingNew />} />
        ) : (
          <Route path={`${PATHS.DISPENSING}/*`} element={<Dispensing />} />
        )}
        <Route path={`${PATHS.DISPENSING}/*`} element={<Dispensing />} />
        {enableOrder && <Route path={PATHS.PATIENT_ORDER} element={<OrderDetail />} />}
        {exam && <Route path={PATHS.EXAM} element={<Exam />} />}
        {orders && <Route path={PATHS.ORDERS} element={<Orders />} />}
        {pendingCarts && <Route path={PATHS.PENDING_CART} element={<PendingCarts />} />}
        {refund && <Route path={PATHS.REFUND} element={<Refund />} />}
        {setting && <Route path={`${PATHS.SETTING}/*`} element={<Setting />} />}
        {storeOrder && <Route path={PATHS.INVENTORY} element={<Inventory />} />}
      </Routes>
    </Suspense>
  );
};

export default Body;
