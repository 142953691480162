import { ApolloClient, ApolloLink, from, split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";

import { cache } from "../cache";
import { authLink, errorLink, httpLink, wsLink } from "./links";

// https://www.apollographql.com/docs/react/data/subscriptions/#3-split-communication-by-operation-recommended
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink as unknown as ApolloLink,
  httpLink,
);

export const apolloClient = new ApolloClient({
  link: from([authLink, errorLink, splitLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV !== "production",
});
