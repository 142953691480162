import { gql, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { CATEGORY_SLUG, LensBuilderQuery, LensBuilderQueryVariables } from "../../types";

interface FacetRequest {
  facetName: string;
  facetValue: string;
}

export const LENS_BUILDER = gql`
  query LensBuilderQuery($searchCategorySlug: String, $facets: [FacetRequest]) {
    productLensBuilder(categorySlug: $searchCategorySlug, facet: $facets) {
      facets {
        name
        terms {
          term
        }
      }
      products {
        key
        variants {
          sku
          name
          lensSKUs
          currentPrice {
            cashPrice {
              currencyCode
              amount
            }
            discounted {
              value {
                currencyCode
                centAmount
              }
            }
          }
          stocks {
            availableQuantity
            distributionChannelKey
            key
            available
            name
          }
        }
      }
    }
  }
`;

export const useLensProductQuery = (
  searchCategorySlug: CATEGORY_SLUG,
  facets: FacetRequest[],
): QueryResult<LensBuilderQuery, LensBuilderQueryVariables> =>
  useQuery<LensBuilderQuery, LensBuilderQueryVariables>(LENS_BUILDER, {
    variables: { searchCategorySlug: searchCategorySlug, facets: facets },
    fetchPolicy: "network-only",
    onError: () => void 0,
  });

interface ExtendedQueryResult extends Pick<QueryResult<LensBuilderQuery>, "data" | "loading" | "error" | "variables"> {
  getLensBuilderQuery: ({ variables }: { variables: LensBuilderQueryVariables }) => void;
  called: boolean;
}

export const useLensProductLazyQuery = (handleOnComplete?: (data: LensBuilderQuery) => void): ExtendedQueryResult => {
  const [getLensBuilderQuery, { data, error, loading, called, variables }] = useLazyQuery<LensBuilderQuery, LensBuilderQueryVariables>(LENS_BUILDER, {
    onError: () => void 0,
    fetchPolicy: "network-only",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  return {
    getLensBuilderQuery,
    data,
    error,
    loading,
    called,
    variables,
  };
};
