import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { StoreOrders, StoreOrdersVariables } from "../../types";
import { ORDER_FIELD } from "../fragments";

export const STORE_ORDERS = gql`
  ${ORDER_FIELD}
  query StoreOrders($storeOrdersRequest: StoreOrdersRequest!) {
    storeOrders(storeOrdersRequest: $storeOrdersRequest) {
      ...OrderField
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<StoreOrders>, "data" | "loading" | "error"> {
  getOrder: ({ variables }: { variables: StoreOrdersVariables }) => void;
  called: boolean;
}

export const useStoreOrdersQuery = (): ExtendedQueryResult => {
  const [getOrder, { data, error, loading, called }] = useLazyQuery<StoreOrders, StoreOrdersVariables>(STORE_ORDERS, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    getOrder,
    data,
    error,
    loading,
    called,
  };
};
