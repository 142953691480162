export interface ColorVision {
  test: string;
  result: string;
  diagnosis: string;
}

export interface ExamVisionCoverTestData {
  odAidDistance: string;
  osAidDistance: string;

  odUnaidDistance: string;
  osUnaidDistance: string;

  odUnaidedNear: string;
  osUnaidedNear: string;

  odAidedNear: string;
  osAidedNear: string;

  npcBlur: string;
  npaBlur: string;

  npcBreak: string;
  npaBreak: string;

  npcRecovery: string;
  npaRecovery: string;
}

export interface ExamVisionAidData {
  lensType: string;
  aidedNear: string;
  aidDistance: string;
  odAidDistance: string;
  osAidDistance: string;
  ouAidDistance: string;
}

export interface ExamVisionUnaidData {
  unaidedNear: string;
  unadDistance: string;
  odUnaidDistance: string;
  osUnaidDistance: string;
  ouUnaidDistance: string;
}

export interface ExamVisionPinholeData {
  odPinhole: string;
  osPinhole: string;
}

export interface ExamVisionOptionalData {
  confrontation: string;
  pupils: string;
  excursion: string;
  colorVision: ColorVision;
}
export interface ExamVisionData {
  aidData: ExamVisionAidData;
  unaidData: ExamVisionUnaidData;

  pinholeData: ExamVisionPinholeData;
  coverTestData: ExamVisionCoverTestData;

  optionalData: ExamVisionOptionalData;
}

interface BasePrescription {
  odSph: string;
  odCyl: string;
  odAxis: string;
  odVa: string;

  osSph: string;
  osCyl: string;
  osAxis: string;
  osVa: string;
}

interface BasePrescriptionWithPrism extends BasePrescription {
  odHPrism: string;
  odHPrism2: string;
  odVPrism: string;
  odVPrism2: string;
  odBVD: string;
  osHPrism: string;
  osHPrism2: string;
  osVPrism: string;
  osVPrism2: string;
  osBVD: string;
}

export interface AutoRefractionPrescriptionData extends BasePrescription {
  autoPd: string;
  note: string;
}

export interface RefractionPrescriptionData extends BasePrescription {
  odAdd: string;
  odIntAdd: string;
  odNva: string;
  osAdd: string;
  osIntAdd: string;
  osNva: string;
}
export interface CurrentRefractionPrescriptionData extends BasePrescription {
  lensType: string;
  pd: string;
  odAdd: string;
  osAdd: string;
  note: string;
}

export interface FinalRefractionPrescriptionData extends BasePrescriptionWithPrism {
  odAdd: string;
  odIntAdd: string;
  odNva: string;
  osAdd: string;
  osIntAdd: string;
  osNva: string;
  note: string;
}

export interface ExamRefractionData {
  currentRx: CurrentRefractionPrescriptionData[];
  autoRefraction: AutoRefractionPrescriptionData;
  finalRx: FinalRefractionPrescriptionData[];
  subjectiveRx: FinalRefractionPrescriptionData[];
  retinoscopy: RefractionPrescriptionData;
  cycloplegic: RefractionPrescriptionData;
  refractionNote: string;
  binocularVision: string;
}

export interface ExamHistoryData {
  presentingComplaint: string;
  clHistory: string;
  generalHealth: string;
  pastOcular: string;
  familyOcular: string;
  occupation: string;
  occupationDescription: string;
  drivingDetail: string;
}

export type DilatedFundus = {
  isConsent: boolean;
  time: string;
  odDrop: string;
  osDrop: string;

  odDrop2: string;
  osDrop2: string;
};

export type OcularIopData = {
  odIop: string; //iop value is between 12-22mmhg
  osIop: string;
  odCorrected: string; //corrected value is between -7 to +9
  osCorrected: string;
  odPachymetry: string;
  osPachymetry: string;
  device: string;
  time: string;
};

export type OcularOptionalData = {
  amsler: string;
  vfe: string;
  vfeFileUrl: string;
};

export type OcularSegmentData = {
  anterior: string;
  posterior: string;

  anteriorFileUrl: string;
  posteriorFileUrl: string;
};

export type ExamOcularData = {
  iopData: OcularIopData;
  ocularSegmentData: OcularSegmentData;
  optionalData: OcularOptionalData;
  dilatedFundus: DilatedFundus;
};

export type DiagnosisManagementData = {
  //placeholder, likely to change later
  diagnosis: string;
  management: string;
};

export enum SIDE_NAV_ITEM {
  PRE_TEST = "Pre-test",
  HISTORY = "History",
  VISION = "Vision",
  REFRACTION = "Refraction",
  OCULAR_HEALTH = "Ocular Health",
  DIAGNOSIS = "Diagnosis",
}

export enum REFRACTION_TYPE {
  RETINOSCOPY = "rentinoscopy",
  CYCLOPLEGIC = "cycloplegic",
}
